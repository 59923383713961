.avatar-speech-bubble-banner {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.avatar-speech-bubble-banner__background {
  display: block;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.avatar-speech-bubble-banner__main {
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  background-color: #4945e2;
  padding: 20px;
}
@media (min-width: 577px) {
  .avatar-speech-bubble-banner__main {
    flex-direction: row;
  }
}
.avatar-speech-bubble-banner__avatar-wrapper {
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
}
@media (min-width: 577px) {
  .avatar-speech-bubble-banner__avatar-wrapper {
    margin-top: 14px;
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 835px) {
  .avatar-speech-bubble-banner__avatar-wrapper {
    flex: 0 0 73px;
    width: 73px;
    height: 73px;
  }
}
.avatar-speech-bubble-banner__avatar {
  display: block;
  width: 100%;
}
.avatar-speech-bubble-banner__content {
  background-color: #fff;
  padding: 24px 20px;
  border-radius: 5px;
  position: relative;
  margin: 0 0 30px 0;
}
@media (min-width: 577px) {
  .avatar-speech-bubble-banner__content {
    margin: 0 0 0 30px;
  }
}
.avatar-speech-bubble-banner__content:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 25px;
  border-color: transparent transparent #fff #fff;
  border-radius: 4px;
  transform: skewY(-35deg);
  position: absolute;
  bottom: -4px;
}
@media (min-width: 577px) {
  .avatar-speech-bubble-banner__content:after {
    bottom: auto;
    left: -14px;
    top: 32px;
    transform: rotate(90deg) skewY(-35deg);
  }
}
.avatar-speech-bubble-banner__title {
  display: block;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 1em;
  text-transform: uppercase;
  color: #28283c;
  margin-bottom: 20px;
}
@media (min-width: 835px) {
  .avatar-speech-bubble-banner__title {
    margin-bottom: 18px;
  }
}
.avatar-speech-bubble-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1.5em;
  color: #28283c;
  margin: 0;
}
.avatar-speech-bubble-banner__name {
  display: block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  margin-top: 30px;
}
@media (min-width: 835px) {
  .avatar-speech-bubble-banner__name {
    margin-top: 20px;
  }
}
