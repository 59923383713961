.arrow-note {
  position: relative;
}
.arrow-note__percentage {
  display: inline-block;
  font-family: "Founders Grotesk X Condensed", "Arial Narrow Bold", sans-serif;
  font-size: 28px;
  background-color: #00d2c8;
  color: #bff0db;
  height: 42px;
  line-height: 42px;
  border-radius: 21px;
  padding: 0 14px;
  vertical-align: top;
  transform: rotate(38.29deg);
  text-transform: uppercase;
}
.arrow-note__arrow-icon {
  position: absolute;
  top: -2px;
  left: 65px;
}
