.bullet-points-list__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.bullet-points-list__list-item {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #28283c;
  opacity: 0.8;
  margin: 25px 0;
}
.bullet-points-list__list-item:last-child {
  margin-bottom: 0;
}
.bullet-points-list__bullet-point {
  flex: 0 0 43px;
}
.bullet-points-list__bullet-point:before {
  display: inline-block;
  content: "";
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #dddde0;
  vertical-align: middle;
  margin-right: 20px;
}
