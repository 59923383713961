.menu-banner {
  max-width: 530px;
  border-radius: 10px;
  overflow: hidden;
}
.menu-banner--camelot {
  background-color: #822d50;
  color: #fff;
}
.menu-banner--emerald {
  background-color: #32c864;
  color: #fff;
}
.menu-banner--sun {
  background-color: #faaa1e;
  color: #fff;
}
.menu-banner--robbinsEggBlue {
  background-color: #00d2c8;
  color: #fff;
}
.menu-banner--ebonyClay {
  background-color: #28283c;
  color: #fff;
}
.menu-banner--azalea {
  background-color: #fadcdc;
  color: #fff;
}
.menu-banner--cruise {
  background-color: #bff0db;
  color: #fff;
}
.menu-banner--astra {
  background-color: #fafabe;
  color: #fff;
}
.menu-banner--gallery {
  background-color: #f0f0f0;
  color: #fff;
}
.menu-banner--humming-bird {
  background-color: #d7f0fa;
  color: #fff;
}
.menu-banner--humming-bird-faded {
  background-color: #f0fafe;
  color: #fff;
}
.menu-banner--silver-chalice {
  background-color: #a6a6a6;
  color: #fff;
}
.menu-banner--catskill-white {
  background-color: #e1e9f0;
  color: #fff;
}
.menu-banner--bali-hai {
  background-color: #8298ab;
  color: #fff;
}
.menu-banner--french-lilac {
  background-color: #efe0f6;
  color: #fff;
}
.menu-banner--deep-blush {
  background-color: #e07688;
  color: #fff;
}
.menu-banner--pink {
  background-color: #fbdad8;
  color: #fff;
}
.menu-banner--gothic {
  background-color: #6991a1;
  color: #fff;
}
.menu-banner--polar-blue {
  background-color: #f0f9fc;
  color: #fff;
}
.menu-banner--prussian {
  background-color: #304659;
  color: #fff;
}
.menu-banner--sky {
  background-color: #e1e9f0;
  color: #fff;
}
.menu-banner--tulip-tree {
  background-color: #f0ac44;
  color: #fff;
}
.menu-banner--emeraldish {
  background-color: #03c2b9;
  color: #fff;
}
.menu-banner--jumbo {
  background-color: #7b7b86;
  color: #fff;
}
.menu-banner--pampas {
  background-color: #f8f7f5;
  color: #fff;
}
.menu-banner--dark-gallery {
  background-color: #eaeaea;
  color: #fff;
}
.menu-banner--concrete {
  background-color: #f2f2f2;
  color: #fff;
}
.menu-banner--sand {
  background-color: #fbfbfb;
  color: #fff;
}
.menu-banner--black-squeeze {
  background-color: #f9fcfd;
  color: #fff;
}
.menu-banner--athens-gray {
  background-color: #f4f4f4;
  color: #fff;
}
.menu-banner--alto {
  background-color: #d8d8d8;
  color: #fff;
}
.menu-banner--gray {
  background-color: #888;
  color: #fff;
}
.menu-banner--venus {
  background-color: #7e7e8a;
  color: #fff;
}
.menu-banner--dolphin {
  background-color: #6b6a77;
  color: #fff;
}
.menu-banner--mercury {
  background-color: #e6e6e6;
  color: #fff;
}
.menu-banner--text-black {
  background-color: #28283c;
  color: #fff;
}
.menu-banner--polar {
  background-color: #eafbfa;
  color: #fff;
}
.menu-banner--tundora {
  background-color: #484444;
  color: #fff;
}
.menu-banner--jet {
  background-color: #3e3e50;
  color: #fff;
}
.menu-banner--silver {
  background-color: #bdbdbd;
  color: #fff;
}
.menu-banner--smoke {
  background-color: #bfbfbf;
  color: #fff;
}
.menu-banner--white {
  background-color: #fff;
  color: #fff;
}
.menu-banner--malachite {
  background-color: #00c040;
  color: #fff;
}
.menu-banner--light-green {
  background-color: #c0f0dd;
  color: #fff;
}
.menu-banner--coral {
  background-color: #ff774d;
  color: #fff;
}
.menu-banner--bright-sun {
  background-color: #ffcc43;
  color: #fff;
}
.menu-banner--shark {
  background-color: #272a2d;
  color: #fff;
}
.menu-banner--royal-blue {
  background-color: #4945e2;
  color: #fff;
}
.menu-banner--aquamarine-blue {
  background-color: #7ce5df;
  color: #fff;
}
.menu-banner--scorpion {
  background-color: #595959;
  color: #fff;
}
.menu-banner--heather {
  background-color: #b8c5d0;
  color: #fff;
}
.menu-banner--red {
  background-color: #de2828;
  color: #fff;
}
.menu-banner--lighter-teal {
  background-color: #d6f6f3;
  color: #fff;
}
.menu-banner--blue-lagoon {
  background-color: #007986;
  color: #fff;
}
.menu-banner--brightTeal {
  background-color: #00d8c4;
  color: #fff;
}
.menu-banner__wrapper--has-thumbnail {
  padding-bottom: 50%;
  position: relative;
}
.menu-banner__content {
  padding: 25px 20px 20px;
}
.menu-banner__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 90%;
  text-transform: uppercase;
  display: block;
  margin-bottom: 12px;
}
.menu-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 144%;
  letter-spacing: 0.01em;
  display: block;
}
.menu-banner__link:link,
.menu-banner__link:visited,
.menu-banner__link:hover,
.menu-banner__link:active {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #f8f7f5;
  border-bottom: 1px solid #f8f7f5;
  text-decoration: none;
}
.menu-banner__thumbnail {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
}
