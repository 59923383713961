.tips {
  background-color: #f0fafe;
  border-radius: 5px;
  padding: 24px;
}
.tips__toggle .accordion-box-toggle__tick path {
  stroke: #6991a1;
}
.tips__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 24px;
  color: #28283c;
}
.tips__wrapper {
  margin: 0;
  padding: 16px 0 0;
  list-style: none;
}
.tips a {
  color: #28283c;
}
.tips a:visited,
.tips a:hover,
.tips a:active,
.tips a:focus {
  color: #28283c;
}
.tips a:hover {
  text-decoration: underline;
}
