.alert-banner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-top: 6px solid #00d2c8;
  border-radius: 0 0 5px 5px;
  padding: 25px;
  cursor: pointer;
}
@media (min-width: 834px) {
  .alert-banner {
    flex-wrap: nowrap;
  }
}
.alert-banner__icon-section {
  margin-right: 15px;
  margin-bottom: 10px;
}
.alert-banner__icon path {
  fill: #b8c5d0;
}
.alert-banner__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 5px;
}
.alert-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  letter-spacing: 0.3px;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
}
.alert-banner__prompt {
  display: inline-flex;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 0.3px;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
  border-bottom: 1px solid #00d2c8;
  margin-top: 15px;
}
