.teacher-benefits-page {
  background: #f8f7f5;
}
.teacher-benefits-page__banner-cta-link:link,
.teacher-benefits-page__banner-cta-link:hover {
  text-decoration: none;
}
.teacher-benefits-page__container {
  padding: 0 0 0;
  max-width: 1150px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .teacher-benefits-page__container {
    padding: 0 70px 0;
  }
}
@media (max-width: 1024px) {
  .teacher-benefits-page__container {
    padding: 0 40px 0;
  }
}
@media (max-width: 834px) {
  .teacher-benefits-page__container {
    padding: 0 60px 0;
  }
}
@media (max-width: 576px) {
  .teacher-benefits-page__container {
    padding: 0 14px 0;
  }
}
.teacher-benefits-page__top-simple-cta {
  padding: 90px 0 70px;
}
@media (min-width: 1024px) {
  .teacher-benefits-page__top-simple-cta {
    padding: 110px 0 90px;
  }
}
.teacher-benefits-page__info-banner {
  padding: 0 0 60px;
}
@media (min-width: 1024px) {
  .teacher-benefits-page__info-banner {
    padding: 0 0 100px;
  }
}
.teacher-benefits-page__aligned-image-banner {
  padding: 0 0 0px;
}
@media (min-width: 1024px) {
  .teacher-benefits-page__aligned-image-banner {
    padding: 0 0 100px;
  }
}
.teacher-benefits-page__motivation-banner {
  padding-bottom: 40px;
}
@media (min-width: 1024px) {
  .teacher-benefits-page__motivation-banner {
    padding-bottom: 160px;
  }
}
.teacher-benefits-page__bottom-simple-cta {
  padding: 0 0 50px;
}
@media (min-width: 1024px) {
  .teacher-benefits-page__bottom-simple-cta {
    padding: 0 0 110px;
  }
}
