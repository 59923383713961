.with-super-position {
  position: relative;
  display: inline-block;
}
.with-super-position__child {
  position: relative;
}
.with-super-position__top {
  position: relative;
  z-index: 5;
}
.with-super-position__top--fill-camelot svg {
  fill: #822d50;
}
.with-super-position__top--fill-emerald svg {
  fill: #32c864;
}
.with-super-position__top--fill-sun svg {
  fill: #faaa1e;
}
.with-super-position__top--fill-robbinsEggBlue svg {
  fill: #00d2c8;
}
.with-super-position__top--fill-ebonyClay svg {
  fill: #28283c;
}
.with-super-position__top--fill-azalea svg {
  fill: #fadcdc;
}
.with-super-position__top--fill-cruise svg {
  fill: #bff0db;
}
.with-super-position__top--fill-astra svg {
  fill: #fafabe;
}
.with-super-position__top--fill-gallery svg {
  fill: #f0f0f0;
}
.with-super-position__top--fill-humming-bird svg {
  fill: #d7f0fa;
}
.with-super-position__top--fill-humming-bird-faded svg {
  fill: #f0fafe;
}
.with-super-position__top--fill-silver-chalice svg {
  fill: #a6a6a6;
}
.with-super-position__top--fill-catskill-white svg {
  fill: #e1e9f0;
}
.with-super-position__top--fill-bali-hai svg {
  fill: #8298ab;
}
.with-super-position__top--fill-french-lilac svg {
  fill: #efe0f6;
}
.with-super-position__top--fill-deep-blush svg {
  fill: #e07688;
}
.with-super-position__top--fill-pink svg {
  fill: #fbdad8;
}
.with-super-position__top--fill-gothic svg {
  fill: #6991a1;
}
.with-super-position__top--fill-polar-blue svg {
  fill: #f0f9fc;
}
.with-super-position__top--fill-prussian svg {
  fill: #304659;
}
.with-super-position__top--fill-sky svg {
  fill: #e1e9f0;
}
.with-super-position__top--fill-tulip-tree svg {
  fill: #f0ac44;
}
.with-super-position__top--fill-emeraldish svg {
  fill: #03c2b9;
}
.with-super-position__top--fill-jumbo svg {
  fill: #7b7b86;
}
.with-super-position__top--fill-pampas svg {
  fill: #f8f7f5;
}
.with-super-position__top--fill-dark-gallery svg {
  fill: #eaeaea;
}
.with-super-position__top--fill-concrete svg {
  fill: #f2f2f2;
}
.with-super-position__top--fill-sand svg {
  fill: #fbfbfb;
}
.with-super-position__top--fill-black-squeeze svg {
  fill: #f9fcfd;
}
.with-super-position__top--fill-athens-gray svg {
  fill: #f4f4f4;
}
.with-super-position__top--fill-alto svg {
  fill: #d8d8d8;
}
.with-super-position__top--fill-gray svg {
  fill: #888;
}
.with-super-position__top--fill-venus svg {
  fill: #7e7e8a;
}
.with-super-position__top--fill-dolphin svg {
  fill: #6b6a77;
}
.with-super-position__top--fill-mercury svg {
  fill: #e6e6e6;
}
.with-super-position__top--fill-text-black svg {
  fill: #28283c;
}
.with-super-position__top--fill-polar svg {
  fill: #eafbfa;
}
.with-super-position__top--fill-tundora svg {
  fill: #484444;
}
.with-super-position__top--fill-jet svg {
  fill: #3e3e50;
}
.with-super-position__top--fill-silver svg {
  fill: #bdbdbd;
}
.with-super-position__top--fill-smoke svg {
  fill: #bfbfbf;
}
.with-super-position__top--fill-white svg {
  fill: #fff;
}
.with-super-position__top--fill-malachite svg {
  fill: #00c040;
}
.with-super-position__top--fill-light-green svg {
  fill: #c0f0dd;
}
.with-super-position__top--fill-coral svg {
  fill: #ff774d;
}
.with-super-position__top--fill-bright-sun svg {
  fill: #ffcc43;
}
.with-super-position__top--fill-shark svg {
  fill: #272a2d;
}
.with-super-position__top--fill-royal-blue svg {
  fill: #4945e2;
}
.with-super-position__top--fill-aquamarine-blue svg {
  fill: #7ce5df;
}
.with-super-position__top--fill-scorpion svg {
  fill: #595959;
}
.with-super-position__top--fill-heather svg {
  fill: #b8c5d0;
}
.with-super-position__top--fill-red svg {
  fill: #de2828;
}
.with-super-position__top--fill-lighter-teal svg {
  fill: #d6f6f3;
}
.with-super-position__top--fill-blue-lagoon svg {
  fill: #007986;
}
.with-super-position__top--fill-brightTeal svg {
  fill: #00d8c4;
}
