.footer {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 400;
  background-color: #28283c;
  padding: 0 16px 110px;
  color: #f8f7f5;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .footer {
    padding: 0 16px;
  }
}
@media (min-width: 577px) {
  .footer {
    padding: 0 16px;
  }
}
.footer__cookie-toast {
  position: fixed;
  z-index: 2000;
  bottom: 15px;
  left: 15px;
}
@media (min-width: 834px) {
  .footer__cookie-toast {
    bottom: 20px;
    left: 20px;
  }
}
.footer__container {
  display: flex;
  flex-direction: column;
  max-width: 1150px;
  margin: 0 auto;
  padding: 20px;
}
@media (min-width: 577px) {
  .footer__container {
    padding: 110px 0;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 835px) {
  .footer__container {
    padding: 160px 0;
  }
}
.footer__mobile-links {
  display: flex;
  margin: 40px 0 0;
}
@media (min-width: 577px) {
  .footer__mobile-links {
    display: none;
  }
}
.footer__mobile-links .footer__link {
  display: block;
  flex: 1;
}
.footer__logo {
  width: 100px;
  position: absolute;
  right: 44px;
  bottom: -10px;
}
@media (min-width: 577px) {
  .footer__aside {
    max-width: 248px;
  }
}
@media (min-width: 835px) {
  .footer__aside {
    max-width: 351px;
  }
}
.footer__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 29px;
  line-height: 84%;
  text-transform: uppercase;
  color: #d7f0fa;
}
@media (min-width: 835px) {
  .footer__title {
    font-size: 42px;
  }
}
.footer__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.3px;
  margin: 20px 0 20px 0;
  max-width: 440px;
  color: #d7f0fa;
}
@media (min-width: 835px) {
  .footer__description {
    font-size: 20px;
  }
}
.footer__social-icons {
  display: flex;
}
.footer__social-icon {
  fill: white;
}
.footer__social-icon + .footer__social-icon {
  margin-left: 15px;
}
.footer__link-sections {
  display: none;
}
@media (min-width: 577px) {
  .footer__link-sections {
    display: flex;
    flex: 1;
    margin-left: 90px;
    max-width: 60%;
  }
}
.footer__link-section {
  flex: 1;
  min-height: 200px;
}
.footer__links-title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12.7663px;
  line-height: 80%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgba(215, 240, 250, 0.33);
  margin: 28px 0 15px;
}
@media (min-width: 835px) {
  .footer__links-title {
    font-size: 18px;
    margin: 28px 0 22px;
  }
}
.footer__link-list {
  list-style-type: none;
  padding: 0;
}
.footer__link-list-item {
  margin-top: 7px;
}
@media (min-width: 835px) {
  .footer__link-list-item {
    margin-top: 14px;
  }
}
.footer__link {
  text-decoration: none;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 14px;
  line-height: 90%;
  color: #f8f7f5;
  cursor: pointer;
}
@media (min-width: 835px) {
  .footer__link {
    font-size: 20px;
  }
}
.footer__link:hover {
  text-decoration: underline;
}
.footer .footer__links {
  margin: 40px 0 0 20px;
}
.footer .footer__links a {
  color: #f8f7f5;
}
