.bundle-card {
  display: inline-block;
  max-width: 358px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}
.bundle-card__image {
  width: 100%;
}
.bundle-card__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  color: #28283c;
}
.bundle-card__info-row {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #28283c;
}
.bundle-card__info-value {
  font-weight: 500;
}
.bundle-card__book-now-button {
  width: 176px;
}
