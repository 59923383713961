.image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.image--active .image__thumbnail {
  opacity: 0;
}
.image__thumbnail {
  display: block;
  position: relative;
  z-index: 2;
  transform: scale(1.15);
  filter: blur(20px);
  opacity: 1;
  will-change: opacity;
  transition: opacity 350ms ease-in-out;
  width: 100%;
  pointer-events: none;
}
.image__thumbnail--full-height {
  width: auto;
  height: 100%;
}
.image__image-wrapper {
  display: block;
  width: 100%;
}
.image__image-wrapper--abs {
  position: absolute;
  top: 0;
  left: 0;
}
.image__image-wrapper--bg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.image__image-wrapper--full-height {
  width: auto;
  height: 100%;
}
.image__image {
  display: block;
  width: 100%;
}
.image__image--full-height {
  width: auto;
  height: 100%;
}
