.icon-blurb__wrapper {
  min-height: 24px;
}
.icon-blurb__wrapper--inline {
  display: flex;
  align-items: flex-start;
}
.icon-blurb__icon {
  opacity: 0.6;
  margin-right: 10px;
  margin-top: 1px;
  display: block;
}
.icon-blurb__icon--color-camelot {
  fill: #822d50;
  stroke: #822d50;
}
.icon-blurb__icon--color-emerald {
  fill: #32c864;
  stroke: #32c864;
}
.icon-blurb__icon--color-sun {
  fill: #faaa1e;
  stroke: #faaa1e;
}
.icon-blurb__icon--color-robbinsEggBlue {
  fill: #00d2c8;
  stroke: #00d2c8;
}
.icon-blurb__icon--color-ebonyClay {
  fill: #28283c;
  stroke: #28283c;
}
.icon-blurb__icon--color-azalea {
  fill: #fadcdc;
  stroke: #fadcdc;
}
.icon-blurb__icon--color-cruise {
  fill: #bff0db;
  stroke: #bff0db;
}
.icon-blurb__icon--color-astra {
  fill: #fafabe;
  stroke: #fafabe;
}
.icon-blurb__icon--color-gallery {
  fill: #f0f0f0;
  stroke: #f0f0f0;
}
.icon-blurb__icon--color-humming-bird {
  fill: #d7f0fa;
  stroke: #d7f0fa;
}
.icon-blurb__icon--color-humming-bird-faded {
  fill: #f0fafe;
  stroke: #f0fafe;
}
.icon-blurb__icon--color-silver-chalice {
  fill: #a6a6a6;
  stroke: #a6a6a6;
}
.icon-blurb__icon--color-catskill-white {
  fill: #e1e9f0;
  stroke: #e1e9f0;
}
.icon-blurb__icon--color-bali-hai {
  fill: #8298ab;
  stroke: #8298ab;
}
.icon-blurb__icon--color-french-lilac {
  fill: #efe0f6;
  stroke: #efe0f6;
}
.icon-blurb__icon--color-deep-blush {
  fill: #e07688;
  stroke: #e07688;
}
.icon-blurb__icon--color-pink {
  fill: #fbdad8;
  stroke: #fbdad8;
}
.icon-blurb__icon--color-gothic {
  fill: #6991a1;
  stroke: #6991a1;
}
.icon-blurb__icon--color-polar-blue {
  fill: #f0f9fc;
  stroke: #f0f9fc;
}
.icon-blurb__icon--color-prussian {
  fill: #304659;
  stroke: #304659;
}
.icon-blurb__icon--color-sky {
  fill: #e1e9f0;
  stroke: #e1e9f0;
}
.icon-blurb__icon--color-tulip-tree {
  fill: #f0ac44;
  stroke: #f0ac44;
}
.icon-blurb__icon--color-emeraldish {
  fill: #03c2b9;
  stroke: #03c2b9;
}
.icon-blurb__icon--color-jumbo {
  fill: #7b7b86;
  stroke: #7b7b86;
}
.icon-blurb__icon--color-pampas {
  fill: #f8f7f5;
  stroke: #f8f7f5;
}
.icon-blurb__icon--color-dark-gallery {
  fill: #eaeaea;
  stroke: #eaeaea;
}
.icon-blurb__icon--color-concrete {
  fill: #f2f2f2;
  stroke: #f2f2f2;
}
.icon-blurb__icon--color-sand {
  fill: #fbfbfb;
  stroke: #fbfbfb;
}
.icon-blurb__icon--color-black-squeeze {
  fill: #f9fcfd;
  stroke: #f9fcfd;
}
.icon-blurb__icon--color-athens-gray {
  fill: #f4f4f4;
  stroke: #f4f4f4;
}
.icon-blurb__icon--color-alto {
  fill: #d8d8d8;
  stroke: #d8d8d8;
}
.icon-blurb__icon--color-gray {
  fill: #888;
  stroke: #888;
}
.icon-blurb__icon--color-venus {
  fill: #7e7e8a;
  stroke: #7e7e8a;
}
.icon-blurb__icon--color-dolphin {
  fill: #6b6a77;
  stroke: #6b6a77;
}
.icon-blurb__icon--color-mercury {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}
.icon-blurb__icon--color-text-black {
  fill: #28283c;
  stroke: #28283c;
}
.icon-blurb__icon--color-polar {
  fill: #eafbfa;
  stroke: #eafbfa;
}
.icon-blurb__icon--color-tundora {
  fill: #484444;
  stroke: #484444;
}
.icon-blurb__icon--color-jet {
  fill: #3e3e50;
  stroke: #3e3e50;
}
.icon-blurb__icon--color-silver {
  fill: #bdbdbd;
  stroke: #bdbdbd;
}
.icon-blurb__icon--color-smoke {
  fill: #bfbfbf;
  stroke: #bfbfbf;
}
.icon-blurb__icon--color-white {
  fill: #fff;
  stroke: #fff;
}
.icon-blurb__icon--color-malachite {
  fill: #00c040;
  stroke: #00c040;
}
.icon-blurb__icon--color-light-green {
  fill: #c0f0dd;
  stroke: #c0f0dd;
}
.icon-blurb__icon--color-coral {
  fill: #ff774d;
  stroke: #ff774d;
}
.icon-blurb__icon--color-bright-sun {
  fill: #ffcc43;
  stroke: #ffcc43;
}
.icon-blurb__icon--color-shark {
  fill: #272a2d;
  stroke: #272a2d;
}
.icon-blurb__icon--color-royal-blue {
  fill: #4945e2;
  stroke: #4945e2;
}
.icon-blurb__icon--color-aquamarine-blue {
  fill: #7ce5df;
  stroke: #7ce5df;
}
.icon-blurb__icon--color-scorpion {
  fill: #595959;
  stroke: #595959;
}
.icon-blurb__icon--color-heather {
  fill: #b8c5d0;
  stroke: #b8c5d0;
}
.icon-blurb__icon--color-red {
  fill: #de2828;
  stroke: #de2828;
}
.icon-blurb__icon--color-lighter-teal {
  fill: #d6f6f3;
  stroke: #d6f6f3;
}
.icon-blurb__icon--color-blue-lagoon {
  fill: #007986;
  stroke: #007986;
}
.icon-blurb__icon--color-brightTeal {
  fill: #00d8c4;
  stroke: #00d8c4;
}
.icon-blurb__icon--size-sm:not([width]) {
  width: 15px;
  flex: 0 0 15px;
}
.icon-blurb__icon--size-md:not([width]) {
  width: 16px;
  flex: 0 0 16px;
}
.icon-blurb__text {
  margin: 0;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1;
}
.icon-blurb__text--size-sm {
  font-size: 16px;
}
.icon-blurb__text--size-md {
  font-size: 18px;
}
