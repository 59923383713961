.simple-banner {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  border-radius: 4px;
  background-color: white;
  color: #28283c;
  border-top: solid 12px;
}
.simple-banner--accent-emerald {
  border-color: #32c864;
}
.simple-banner--accent-royal-blue {
  border-color: #4945e2;
}
.simple-banner--accent-lavender {
  border-color: #cb87da;
}
.simple-banner__title {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  letter-spacing: 0.01em;
}
.simple-banner__footer {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  line-height: 100%;
  letter-spacing: 0.01em;
  padding: 20px;
  color: #00d2c8;
}
.simple-banner__footer--border {
  border-top: solid 1px #dddde0;
}
