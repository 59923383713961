.banner-card {
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 386px;
}
@media (min-width: 572px) {
  .banner-card {
    flex-direction: row;
    min-height: initial;
  }
}
.banner-card--bg-camelot {
  background-color: #822d50;
}
.banner-card--bg-emerald {
  background-color: #32c864;
}
.banner-card--bg-sun {
  background-color: #faaa1e;
}
.banner-card--bg-robbinsEggBlue {
  background-color: #00d2c8;
}
.banner-card--bg-ebonyClay {
  background-color: #28283c;
}
.banner-card--bg-azalea {
  background-color: #fadcdc;
}
.banner-card--bg-cruise {
  background-color: #bff0db;
}
.banner-card--bg-astra {
  background-color: #fafabe;
}
.banner-card--bg-gallery {
  background-color: #f0f0f0;
}
.banner-card--bg-humming-bird {
  background-color: #d7f0fa;
}
.banner-card--bg-humming-bird-faded {
  background-color: #f0fafe;
}
.banner-card--bg-silver-chalice {
  background-color: #a6a6a6;
}
.banner-card--bg-catskill-white {
  background-color: #e1e9f0;
}
.banner-card--bg-bali-hai {
  background-color: #8298ab;
}
.banner-card--bg-french-lilac {
  background-color: #efe0f6;
}
.banner-card--bg-deep-blush {
  background-color: #e07688;
}
.banner-card--bg-pink {
  background-color: #fbdad8;
}
.banner-card--bg-gothic {
  background-color: #6991a1;
}
.banner-card--bg-polar-blue {
  background-color: #f0f9fc;
}
.banner-card--bg-prussian {
  background-color: #304659;
}
.banner-card--bg-sky {
  background-color: #e1e9f0;
}
.banner-card--bg-tulip-tree {
  background-color: #f0ac44;
}
.banner-card--bg-emeraldish {
  background-color: #03c2b9;
}
.banner-card--bg-jumbo {
  background-color: #7b7b86;
}
.banner-card--bg-pampas {
  background-color: #f8f7f5;
}
.banner-card--bg-dark-gallery {
  background-color: #eaeaea;
}
.banner-card--bg-concrete {
  background-color: #f2f2f2;
}
.banner-card--bg-sand {
  background-color: #fbfbfb;
}
.banner-card--bg-black-squeeze {
  background-color: #f9fcfd;
}
.banner-card--bg-athens-gray {
  background-color: #f4f4f4;
}
.banner-card--bg-alto {
  background-color: #d8d8d8;
}
.banner-card--bg-gray {
  background-color: #888;
}
.banner-card--bg-venus {
  background-color: #7e7e8a;
}
.banner-card--bg-dolphin {
  background-color: #6b6a77;
}
.banner-card--bg-mercury {
  background-color: #e6e6e6;
}
.banner-card--bg-text-black {
  background-color: #28283c;
}
.banner-card--bg-polar {
  background-color: #eafbfa;
}
.banner-card--bg-tundora {
  background-color: #484444;
}
.banner-card--bg-jet {
  background-color: #3e3e50;
}
.banner-card--bg-silver {
  background-color: #bdbdbd;
}
.banner-card--bg-smoke {
  background-color: #bfbfbf;
}
.banner-card--bg-white {
  background-color: #fff;
}
.banner-card--bg-malachite {
  background-color: #00c040;
}
.banner-card--bg-light-green {
  background-color: #c0f0dd;
}
.banner-card--bg-coral {
  background-color: #ff774d;
}
.banner-card--bg-bright-sun {
  background-color: #ffcc43;
}
.banner-card--bg-shark {
  background-color: #272a2d;
}
.banner-card--bg-royal-blue {
  background-color: #4945e2;
}
.banner-card--bg-aquamarine-blue {
  background-color: #7ce5df;
}
.banner-card--bg-scorpion {
  background-color: #595959;
}
.banner-card--bg-heather {
  background-color: #b8c5d0;
}
.banner-card--bg-red {
  background-color: #de2828;
}
.banner-card--bg-lighter-teal {
  background-color: #d6f6f3;
}
.banner-card--bg-blue-lagoon {
  background-color: #007986;
}
.banner-card--bg-brightTeal {
  background-color: #00d8c4;
}
.banner-card__content {
  padding: 24px;
  color: white;
}
@media (min-width: 572px) {
  .banner-card__content {
    flex: 1 1 48%;
    padding: 36px 24px;
  }
}
.banner-card__title {
  display: block;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.banner-card__description {
  display: block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 120%;
}
.banner-card__picture {
  position: relative;
  flex: 1 1;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}
@media (min-width: 572px) {
  .banner-card__picture {
    flex: 1 1 45%;
  }
}
@media (max-width: 400px) {
  .banner-card__picture button {
    font-size: 12px;
    padding: 8px 12px;
    height: 32px;
  }
}
.banner-card__button {
  margin-top: 12px;
}
@media (min-width: 572px) {
  .banner-card__button {
    margin-top: 24px;
  }
}
.banner-card__sticker {
  position: absolute;
  right: 20px;
  top: -20px;
  width: 60px;
  z-index: 3;
  transform: matrix(0.89, -0.45, 0.46, 0.89, 0, 0);
}
@media (min-width: 572px) {
  .banner-card__sticker {
    width: 100px;
    left: -50px;
    bottom: 0;
    top: initial;
  }
}
.banner-card__button {
  color: #28283c;
}
