.menu-nav {
  position: relative;
  transition: background-color 250ms ease-in-out;
}
.menu-nav--is-active {
  background-color: white;
}
.menu-nav__wrapper {
  display: flex;
  padding: 0 15px;
  height: 73px;
  justify-content: space-between;
}
@media (min-width: 700px) {
  .menu-nav__wrapper {
    padding: 0 30px;
  }
}
@media (min-width: 1024px) {
  .menu-nav__wrapper {
    justify-content: flex-start;
    padding: 0 40px;
    height: 90px;
  }
}
.menu-nav__logo {
  display: flex;
  margin-top: 10px;
}
@media (min-width: 700px) {
  .menu-nav__logo {
    margin-top: -6px;
  }
}
.menu-nav__logo-icon {
  height: 53px;
}
.menu-nav__logo-icon .background {
  fill: #00d2c8;
}
@media (min-width: 700px) {
  .menu-nav__logo-icon {
    transform: rotate(-5.25deg);
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .menu-nav__logo-icon {
    height: 86px;
  }
}
@media (min-width: 1024px) {
  .menu-nav .drawer {
    display: none;
  }
}
.what-you-can-learn__link {
  line-height: 100%;
  color: #28283c;
  letter-spacing: 0.01em;
  font-size: 21px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .what-you-can-learn__link {
    font-size: 18px;
  }
}
.what-you-can-learn__link:active,
.what-you-can-learn__link:focus,
.what-you-can-learn__link:hover,
.what-you-can-learn__link:visited {
  color: #28283c;
  text-decoration: none;
}
