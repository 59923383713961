.landing-header {
  position: relative;
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 834px) {
  .landing-header {
    height: auto;
  }
}
.landing-header--camelot {
  background-color: #822d50;
}
.landing-header--emerald {
  background-color: #32c864;
}
.landing-header--sun {
  background-color: #faaa1e;
}
.landing-header--robbinsEggBlue {
  background-color: #00d2c8;
}
.landing-header--ebonyClay {
  background-color: #28283c;
}
.landing-header--azalea {
  background-color: #fadcdc;
}
.landing-header--cruise {
  background-color: #bff0db;
}
.landing-header--astra {
  background-color: #fafabe;
}
.landing-header--gallery {
  background-color: #f0f0f0;
}
.landing-header--humming-bird {
  background-color: #d7f0fa;
}
.landing-header--humming-bird-faded {
  background-color: #f0fafe;
}
.landing-header--silver-chalice {
  background-color: #a6a6a6;
}
.landing-header--catskill-white {
  background-color: #e1e9f0;
}
.landing-header--bali-hai {
  background-color: #8298ab;
}
.landing-header--french-lilac {
  background-color: #efe0f6;
}
.landing-header--deep-blush {
  background-color: #e07688;
}
.landing-header--pink {
  background-color: #fbdad8;
}
.landing-header--gothic {
  background-color: #6991a1;
}
.landing-header--polar-blue {
  background-color: #f0f9fc;
}
.landing-header--prussian {
  background-color: #304659;
}
.landing-header--sky {
  background-color: #e1e9f0;
}
.landing-header--tulip-tree {
  background-color: #f0ac44;
}
.landing-header--emeraldish {
  background-color: #03c2b9;
}
.landing-header--jumbo {
  background-color: #7b7b86;
}
.landing-header--pampas {
  background-color: #f8f7f5;
}
.landing-header--dark-gallery {
  background-color: #eaeaea;
}
.landing-header--concrete {
  background-color: #f2f2f2;
}
.landing-header--sand {
  background-color: #fbfbfb;
}
.landing-header--black-squeeze {
  background-color: #f9fcfd;
}
.landing-header--athens-gray {
  background-color: #f4f4f4;
}
.landing-header--alto {
  background-color: #d8d8d8;
}
.landing-header--gray {
  background-color: #888;
}
.landing-header--venus {
  background-color: #7e7e8a;
}
.landing-header--dolphin {
  background-color: #6b6a77;
}
.landing-header--mercury {
  background-color: #e6e6e6;
}
.landing-header--text-black {
  background-color: #28283c;
}
.landing-header--polar {
  background-color: #eafbfa;
}
.landing-header--tundora {
  background-color: #484444;
}
.landing-header--jet {
  background-color: #3e3e50;
}
.landing-header--silver {
  background-color: #bdbdbd;
}
.landing-header--smoke {
  background-color: #bfbfbf;
}
.landing-header--white {
  background-color: #fff;
}
.landing-header--malachite {
  background-color: #00c040;
}
.landing-header--light-green {
  background-color: #c0f0dd;
}
.landing-header--coral {
  background-color: #ff774d;
}
.landing-header--bright-sun {
  background-color: #ffcc43;
}
.landing-header--shark {
  background-color: #272a2d;
}
.landing-header--royal-blue {
  background-color: #4945e2;
}
.landing-header--aquamarine-blue {
  background-color: #7ce5df;
}
.landing-header--scorpion {
  background-color: #595959;
}
.landing-header--heather {
  background-color: #b8c5d0;
}
.landing-header--red {
  background-color: #de2828;
}
.landing-header--lighter-teal {
  background-color: #d6f6f3;
}
.landing-header--blue-lagoon {
  background-color: #007986;
}
.landing-header--brightTeal {
  background-color: #00d8c4;
}
.landing-header__popout-image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 95px;
  display: none;
}
@media (max-width: 834px) and (min-width: 577px) {
  .landing-header__popout-image-wrapper {
    display: block;
  }
}
.landing-header__popout-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.landing-header__body {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}
@media (min-width: 835px) and (max-width: 1150px) {
  .landing-header__body {
    flex-direction: initial;
    flex-wrap: nowrap;
    width: 100%;
  }
}
@media (min-width: 1150px) {
  .landing-header__body {
    flex-direction: initial;
    flex-wrap: nowrap;
    max-width: 1230px;
    justify-content: flex-start;
  }
}
.landing-header__right-background {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
}
.landing-header__right-background--camelot {
  background-color: #822d50;
}
.landing-header__right-background--emerald {
  background-color: #32c864;
}
.landing-header__right-background--sun {
  background-color: #faaa1e;
}
.landing-header__right-background--robbinsEggBlue {
  background-color: #00d2c8;
}
.landing-header__right-background--ebonyClay {
  background-color: #28283c;
}
.landing-header__right-background--azalea {
  background-color: #fadcdc;
}
.landing-header__right-background--cruise {
  background-color: #bff0db;
}
.landing-header__right-background--astra {
  background-color: #fafabe;
}
.landing-header__right-background--gallery {
  background-color: #f0f0f0;
}
.landing-header__right-background--humming-bird {
  background-color: #d7f0fa;
}
.landing-header__right-background--humming-bird-faded {
  background-color: #f0fafe;
}
.landing-header__right-background--silver-chalice {
  background-color: #a6a6a6;
}
.landing-header__right-background--catskill-white {
  background-color: #e1e9f0;
}
.landing-header__right-background--bali-hai {
  background-color: #8298ab;
}
.landing-header__right-background--french-lilac {
  background-color: #efe0f6;
}
.landing-header__right-background--deep-blush {
  background-color: #e07688;
}
.landing-header__right-background--pink {
  background-color: #fbdad8;
}
.landing-header__right-background--gothic {
  background-color: #6991a1;
}
.landing-header__right-background--polar-blue {
  background-color: #f0f9fc;
}
.landing-header__right-background--prussian {
  background-color: #304659;
}
.landing-header__right-background--sky {
  background-color: #e1e9f0;
}
.landing-header__right-background--tulip-tree {
  background-color: #f0ac44;
}
.landing-header__right-background--emeraldish {
  background-color: #03c2b9;
}
.landing-header__right-background--jumbo {
  background-color: #7b7b86;
}
.landing-header__right-background--pampas {
  background-color: #f8f7f5;
}
.landing-header__right-background--dark-gallery {
  background-color: #eaeaea;
}
.landing-header__right-background--concrete {
  background-color: #f2f2f2;
}
.landing-header__right-background--sand {
  background-color: #fbfbfb;
}
.landing-header__right-background--black-squeeze {
  background-color: #f9fcfd;
}
.landing-header__right-background--athens-gray {
  background-color: #f4f4f4;
}
.landing-header__right-background--alto {
  background-color: #d8d8d8;
}
.landing-header__right-background--gray {
  background-color: #888;
}
.landing-header__right-background--venus {
  background-color: #7e7e8a;
}
.landing-header__right-background--dolphin {
  background-color: #6b6a77;
}
.landing-header__right-background--mercury {
  background-color: #e6e6e6;
}
.landing-header__right-background--text-black {
  background-color: #28283c;
}
.landing-header__right-background--polar {
  background-color: #eafbfa;
}
.landing-header__right-background--tundora {
  background-color: #484444;
}
.landing-header__right-background--jet {
  background-color: #3e3e50;
}
.landing-header__right-background--silver {
  background-color: #bdbdbd;
}
.landing-header__right-background--smoke {
  background-color: #bfbfbf;
}
.landing-header__right-background--white {
  background-color: #fff;
}
.landing-header__right-background--malachite {
  background-color: #00c040;
}
.landing-header__right-background--light-green {
  background-color: #c0f0dd;
}
.landing-header__right-background--coral {
  background-color: #ff774d;
}
.landing-header__right-background--bright-sun {
  background-color: #ffcc43;
}
.landing-header__right-background--shark {
  background-color: #272a2d;
}
.landing-header__right-background--royal-blue {
  background-color: #4945e2;
}
.landing-header__right-background--aquamarine-blue {
  background-color: #7ce5df;
}
.landing-header__right-background--scorpion {
  background-color: #595959;
}
.landing-header__right-background--heather {
  background-color: #b8c5d0;
}
.landing-header__right-background--red {
  background-color: #de2828;
}
.landing-header__right-background--lighter-teal {
  background-color: #d6f6f3;
}
.landing-header__right-background--blue-lagoon {
  background-color: #007986;
}
.landing-header__right-background--brightTeal {
  background-color: #00d8c4;
}
@media (min-width: 835px) {
  .landing-header__right-background {
    display: block;
  }
}
.landing-header__column-body {
  width: 100%;
  max-width: 450px;
}
@media (min-width: 576px) {
  .landing-header__column-body {
    max-width: 100%;
  }
}
.landing-header__text-column {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 113px 0 28px;
}
@media (min-width: 834px) {
  .landing-header__text-column {
    width: 50%;
    padding: 113px 50px 113px 0;
  }
}
.landing-header__breadcrumbs {
  display: none;
  margin-bottom: 9px;
  opacity: 0.5;
  font-size: 12px;
}
@media (min-width: 834px) {
  .landing-header__breadcrumbs {
    display: block;
  }
}
.landing-header__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 58px;
  line-height: 84%;
  text-transform: uppercase;
  padding: 0 0 34px;
  font-weight: bold;
  max-width: 462px;
}
.landing-header__title--camelot,
.landing-header__title--ebonyClay,
.landing-header__title--emeraldish {
  color: #f8f7f5;
}
.landing-header__title--light-green {
  color: #f8f7f5;
}
@media (min-width: 834px) {
  .landing-header__title--light-green {
    color: #28283c;
  }
}
.landing-header__title--astra,
.landing-header__title--emerald,
.landing-header__title--azalea,
.landing-header__title--cruise,
.landing-header__title--robbinsEggBlue,
.landing-header__title--humming-bird {
  color: #28283c;
}
.landing-header__title--sun {
  color: white;
}
@media (min-width: 1440px) {
  .landing-header__title {
    font-size: 60px;
  }
}
.landing-header__description {
  color: #28283c;
  padding-bottom: 27px;
  max-width: 602px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 124%;
  letter-spacing: 0.01em;
}
@media (min-width: 834px) {
  .landing-header__description {
    max-width: 497px;
  }
}
.landing-header__description--camelot,
.landing-header__description--robbinsEggBlue,
.landing-header__description--ebonyClay,
.landing-header__description--emeraldish {
  color: #f8f7f5;
}
.landing-header__description--light-green {
  color: #f8f7f5;
}
@media (min-width: 835px) {
  .landing-header__description--light-green {
    color: #28283c;
  }
}
.landing-header__description--emerald,
.landing-header__description--azalea,
.landing-header__description--cruise,
.landing-header__description--astra,
.landing-header__description--humming-bird {
  color: #28283c;
}
.landing-header__description--sun {
  color: #f0f0f0;
}
.landing-header__input {
  max-width: 500px;
}
@media (max-width: 834px) {
  .landing-header__input {
    max-width: 420px;
  }
}
.landing-header__image-column {
  display: none;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px 0;
}
@media (min-width: 834px) {
  .landing-header__image-column {
    display: flex;
  }
}
.landing-header__image-column--camelot {
  background-color: #822d50;
}
.landing-header__image-column--emerald {
  background-color: #32c864;
}
.landing-header__image-column--sun {
  background-color: #faaa1e;
}
.landing-header__image-column--robbinsEggBlue {
  background-color: #00d2c8;
}
.landing-header__image-column--ebonyClay {
  background-color: #28283c;
}
.landing-header__image-column--azalea {
  background-color: #fadcdc;
}
.landing-header__image-column--cruise {
  background-color: #bff0db;
}
.landing-header__image-column--astra {
  background-color: #fafabe;
}
.landing-header__image-column--gallery {
  background-color: #f0f0f0;
}
.landing-header__image-column--humming-bird {
  background-color: #d7f0fa;
}
.landing-header__image-column--humming-bird-faded {
  background-color: #f0fafe;
}
.landing-header__image-column--silver-chalice {
  background-color: #a6a6a6;
}
.landing-header__image-column--catskill-white {
  background-color: #e1e9f0;
}
.landing-header__image-column--bali-hai {
  background-color: #8298ab;
}
.landing-header__image-column--french-lilac {
  background-color: #efe0f6;
}
.landing-header__image-column--deep-blush {
  background-color: #e07688;
}
.landing-header__image-column--pink {
  background-color: #fbdad8;
}
.landing-header__image-column--gothic {
  background-color: #6991a1;
}
.landing-header__image-column--polar-blue {
  background-color: #f0f9fc;
}
.landing-header__image-column--prussian {
  background-color: #304659;
}
.landing-header__image-column--sky {
  background-color: #e1e9f0;
}
.landing-header__image-column--tulip-tree {
  background-color: #f0ac44;
}
.landing-header__image-column--emeraldish {
  background-color: #03c2b9;
}
.landing-header__image-column--jumbo {
  background-color: #7b7b86;
}
.landing-header__image-column--pampas {
  background-color: #f8f7f5;
}
.landing-header__image-column--dark-gallery {
  background-color: #eaeaea;
}
.landing-header__image-column--concrete {
  background-color: #f2f2f2;
}
.landing-header__image-column--sand {
  background-color: #fbfbfb;
}
.landing-header__image-column--black-squeeze {
  background-color: #f9fcfd;
}
.landing-header__image-column--athens-gray {
  background-color: #f4f4f4;
}
.landing-header__image-column--alto {
  background-color: #d8d8d8;
}
.landing-header__image-column--gray {
  background-color: #888;
}
.landing-header__image-column--venus {
  background-color: #7e7e8a;
}
.landing-header__image-column--dolphin {
  background-color: #6b6a77;
}
.landing-header__image-column--mercury {
  background-color: #e6e6e6;
}
.landing-header__image-column--text-black {
  background-color: #28283c;
}
.landing-header__image-column--polar {
  background-color: #eafbfa;
}
.landing-header__image-column--tundora {
  background-color: #484444;
}
.landing-header__image-column--jet {
  background-color: #3e3e50;
}
.landing-header__image-column--silver {
  background-color: #bdbdbd;
}
.landing-header__image-column--smoke {
  background-color: #bfbfbf;
}
.landing-header__image-column--white {
  background-color: #fff;
}
.landing-header__image-column--malachite {
  background-color: #00c040;
}
.landing-header__image-column--light-green {
  background-color: #c0f0dd;
}
.landing-header__image-column--coral {
  background-color: #ff774d;
}
.landing-header__image-column--bright-sun {
  background-color: #ffcc43;
}
.landing-header__image-column--shark {
  background-color: #272a2d;
}
.landing-header__image-column--royal-blue {
  background-color: #4945e2;
}
.landing-header__image-column--aquamarine-blue {
  background-color: #7ce5df;
}
.landing-header__image-column--scorpion {
  background-color: #595959;
}
.landing-header__image-column--heather {
  background-color: #b8c5d0;
}
.landing-header__image-column--red {
  background-color: #de2828;
}
.landing-header__image-column--lighter-teal {
  background-color: #d6f6f3;
}
.landing-header__image-column--blue-lagoon {
  background-color: #007986;
}
.landing-header__image-column--brightTeal {
  background-color: #00d8c4;
}
.landing-header__full-image-mobile-column {
  display: block;
}
@media (min-width: 835px) {
  .landing-header__full-image-mobile-column {
    display: none;
  }
}
.landing-header__image-wrap {
  width: calc(100% + 40px);
  overflow: hidden;
  margin-left: -40px;
}
.landing-header__full-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.landing-header__full-image .image__image-wrapper--bg {
  background-size: contain;
}
.landing-header__image {
  width: 100%;
}
.landing-header__sticker {
  position: absolute;
}
.landing-header__sticker--bottom-right {
  right: -15px;
  bottom: -15px;
  transform: rotate(-10deg);
}
.landing-header__sticker--bottom-left {
  left: 20px;
  bottom: -30px;
  transform: rotate(10deg);
}
@media (max-width: 834px) {
  .landing-header--md-camelot {
    background-color: #822d50;
  }
}
@media (max-width: 834px) {
  .landing-header--md-emerald {
    background-color: #32c864;
  }
}
@media (max-width: 834px) {
  .landing-header--md-sun {
    background-color: #faaa1e;
  }
}
@media (max-width: 834px) {
  .landing-header--md-robbinsEggBlue {
    background-color: #00d2c8;
  }
}
@media (max-width: 834px) {
  .landing-header--md-ebonyClay {
    background-color: #28283c;
  }
}
@media (max-width: 834px) {
  .landing-header--md-azalea {
    background-color: #fadcdc;
  }
}
@media (max-width: 834px) {
  .landing-header--md-cruise {
    background-color: #bff0db;
  }
}
@media (max-width: 834px) {
  .landing-header--md-astra {
    background-color: #fafabe;
  }
}
@media (max-width: 834px) {
  .landing-header--md-gallery {
    background-color: #f0f0f0;
  }
}
@media (max-width: 834px) {
  .landing-header--md-humming-bird {
    background-color: #d7f0fa;
  }
}
@media (max-width: 834px) {
  .landing-header--md-humming-bird-faded {
    background-color: #f0fafe;
  }
}
@media (max-width: 834px) {
  .landing-header--md-silver-chalice {
    background-color: #a6a6a6;
  }
}
@media (max-width: 834px) {
  .landing-header--md-catskill-white {
    background-color: #e1e9f0;
  }
}
@media (max-width: 834px) {
  .landing-header--md-bali-hai {
    background-color: #8298ab;
  }
}
@media (max-width: 834px) {
  .landing-header--md-french-lilac {
    background-color: #efe0f6;
  }
}
@media (max-width: 834px) {
  .landing-header--md-deep-blush {
    background-color: #e07688;
  }
}
@media (max-width: 834px) {
  .landing-header--md-pink {
    background-color: #fbdad8;
  }
}
@media (max-width: 834px) {
  .landing-header--md-gothic {
    background-color: #6991a1;
  }
}
@media (max-width: 834px) {
  .landing-header--md-polar-blue {
    background-color: #f0f9fc;
  }
}
@media (max-width: 834px) {
  .landing-header--md-prussian {
    background-color: #304659;
  }
}
@media (max-width: 834px) {
  .landing-header--md-sky {
    background-color: #e1e9f0;
  }
}
@media (max-width: 834px) {
  .landing-header--md-tulip-tree {
    background-color: #f0ac44;
  }
}
@media (max-width: 834px) {
  .landing-header--md-emeraldish {
    background-color: #03c2b9;
  }
}
@media (max-width: 834px) {
  .landing-header--md-jumbo {
    background-color: #7b7b86;
  }
}
@media (max-width: 834px) {
  .landing-header--md-pampas {
    background-color: #f8f7f5;
  }
}
@media (max-width: 834px) {
  .landing-header--md-dark-gallery {
    background-color: #eaeaea;
  }
}
@media (max-width: 834px) {
  .landing-header--md-concrete {
    background-color: #f2f2f2;
  }
}
@media (max-width: 834px) {
  .landing-header--md-sand {
    background-color: #fbfbfb;
  }
}
@media (max-width: 834px) {
  .landing-header--md-black-squeeze {
    background-color: #f9fcfd;
  }
}
@media (max-width: 834px) {
  .landing-header--md-athens-gray {
    background-color: #f4f4f4;
  }
}
@media (max-width: 834px) {
  .landing-header--md-alto {
    background-color: #d8d8d8;
  }
}
@media (max-width: 834px) {
  .landing-header--md-gray {
    background-color: #888;
  }
}
@media (max-width: 834px) {
  .landing-header--md-venus {
    background-color: #7e7e8a;
  }
}
@media (max-width: 834px) {
  .landing-header--md-dolphin {
    background-color: #6b6a77;
  }
}
@media (max-width: 834px) {
  .landing-header--md-mercury {
    background-color: #e6e6e6;
  }
}
@media (max-width: 834px) {
  .landing-header--md-text-black {
    background-color: #28283c;
  }
}
@media (max-width: 834px) {
  .landing-header--md-polar {
    background-color: #eafbfa;
  }
}
@media (max-width: 834px) {
  .landing-header--md-tundora {
    background-color: #484444;
  }
}
@media (max-width: 834px) {
  .landing-header--md-jet {
    background-color: #3e3e50;
  }
}
@media (max-width: 834px) {
  .landing-header--md-silver {
    background-color: #bdbdbd;
  }
}
@media (max-width: 834px) {
  .landing-header--md-smoke {
    background-color: #bfbfbf;
  }
}
@media (max-width: 834px) {
  .landing-header--md-white {
    background-color: #fff;
  }
}
@media (max-width: 834px) {
  .landing-header--md-malachite {
    background-color: #00c040;
  }
}
@media (max-width: 834px) {
  .landing-header--md-light-green {
    background-color: #c0f0dd;
  }
}
@media (max-width: 834px) {
  .landing-header--md-coral {
    background-color: #ff774d;
  }
}
@media (max-width: 834px) {
  .landing-header--md-bright-sun {
    background-color: #ffcc43;
  }
}
@media (max-width: 834px) {
  .landing-header--md-shark {
    background-color: #272a2d;
  }
}
@media (max-width: 834px) {
  .landing-header--md-royal-blue {
    background-color: #4945e2;
  }
}
@media (max-width: 834px) {
  .landing-header--md-aquamarine-blue {
    background-color: #7ce5df;
  }
}
@media (max-width: 834px) {
  .landing-header--md-scorpion {
    background-color: #595959;
  }
}
@media (max-width: 834px) {
  .landing-header--md-heather {
    background-color: #b8c5d0;
  }
}
@media (max-width: 834px) {
  .landing-header--md-red {
    background-color: #de2828;
  }
}
@media (max-width: 834px) {
  .landing-header--md-lighter-teal {
    background-color: #d6f6f3;
  }
}
@media (max-width: 834px) {
  .landing-header--md-blue-lagoon {
    background-color: #007986;
  }
}
@media (max-width: 834px) {
  .landing-header--md-brightTeal {
    background-color: #00d8c4;
  }
}
