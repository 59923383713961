.gallery {
  position: relative;
}
.gallery__placeholder {
  padding-top: 50%;
}
.gallery__thumbnails {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gallery__thumbnails--1 .gallery__thumbnail--1 {
  margin: 0 0 0 0;
}
.gallery__thumbnails--1 .gallery__thumbnail--1 .gallery__frame {
  margin: 0 0 0 0;
}
.gallery__thumbnails--2 .gallery__thumbnail--1 {
  right: 50%;
  margin: 0 10px 0 0;
}
.gallery__thumbnails--2 .gallery__thumbnail--1 .gallery__frame {
  margin: 0 -10px 0 0;
}
.gallery__thumbnails--2 .gallery__thumbnail--2 {
  left: 50%;
  margin: 0 0 0 10px;
}
.gallery__thumbnails--2 .gallery__thumbnail--2 .gallery__frame {
  margin: 0 0 0 -10px;
}
.gallery__thumbnails--3 .gallery__thumbnail--1 {
  right: 50%;
  margin: 0 10px 0 0;
}
.gallery__thumbnails--3 .gallery__thumbnail--1 .gallery__frame {
  margin: 0 -10px 0 0;
}
.gallery__thumbnails--3 .gallery__thumbnail--2 {
  left: 50%;
  bottom: 50%;
  margin: 0 0 10px 10px;
}
.gallery__thumbnails--3 .gallery__thumbnail--2 .gallery__frame {
  margin: 0 0 -10px -10px;
}
.gallery__thumbnails--3 .gallery__thumbnail--3 {
  top: 50%;
  left: 50%;
  margin: 10px 0 0 10px;
}
.gallery__thumbnails--3 .gallery__thumbnail--3 .gallery__frame {
  margin: -10px 0 0 -10px;
}
.gallery__thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: yellow;
  overflow: hidden;
  border-radius: 10px;
}
.gallery__thumbnail--1 {
  grid-area: thumbnail1;
}
.gallery__thumbnail--2 {
  grid-area: thumbnail2;
}
.gallery__thumbnail--3 {
  grid-area: thumbnail3;
}
.gallery__frame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: gray;
}
.gallery__image {
  display: block;
  width: 100%;
  cursor: pointer;
}
