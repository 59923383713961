.gift-card-banner {
  padding: 30px;
  border-radius: 20px;
  background-color: #faaa1e;
}
@media (min-width: 1024px) {
  .gift-card-banner {
    padding: 55px;
  }
}
.gift-card-banner__col-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.gift-card-banner__image {
  display: block;
  width: 100%;
}
.gift-card-banner__title {
  font-family: "Founders Grotesk X Condensed", "Arial Narrow Bold", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 84%;
  color: #fff;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .gift-card-banner__title {
    font-size: 72px;
  }
}
.gift-card-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
}
@media (min-width: 1024px) {
  .gift-card-banner__description {
    font-size: 18px;
  }
}
