.tabs {
  width: 100%;
}
.tabs__nav {
  display: flex;
  width: 100%;
}
.tabs__rail {
  overflow: hidden;
  margin: 0 0 40px;
  box-shadow: 0px -1px 0px #d8d8d8 inset;
}
.tabs__content {
  padding-bottom: 50px;
}
