.customer-booking-card {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
}
.customer-booking-card__type-tag {
  display: inline-block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1;
  color: #28283c;
  text-transform: uppercase;
  padding: 5px 5px;
  background-color: #fadcdc;
  border-radius: 2px;
  align-self: flex-start;
  text-align: center;
  width: auto;
  margin-left: 0.25rem;
}
.customer-booking-card__type-tag:first-child {
  margin-left: 0;
}
.customer-booking-card__type-tag--color-sun {
  background-color: #faaa1e;
}
.customer-booking-card__type-tag--color-camelot {
  background-color: #822d50;
  color: #fff;
}
.customer-booking-card__class {
  flex: 1 1;
}
.customer-booking-card__class-title {
  margin: 0 0 10px;
  font-size: 21px;
  color: #28283c;
}
.customer-booking-card__actions {
  margin-right: -9px;
}
.customer-booking-card__datetime-row {
  margin-bottom: 4px;
  display: block;
}
.customer-booking-card__datetime-row:hover .customer-booking-card__datetime {
  text-decoration: underline;
}
.customer-booking-card__datetime {
  display: inline-block;
  color: #6b6a77;
  margin-right: 15px;
}
.customer-booking-card__icon {
  vertical-align: middle;
  margin-top: -4px;
}
.customer-booking-card__icon--action {
  fill: #28283c;
  margin-right: 4px;
}
.customer-booking-card__icon--general {
  margin-right: 4px;
}
.customer-booking-card__icon--general-fill {
  fill: #c5c5c5;
}
.customer-booking-card__icon--general-stroke {
  stroke: #c5c5c5;
}
.customer-booking-card__row {
  color: #28283c;
  padding: 20px;
  font-size: 18px;
  border-bottom: solid 1px #e6e6e6;
}
.customer-booking-card__row:last-child {
  border-bottom: none;
}
.customer-booking-card__row--flex {
  display: flex;
  justify-content: space-between;
}
.customer-booking-card__row--wrap {
  flex-wrap: wrap;
}
.customer-booking-card__row--center {
  text-align: center;
}
.customer-booking-card__cancelled {
  color: #888;
}
.customer-booking-card__input-label {
  font-weight: 500;
  font-size: 16px;
}
.customer-booking-card__breakdown {
  text-align: center;
  padding: 15px;
  font-size: 15px;
  border-bottom: solid 1px #e6e6e6;
}
.customer-booking-card__customer-actions {
  flex-basis: 100%;
  font-size: 16px;
  margin-top: 2px;
  display: flex;
}
.customer-booking-card__customer-action {
  font-size: 16px;
  margin-top: 2px;
  display: flex;
  cursor: pointer;
}
.customer-booking-card__customer-action--restricted {
  color: #bdbdbd;
}
.customer-booking-card__customer-action--restricted .customer-booking-card__customer-action-icon {
  margin-right: 2px;
}
.customer-booking-card__customer-action--restricted .customer-booking-card__customer-action-icon path {
  fill: #bdbdbd;
}
.customer-booking-card__customer-action-icon {
  fill: #28283c;
  margin-top: 1px;
}
.customer-booking-card__customer-remove {
  flex-basis: 100%;
  font-size: 16px;
  margin-top: 2px;
  display: flex;
  cursor: pointer;
}
.customer-booking-card__customer-remove-icon {
  fill: #28283c;
  margin-top: 1px;
}
.customer-booking-card__customer-name {
  flex: 1 1;
}
