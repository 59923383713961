.category-sticker {
  width: 100%;
  text-align: center;
}
.category-sticker__title {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
}
.category-sticker__title--ebonyClay {
  color: #28283c;
}
.category-sticker__title--camelot {
  color: #822d50;
}
.category-sticker__title--gallery {
  color: #f0f0f0;
}
