.menu-nav-user-links {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-nav-user-links__link {
  display: flex;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
  margin: 10px 0;
  text-decoration: none;
  cursor: pointer;
}
.menu-nav-user-links__link:hover,
.menu-nav-user-links__link:visited,
.menu-nav-user-links__link:active,
.menu-nav-user-links__link:focus {
  color: #28283c;
  text-decoration: none;
}
.menu-nav-user-links__link:hover {
  color: #00d8c4;
}
