.draggable-list-item {
  background-color: white;
}
.draggable-list-item--dragging:first-child + .draggable-list-group-item {
  border-top-width: 1px;
}
.draggable-list-item--dragging:first-child:not(.draggable-list-group-item--dragged-over-below) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.draggable-list-item--dragging:first-child:not(.draggable-list-group-item--dragged-over-below) + .draggable-list-group-item {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.draggable-list-item + .draggable-list-group-item--dragging {
  border-top-width: 1px;
}
.draggable-list-item + .draggable-list-group-item--dragged-over-above {
  border-top-width: 1px;
}
.draggable-list-item--dragged-over-below + .draggable-list-group-item {
  border-top-width: 1px;
}
