.banner-content {
  display: flex;
  flex-direction: column;
}
.banner-content__title {
  font-size: 36px;
  line-height: 90%;
  font-weight: 600;
  padding-bottom: 17px;
  text-transform: uppercase;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
}
@media (min-width: 834px) {
  .banner-content__title {
    font-size: 42px;
  }
}
.banner-content__divider {
  width: 40px;
  margin-bottom: 13px;
  border: solid 3px;
}
.banner-content__divider--camelot {
  border-color: #822d50;
}
.banner-content__divider--emerald {
  border-color: #32c864;
}
.banner-content__divider--sun {
  border-color: #faaa1e;
}
.banner-content__divider--robbinsEggBlue {
  border-color: #00d2c8;
}
.banner-content__divider--ebonyClay {
  border-color: #28283c;
}
.banner-content__divider--azalea {
  border-color: #fadcdc;
}
.banner-content__divider--cruise {
  border-color: #bff0db;
}
.banner-content__divider--astra {
  border-color: #fafabe;
}
.banner-content__divider--gallery {
  border-color: #f0f0f0;
}
.banner-content__divider--humming-bird {
  border-color: #d7f0fa;
}
.banner-content__divider--humming-bird-faded {
  border-color: #f0fafe;
}
.banner-content__divider--silver-chalice {
  border-color: #a6a6a6;
}
.banner-content__divider--catskill-white {
  border-color: #e1e9f0;
}
.banner-content__divider--bali-hai {
  border-color: #8298ab;
}
.banner-content__divider--french-lilac {
  border-color: #efe0f6;
}
.banner-content__divider--deep-blush {
  border-color: #e07688;
}
.banner-content__divider--pink {
  border-color: #fbdad8;
}
.banner-content__divider--gothic {
  border-color: #6991a1;
}
.banner-content__divider--polar-blue {
  border-color: #f0f9fc;
}
.banner-content__divider--prussian {
  border-color: #304659;
}
.banner-content__divider--sky {
  border-color: #e1e9f0;
}
.banner-content__divider--tulip-tree {
  border-color: #f0ac44;
}
.banner-content__divider--emeraldish {
  border-color: #03c2b9;
}
.banner-content__divider--jumbo {
  border-color: #7b7b86;
}
.banner-content__divider--pampas {
  border-color: #f8f7f5;
}
.banner-content__divider--dark-gallery {
  border-color: #eaeaea;
}
.banner-content__divider--concrete {
  border-color: #f2f2f2;
}
.banner-content__divider--sand {
  border-color: #fbfbfb;
}
.banner-content__divider--black-squeeze {
  border-color: #f9fcfd;
}
.banner-content__divider--athens-gray {
  border-color: #f4f4f4;
}
.banner-content__divider--alto {
  border-color: #d8d8d8;
}
.banner-content__divider--gray {
  border-color: #888;
}
.banner-content__divider--venus {
  border-color: #7e7e8a;
}
.banner-content__divider--dolphin {
  border-color: #6b6a77;
}
.banner-content__divider--mercury {
  border-color: #e6e6e6;
}
.banner-content__divider--text-black {
  border-color: #28283c;
}
.banner-content__divider--polar {
  border-color: #eafbfa;
}
.banner-content__divider--tundora {
  border-color: #484444;
}
.banner-content__divider--jet {
  border-color: #3e3e50;
}
.banner-content__divider--silver {
  border-color: #bdbdbd;
}
.banner-content__divider--smoke {
  border-color: #bfbfbf;
}
.banner-content__divider--white {
  border-color: #fff;
}
.banner-content__divider--malachite {
  border-color: #00c040;
}
.banner-content__divider--light-green {
  border-color: #c0f0dd;
}
.banner-content__divider--coral {
  border-color: #ff774d;
}
.banner-content__divider--bright-sun {
  border-color: #ffcc43;
}
.banner-content__divider--shark {
  border-color: #272a2d;
}
.banner-content__divider--royal-blue {
  border-color: #4945e2;
}
.banner-content__divider--aquamarine-blue {
  border-color: #7ce5df;
}
.banner-content__divider--scorpion {
  border-color: #595959;
}
.banner-content__divider--heather {
  border-color: #b8c5d0;
}
.banner-content__divider--red {
  border-color: #de2828;
}
.banner-content__divider--lighter-teal {
  border-color: #d6f6f3;
}
.banner-content__divider--blue-lagoon {
  border-color: #007986;
}
.banner-content__divider--brightTeal {
  border-color: #00d8c4;
}
.banner-content__description {
  line-height: 144%;
  letter-spacing: 0.01em;
  font-size: 18px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.banner-content__button {
  padding-top: 20px;
  max-width: 150px;
}
