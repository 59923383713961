.date-picker-input {
  position: relative;
}
.date-picker-input--position-top .DateInput_fang {
  margin-bottom: 1px;
}
.date-picker-input--position-bottom .DateInput_fang {
  margin-top: 1px;
}
.date-picker-input--no-icon .DateInput_input {
  padding-left: 20px !important;
}
.date-picker-input--accent-brightTeal .DateInput_input:focus {
  border-color: #00d8c4;
}
.date-picker-input__icon {
  position: absolute;
  top: 12px;
  left: 12px;
}
.date-picker-input .SingleDatePicker {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.date-picker-input .DateInput {
  background: transparent;
  width: 100%;
}
.date-picker-input .SingleDatePickerInput {
  background: transparent;
  width: 100%;
  padding-right: 0;
}
.date-picker-input .DateInput_input {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin: 0;
  padding: 10px 20px 10px 40px;
  outline: none;
  -moz-appearance: textfield;
  transition: all 250ms;
}
.date-picker-input .DateInput_input--full-width {
  width: 100%;
}
.date-picker-input .DateInput_input::placeholder {
  color: #d8d8d8;
}
.date-picker-input .DateInput_fang {
  z-index: 1002;
}
.date-picker-input .DateInput_fangShape {
  fill: white;
}
.date-picker-input .CalendarDay__selected,
.date-picker-input .CalendarDay__selected:active,
.date-picker-input .CalendarDay__selected:hover {
  background: #00d8c4;
  border-color: #00d8c4;
}
