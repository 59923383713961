.image-with-label {
  display: inline-block;
  position: relative;
}
.image-with-label__image-wrapper {
  overflow: hidden;
}
.image-with-label__image {
  display: block;
}
.image-with-label__text {
  display: inline-flex;
  align-items: center;
  height: 21px;
  text-transform: uppercase;
  position: absolute;
  bottom: 2px;
  right: -20px;
  font-size: 11px;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  padding: 0 10px;
  transform: matrix(0.95, -0.33, 0.33, 0.95, 0, 0);
  letter-spacing: 0.01em;
}
.image-with-label__text--emeraldish {
  color: #03c2b9;
}
.image-with-label__text--bg-humming-bird {
  background-color: #d7f0fa;
}
