.prompt-card {
  width: 100%;
  padding: 30px 25px;
  background-color: white;
  border-top: 8px solid;
  border-radius: 4px;
}
.prompt-card--top-border-color-camelot {
  border-color: #822d50;
}
.prompt-card--top-border-color-emerald {
  border-color: #32c864;
}
.prompt-card--top-border-color-sun {
  border-color: #faaa1e;
}
.prompt-card--top-border-color-robbinsEggBlue {
  border-color: #00d2c8;
}
.prompt-card--top-border-color-ebonyClay {
  border-color: #28283c;
}
.prompt-card--top-border-color-azalea {
  border-color: #fadcdc;
}
.prompt-card--top-border-color-cruise {
  border-color: #bff0db;
}
.prompt-card--top-border-color-astra {
  border-color: #fafabe;
}
.prompt-card--top-border-color-gallery {
  border-color: #f0f0f0;
}
.prompt-card--top-border-color-humming-bird {
  border-color: #d7f0fa;
}
.prompt-card--top-border-color-humming-bird-faded {
  border-color: #f0fafe;
}
.prompt-card--top-border-color-silver-chalice {
  border-color: #a6a6a6;
}
.prompt-card--top-border-color-catskill-white {
  border-color: #e1e9f0;
}
.prompt-card--top-border-color-bali-hai {
  border-color: #8298ab;
}
.prompt-card--top-border-color-french-lilac {
  border-color: #efe0f6;
}
.prompt-card--top-border-color-deep-blush {
  border-color: #e07688;
}
.prompt-card--top-border-color-pink {
  border-color: #fbdad8;
}
.prompt-card--top-border-color-gothic {
  border-color: #6991a1;
}
.prompt-card--top-border-color-polar-blue {
  border-color: #f0f9fc;
}
.prompt-card--top-border-color-prussian {
  border-color: #304659;
}
.prompt-card--top-border-color-sky {
  border-color: #e1e9f0;
}
.prompt-card--top-border-color-tulip-tree {
  border-color: #f0ac44;
}
.prompt-card--top-border-color-emeraldish {
  border-color: #03c2b9;
}
.prompt-card--top-border-color-jumbo {
  border-color: #7b7b86;
}
.prompt-card--top-border-color-pampas {
  border-color: #f8f7f5;
}
.prompt-card--top-border-color-dark-gallery {
  border-color: #eaeaea;
}
.prompt-card--top-border-color-concrete {
  border-color: #f2f2f2;
}
.prompt-card--top-border-color-sand {
  border-color: #fbfbfb;
}
.prompt-card--top-border-color-black-squeeze {
  border-color: #f9fcfd;
}
.prompt-card--top-border-color-athens-gray {
  border-color: #f4f4f4;
}
.prompt-card--top-border-color-alto {
  border-color: #d8d8d8;
}
.prompt-card--top-border-color-gray {
  border-color: #888;
}
.prompt-card--top-border-color-venus {
  border-color: #7e7e8a;
}
.prompt-card--top-border-color-dolphin {
  border-color: #6b6a77;
}
.prompt-card--top-border-color-mercury {
  border-color: #e6e6e6;
}
.prompt-card--top-border-color-text-black {
  border-color: #28283c;
}
.prompt-card--top-border-color-polar {
  border-color: #eafbfa;
}
.prompt-card--top-border-color-tundora {
  border-color: #484444;
}
.prompt-card--top-border-color-jet {
  border-color: #3e3e50;
}
.prompt-card--top-border-color-silver {
  border-color: #bdbdbd;
}
.prompt-card--top-border-color-smoke {
  border-color: #bfbfbf;
}
.prompt-card--top-border-color-white {
  border-color: #fff;
}
.prompt-card--top-border-color-malachite {
  border-color: #00c040;
}
.prompt-card--top-border-color-light-green {
  border-color: #c0f0dd;
}
.prompt-card--top-border-color-coral {
  border-color: #ff774d;
}
.prompt-card--top-border-color-bright-sun {
  border-color: #ffcc43;
}
.prompt-card--top-border-color-shark {
  border-color: #272a2d;
}
.prompt-card--top-border-color-royal-blue {
  border-color: #4945e2;
}
.prompt-card--top-border-color-aquamarine-blue {
  border-color: #7ce5df;
}
.prompt-card--top-border-color-scorpion {
  border-color: #595959;
}
.prompt-card--top-border-color-heather {
  border-color: #b8c5d0;
}
.prompt-card--top-border-color-red {
  border-color: #de2828;
}
.prompt-card--top-border-color-lighter-teal {
  border-color: #d6f6f3;
}
.prompt-card--top-border-color-blue-lagoon {
  border-color: #007986;
}
.prompt-card--top-border-color-brightTeal {
  border-color: #00d8c4;
}
.prompt-card__title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 13px;
}
.prompt-card__title-text {
  display: inline-flex;
  line-height: 100%;
  font-weight: bold;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  text-transform: uppercase;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 5px;
  padding-right: 5px;
}
.prompt-card__title-text--size-medium {
  font-size: 24px;
}
.prompt-card__title-text--size-large {
  font-size: 28px;
}
.prompt-card__title-icon {
  display: inline-flex;
  padding-bottom: 5px;
}
.prompt-card__title-icon--camelot polygon {
  fill: #822d50;
}
.prompt-card__title-icon--emerald polygon {
  fill: #32c864;
}
.prompt-card__title-icon--sun polygon {
  fill: #faaa1e;
}
.prompt-card__title-icon--robbinsEggBlue polygon {
  fill: #00d2c8;
}
.prompt-card__title-icon--ebonyClay polygon {
  fill: #28283c;
}
.prompt-card__title-icon--azalea polygon {
  fill: #fadcdc;
}
.prompt-card__title-icon--cruise polygon {
  fill: #bff0db;
}
.prompt-card__title-icon--astra polygon {
  fill: #fafabe;
}
.prompt-card__title-icon--gallery polygon {
  fill: #f0f0f0;
}
.prompt-card__title-icon--humming-bird polygon {
  fill: #d7f0fa;
}
.prompt-card__title-icon--humming-bird-faded polygon {
  fill: #f0fafe;
}
.prompt-card__title-icon--silver-chalice polygon {
  fill: #a6a6a6;
}
.prompt-card__title-icon--catskill-white polygon {
  fill: #e1e9f0;
}
.prompt-card__title-icon--bali-hai polygon {
  fill: #8298ab;
}
.prompt-card__title-icon--french-lilac polygon {
  fill: #efe0f6;
}
.prompt-card__title-icon--deep-blush polygon {
  fill: #e07688;
}
.prompt-card__title-icon--pink polygon {
  fill: #fbdad8;
}
.prompt-card__title-icon--gothic polygon {
  fill: #6991a1;
}
.prompt-card__title-icon--polar-blue polygon {
  fill: #f0f9fc;
}
.prompt-card__title-icon--prussian polygon {
  fill: #304659;
}
.prompt-card__title-icon--sky polygon {
  fill: #e1e9f0;
}
.prompt-card__title-icon--tulip-tree polygon {
  fill: #f0ac44;
}
.prompt-card__title-icon--emeraldish polygon {
  fill: #03c2b9;
}
.prompt-card__title-icon--jumbo polygon {
  fill: #7b7b86;
}
.prompt-card__title-icon--pampas polygon {
  fill: #f8f7f5;
}
.prompt-card__title-icon--dark-gallery polygon {
  fill: #eaeaea;
}
.prompt-card__title-icon--concrete polygon {
  fill: #f2f2f2;
}
.prompt-card__title-icon--sand polygon {
  fill: #fbfbfb;
}
.prompt-card__title-icon--black-squeeze polygon {
  fill: #f9fcfd;
}
.prompt-card__title-icon--athens-gray polygon {
  fill: #f4f4f4;
}
.prompt-card__title-icon--alto polygon {
  fill: #d8d8d8;
}
.prompt-card__title-icon--gray polygon {
  fill: #888;
}
.prompt-card__title-icon--venus polygon {
  fill: #7e7e8a;
}
.prompt-card__title-icon--dolphin polygon {
  fill: #6b6a77;
}
.prompt-card__title-icon--mercury polygon {
  fill: #e6e6e6;
}
.prompt-card__title-icon--text-black polygon {
  fill: #28283c;
}
.prompt-card__title-icon--polar polygon {
  fill: #eafbfa;
}
.prompt-card__title-icon--tundora polygon {
  fill: #484444;
}
.prompt-card__title-icon--jet polygon {
  fill: #3e3e50;
}
.prompt-card__title-icon--silver polygon {
  fill: #bdbdbd;
}
.prompt-card__title-icon--smoke polygon {
  fill: #bfbfbf;
}
.prompt-card__title-icon--white polygon {
  fill: #fff;
}
.prompt-card__title-icon--malachite polygon {
  fill: #00c040;
}
.prompt-card__title-icon--light-green polygon {
  fill: #c0f0dd;
}
.prompt-card__title-icon--coral polygon {
  fill: #ff774d;
}
.prompt-card__title-icon--bright-sun polygon {
  fill: #ffcc43;
}
.prompt-card__title-icon--shark polygon {
  fill: #272a2d;
}
.prompt-card__title-icon--royal-blue polygon {
  fill: #4945e2;
}
.prompt-card__title-icon--aquamarine-blue polygon {
  fill: #7ce5df;
}
.prompt-card__title-icon--scorpion polygon {
  fill: #595959;
}
.prompt-card__title-icon--heather polygon {
  fill: #b8c5d0;
}
.prompt-card__title-icon--red polygon {
  fill: #de2828;
}
.prompt-card__title-icon--lighter-teal polygon {
  fill: #d6f6f3;
}
.prompt-card__title-icon--blue-lagoon polygon {
  fill: #007986;
}
.prompt-card__title-icon--brightTeal polygon {
  fill: #00d8c4;
}
.prompt-card__text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.3px;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 24px;
}
