.course-level-filter {
  display: inline-block;
}
.course-level-filter__level {
  cursor: pointer;
}
.course-level-filter__level--selected .course-level-filter__label,
.course-level-filter__level--selected .course-level-filter__count {
  color: #03c2b9;
}
.course-level-filter__level:hover .course-level-filter__label,
.course-level-filter__level:hover .course-level-filter__count {
  text-decoration: underline;
}
.course-level-filter__label,
.course-level-filter__count {
  display: inline-block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.course-level-filter__label {
  color: #28283c;
}
.course-level-filter__count {
  color: #94949e;
}
