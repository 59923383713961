.product-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.product-card__favourite-button {
  position: absolute;
  top: 16px;
  right: 16px;
}
.product-card__thumbnail-image {
  width: 100%;
  display: block;
}
.product-card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #28283c;
  padding: 15px;
}
.product-card__title {
  display: block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  text-decoration: none;
  letter-spacing: 0.5px;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
}
.product-card__title:hover,
.product-card__title:visited,
.product-card__title:active,
.product-card__title:focus {
  color: #28283c;
  text-decoration: none;
}
.product-card__description {
  padding-top: 15px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #888;
  letter-spacing: 0.5px;
}
.product-card__footer {
  display: flex;
  align-items: center;
  padding: 0 15px 15px;
}
.product-card__price {
  letter-spacing: 0.02em;
  flex-basis: 64px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  color: #888;
}
.product-card__price--with-discount {
  text-decoration: line-through;
}
.product-card__stock {
  font-weight: bold;
  flex-grow: 1;
  text-align: right;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  color: #888;
  letter-spacing: 0.5px;
}
