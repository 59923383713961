.attention-banner {
  display: flex;
  align-items: flex-start;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 14px;
  flex-direction: column;
}
@media (min-width: 577px) {
  .attention-banner {
    padding: 26px;
    flex-direction: row;
  }
}
.attention-banner__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  display: block;
}
@media (min-width: 577px) {
  .attention-banner__title {
    font-size: 19px;
  }
}
.attention-banner--camelot .attention-banner__title {
  color: #822d50;
}
.attention-banner--emerald .attention-banner__title {
  color: #32c864;
}
.attention-banner--sun .attention-banner__title {
  color: #faaa1e;
}
.attention-banner--robbinsEggBlue .attention-banner__title {
  color: #00d2c8;
}
.attention-banner--ebonyClay .attention-banner__title {
  color: #28283c;
}
.attention-banner--azalea .attention-banner__title {
  color: #fadcdc;
}
.attention-banner--cruise .attention-banner__title {
  color: #bff0db;
}
.attention-banner--astra .attention-banner__title {
  color: #fafabe;
}
.attention-banner--gallery .attention-banner__title {
  color: #f0f0f0;
}
.attention-banner--humming-bird .attention-banner__title {
  color: #d7f0fa;
}
.attention-banner--humming-bird-faded .attention-banner__title {
  color: #f0fafe;
}
.attention-banner--silver-chalice .attention-banner__title {
  color: #a6a6a6;
}
.attention-banner--catskill-white .attention-banner__title {
  color: #e1e9f0;
}
.attention-banner--bali-hai .attention-banner__title {
  color: #8298ab;
}
.attention-banner--french-lilac .attention-banner__title {
  color: #efe0f6;
}
.attention-banner--deep-blush .attention-banner__title {
  color: #e07688;
}
.attention-banner--pink .attention-banner__title {
  color: #fbdad8;
}
.attention-banner--gothic .attention-banner__title {
  color: #6991a1;
}
.attention-banner--polar-blue .attention-banner__title {
  color: #f0f9fc;
}
.attention-banner--prussian .attention-banner__title {
  color: #304659;
}
.attention-banner--sky .attention-banner__title {
  color: #e1e9f0;
}
.attention-banner--tulip-tree .attention-banner__title {
  color: #f0ac44;
}
.attention-banner--emeraldish .attention-banner__title {
  color: #03c2b9;
}
.attention-banner--jumbo .attention-banner__title {
  color: #7b7b86;
}
.attention-banner--pampas .attention-banner__title {
  color: #f8f7f5;
}
.attention-banner--dark-gallery .attention-banner__title {
  color: #eaeaea;
}
.attention-banner--concrete .attention-banner__title {
  color: #f2f2f2;
}
.attention-banner--sand .attention-banner__title {
  color: #fbfbfb;
}
.attention-banner--black-squeeze .attention-banner__title {
  color: #f9fcfd;
}
.attention-banner--athens-gray .attention-banner__title {
  color: #f4f4f4;
}
.attention-banner--alto .attention-banner__title {
  color: #d8d8d8;
}
.attention-banner--gray .attention-banner__title {
  color: #888;
}
.attention-banner--venus .attention-banner__title {
  color: #7e7e8a;
}
.attention-banner--dolphin .attention-banner__title {
  color: #6b6a77;
}
.attention-banner--mercury .attention-banner__title {
  color: #e6e6e6;
}
.attention-banner--text-black .attention-banner__title {
  color: #28283c;
}
.attention-banner--polar .attention-banner__title {
  color: #eafbfa;
}
.attention-banner--tundora .attention-banner__title {
  color: #484444;
}
.attention-banner--jet .attention-banner__title {
  color: #3e3e50;
}
.attention-banner--silver .attention-banner__title {
  color: #bdbdbd;
}
.attention-banner--smoke .attention-banner__title {
  color: #bfbfbf;
}
.attention-banner--white .attention-banner__title {
  color: #fff;
}
.attention-banner--malachite .attention-banner__title {
  color: #00c040;
}
.attention-banner--light-green .attention-banner__title {
  color: #c0f0dd;
}
.attention-banner--coral .attention-banner__title {
  color: #ff774d;
}
.attention-banner--bright-sun .attention-banner__title {
  color: #ffcc43;
}
.attention-banner--shark .attention-banner__title {
  color: #272a2d;
}
.attention-banner--royal-blue .attention-banner__title {
  color: #4945e2;
}
.attention-banner--aquamarine-blue .attention-banner__title {
  color: #7ce5df;
}
.attention-banner--scorpion .attention-banner__title {
  color: #595959;
}
.attention-banner--heather .attention-banner__title {
  color: #b8c5d0;
}
.attention-banner--red .attention-banner__title {
  color: #de2828;
}
.attention-banner--lighter-teal .attention-banner__title {
  color: #d6f6f3;
}
.attention-banner--blue-lagoon .attention-banner__title {
  color: #007986;
}
.attention-banner--brightTeal .attention-banner__title {
  color: #00d8c4;
}
.attention-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 13px 0 0 0;
  display: block;
}
@media (min-width: 577px) {
  .attention-banner__description {
    font-size: 18px;
    margin-top: auto;
    margin: 0 0 0 16px;
  }
}
