.sales-banner {
  display: flex;
  justify-content: center;
  font-weight: bold;
  background-color: #28283c;
  height: 54px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.sales-banner--size-medium {
  height: 54px;
}
.sales-banner--size-medium .sales-banner__text {
  font-size: 32px;
  padding: 8px 30px;
}
.sales-banner--size-small {
  height: 38px;
}
.sales-banner--size-small .sales-banner__text {
  font-size: 22px;
  padding: 7px 30px 9px;
}
.sales-banner__text {
  display: inline-block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 32px;
  color: #d7f0fa;
  padding: 8px 30px;
  white-space: nowrap;
  line-height: 1;
}
