/**
  all flex complexity here is to keep some title, details and footer aligned with the siblings in the same row in a grid list
 */
.mixed-card {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mixed-card__image {
  display: block;
  width: 100%;
  flex: 0 0;
}
.mixed-card__details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.mixed-card__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  color: #28283c;
  flex-grow: 1;
}
@media (max-width: 576px) {
  .mixed-card__title {
    font-size: 16px;
  }
}
.mixed-card__data-list {
  flex: 0 0 96px;
  display: flex;
  flex-direction: column;
}
.mixed-card__data {
  display: flex;
  align-items: center;
}
.mixed-card__icon-wrapper {
  flex: 0 0 34px;
  padding-right: 8px;
}
.mixed-card__icon {
  display: block;
  margin: 0 auto;
}
.mixed-card__icon path {
  fill: #28283c;
}
.mixed-card__data-text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #94949e;
  line-height: 16px;
}
.mixed-card__data-text--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mixed-card__type {
  position: relative;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 14px;
  color: #94949e;
  font-weight: 500;
  line-height: 1;
  padding: 3px 6px 4px;
  margin: 0 -6px;
}
.mixed-card__type:before {
  border-radius: 2px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
}
.mixed-card__type--type-product:before {
  background-color: #32c864;
}
.mixed-card__type--type-class:before {
  background-color: #ff774d;
}
.mixed-card__type--type-class--online:before {
  background-color: #e6e480;
}
.mixed-card__type-label {
  position: relative;
}
.mixed-card__footer {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .mixed-card__footer .rating-summary {
    font-size: 14px;
  }
}
.mixed-card__price {
  color: #28283c;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.mixed-card__price--discount {
  color: #888;
  font-weight: normal;
  text-decoration: line-through;
}
@media (max-width: 576px) {
  .mixed-card__price {
    font-size: 16px;
  }
}
