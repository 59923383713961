.menu-nav-dropdown {
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  overflow: hidden;
  transition: height, opacity;
  transition-timing-function: ease;
  transition-duration: 250ms;
  opacity: 0;
  box-sizing: content-box;
}
@media (min-width: 1024px) {
  .menu-nav-dropdown {
    display: block;
  }
}
.menu-nav-dropdown--visible {
  opacity: 1;
  padding-bottom: 30px;
}
.menu-nav-dropdown__wrapper {
  box-shadow: 0 1.5591px 27.2303px rgba(40, 40, 60, 0.12);
  background-color: white;
  border-top: solid 1px #f8f7f5;
}
