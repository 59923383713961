.image-and-sticker-cta {
  background-color: #28283c;
}
.image-and-sticker-cta__container {
  display: flex;
  margin-top: 100px;
  max-width: 1150px;
  margin: 0 auto !important;
}
.image-and-sticker-cta__image {
  display: none;
}
@media (min-width: 576px) {
  .image-and-sticker-cta__image {
    display: inline-block;
    width: 50%;
  }
}
.image-and-sticker-cta__text-wrapper {
  position: relative;
  background-color: #28283c;
  color: #f0f0f0;
}
@media (min-width: 576px) {
  .image-and-sticker-cta__text-wrapper {
    width: 50%;
  }
}
.image-and-sticker-cta__text {
  margin: 60px 40px 160px 60px;
}
@media (max-width: 767px) {
  .image-and-sticker-cta__text {
    margin: 40px;
  }
}
.image-and-sticker-cta__title {
  max-width: 350px;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 35px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
}
.image-and-sticker-cta__tagline {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  margin: 20px 0 30px 0;
}
.image-and-sticker-cta__button {
  display: inline-block;
  min-width: 150px;
  cursor: pointer;
}
.image-and-sticker-cta__sticker {
  position: absolute;
  width: 200px;
  transform: rotateZ(-20deg);
  z-index: 5;
  left: -120px;
  top: 140px;
}
@media (max-width: 767px) {
  .image-and-sticker-cta__sticker {
    display: none;
  }
}
