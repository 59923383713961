.menu-nav-mobile-bar {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .menu-nav-mobile-bar {
    display: none;
  }
}
.menu-nav-mobile-bar__tools {
  display: flex;
  align-items: center;
}
@media (min-width: 1024px) {
  .menu-nav-mobile-bar__tools {
    display: none;
  }
}
.menu-nav-mobile-bar__hamburger {
  height: 26px;
  cursor: pointer;
}
.menu-nav-mobile-bar__hamburger--color-camelot {
  fill: #822d50;
}
.menu-nav-mobile-bar__hamburger--color-emerald {
  fill: #32c864;
}
.menu-nav-mobile-bar__hamburger--color-sun {
  fill: #faaa1e;
}
.menu-nav-mobile-bar__hamburger--color-robbinsEggBlue {
  fill: #00d2c8;
}
.menu-nav-mobile-bar__hamburger--color-ebonyClay {
  fill: #28283c;
}
.menu-nav-mobile-bar__hamburger--color-azalea {
  fill: #fadcdc;
}
.menu-nav-mobile-bar__hamburger--color-cruise {
  fill: #bff0db;
}
.menu-nav-mobile-bar__hamburger--color-astra {
  fill: #fafabe;
}
.menu-nav-mobile-bar__hamburger--color-gallery {
  fill: #f0f0f0;
}
.menu-nav-mobile-bar__hamburger--color-humming-bird {
  fill: #d7f0fa;
}
.menu-nav-mobile-bar__hamburger--color-humming-bird-faded {
  fill: #f0fafe;
}
.menu-nav-mobile-bar__hamburger--color-silver-chalice {
  fill: #a6a6a6;
}
.menu-nav-mobile-bar__hamburger--color-catskill-white {
  fill: #e1e9f0;
}
.menu-nav-mobile-bar__hamburger--color-bali-hai {
  fill: #8298ab;
}
.menu-nav-mobile-bar__hamburger--color-french-lilac {
  fill: #efe0f6;
}
.menu-nav-mobile-bar__hamburger--color-deep-blush {
  fill: #e07688;
}
.menu-nav-mobile-bar__hamburger--color-pink {
  fill: #fbdad8;
}
.menu-nav-mobile-bar__hamburger--color-gothic {
  fill: #6991a1;
}
.menu-nav-mobile-bar__hamburger--color-polar-blue {
  fill: #f0f9fc;
}
.menu-nav-mobile-bar__hamburger--color-prussian {
  fill: #304659;
}
.menu-nav-mobile-bar__hamburger--color-sky {
  fill: #e1e9f0;
}
.menu-nav-mobile-bar__hamburger--color-tulip-tree {
  fill: #f0ac44;
}
.menu-nav-mobile-bar__hamburger--color-emeraldish {
  fill: #03c2b9;
}
.menu-nav-mobile-bar__hamburger--color-jumbo {
  fill: #7b7b86;
}
.menu-nav-mobile-bar__hamburger--color-pampas {
  fill: #f8f7f5;
}
.menu-nav-mobile-bar__hamburger--color-dark-gallery {
  fill: #eaeaea;
}
.menu-nav-mobile-bar__hamburger--color-concrete {
  fill: #f2f2f2;
}
.menu-nav-mobile-bar__hamburger--color-sand {
  fill: #fbfbfb;
}
.menu-nav-mobile-bar__hamburger--color-black-squeeze {
  fill: #f9fcfd;
}
.menu-nav-mobile-bar__hamburger--color-athens-gray {
  fill: #f4f4f4;
}
.menu-nav-mobile-bar__hamburger--color-alto {
  fill: #d8d8d8;
}
.menu-nav-mobile-bar__hamburger--color-gray {
  fill: #888;
}
.menu-nav-mobile-bar__hamburger--color-venus {
  fill: #7e7e8a;
}
.menu-nav-mobile-bar__hamburger--color-dolphin {
  fill: #6b6a77;
}
.menu-nav-mobile-bar__hamburger--color-mercury {
  fill: #e6e6e6;
}
.menu-nav-mobile-bar__hamburger--color-text-black {
  fill: #28283c;
}
.menu-nav-mobile-bar__hamburger--color-polar {
  fill: #eafbfa;
}
.menu-nav-mobile-bar__hamburger--color-tundora {
  fill: #484444;
}
.menu-nav-mobile-bar__hamburger--color-jet {
  fill: #3e3e50;
}
.menu-nav-mobile-bar__hamburger--color-silver {
  fill: #bdbdbd;
}
.menu-nav-mobile-bar__hamburger--color-smoke {
  fill: #bfbfbf;
}
.menu-nav-mobile-bar__hamburger--color-white {
  fill: #fff;
}
.menu-nav-mobile-bar__hamburger--color-malachite {
  fill: #00c040;
}
.menu-nav-mobile-bar__hamburger--color-light-green {
  fill: #c0f0dd;
}
.menu-nav-mobile-bar__hamburger--color-coral {
  fill: #ff774d;
}
.menu-nav-mobile-bar__hamburger--color-bright-sun {
  fill: #ffcc43;
}
.menu-nav-mobile-bar__hamburger--color-shark {
  fill: #272a2d;
}
.menu-nav-mobile-bar__hamburger--color-royal-blue {
  fill: #4945e2;
}
.menu-nav-mobile-bar__hamburger--color-aquamarine-blue {
  fill: #7ce5df;
}
.menu-nav-mobile-bar__hamburger--color-scorpion {
  fill: #595959;
}
.menu-nav-mobile-bar__hamburger--color-heather {
  fill: #b8c5d0;
}
.menu-nav-mobile-bar__hamburger--color-red {
  fill: #de2828;
}
.menu-nav-mobile-bar__hamburger--color-lighter-teal {
  fill: #d6f6f3;
}
.menu-nav-mobile-bar__hamburger--color-blue-lagoon {
  fill: #007986;
}
.menu-nav-mobile-bar__hamburger--color-brightTeal {
  fill: #00d8c4;
}
.menu-nav-mobile-bar__hamburger--hover-color-camelot:hover {
  fill: #822d50;
}
.menu-nav-mobile-bar__hamburger--hover-color-emerald:hover {
  fill: #32c864;
}
.menu-nav-mobile-bar__hamburger--hover-color-sun:hover {
  fill: #faaa1e;
}
.menu-nav-mobile-bar__hamburger--hover-color-robbinsEggBlue:hover {
  fill: #00d2c8;
}
.menu-nav-mobile-bar__hamburger--hover-color-ebonyClay:hover {
  fill: #28283c;
}
.menu-nav-mobile-bar__hamburger--hover-color-azalea:hover {
  fill: #fadcdc;
}
.menu-nav-mobile-bar__hamburger--hover-color-cruise:hover {
  fill: #bff0db;
}
.menu-nav-mobile-bar__hamburger--hover-color-astra:hover {
  fill: #fafabe;
}
.menu-nav-mobile-bar__hamburger--hover-color-gallery:hover {
  fill: #f0f0f0;
}
.menu-nav-mobile-bar__hamburger--hover-color-humming-bird:hover {
  fill: #d7f0fa;
}
.menu-nav-mobile-bar__hamburger--hover-color-humming-bird-faded:hover {
  fill: #f0fafe;
}
.menu-nav-mobile-bar__hamburger--hover-color-silver-chalice:hover {
  fill: #a6a6a6;
}
.menu-nav-mobile-bar__hamburger--hover-color-catskill-white:hover {
  fill: #e1e9f0;
}
.menu-nav-mobile-bar__hamburger--hover-color-bali-hai:hover {
  fill: #8298ab;
}
.menu-nav-mobile-bar__hamburger--hover-color-french-lilac:hover {
  fill: #efe0f6;
}
.menu-nav-mobile-bar__hamburger--hover-color-deep-blush:hover {
  fill: #e07688;
}
.menu-nav-mobile-bar__hamburger--hover-color-pink:hover {
  fill: #fbdad8;
}
.menu-nav-mobile-bar__hamburger--hover-color-gothic:hover {
  fill: #6991a1;
}
.menu-nav-mobile-bar__hamburger--hover-color-polar-blue:hover {
  fill: #f0f9fc;
}
.menu-nav-mobile-bar__hamburger--hover-color-prussian:hover {
  fill: #304659;
}
.menu-nav-mobile-bar__hamburger--hover-color-sky:hover {
  fill: #e1e9f0;
}
.menu-nav-mobile-bar__hamburger--hover-color-tulip-tree:hover {
  fill: #f0ac44;
}
.menu-nav-mobile-bar__hamburger--hover-color-emeraldish:hover {
  fill: #03c2b9;
}
.menu-nav-mobile-bar__hamburger--hover-color-jumbo:hover {
  fill: #7b7b86;
}
.menu-nav-mobile-bar__hamburger--hover-color-pampas:hover {
  fill: #f8f7f5;
}
.menu-nav-mobile-bar__hamburger--hover-color-dark-gallery:hover {
  fill: #eaeaea;
}
.menu-nav-mobile-bar__hamburger--hover-color-concrete:hover {
  fill: #f2f2f2;
}
.menu-nav-mobile-bar__hamburger--hover-color-sand:hover {
  fill: #fbfbfb;
}
.menu-nav-mobile-bar__hamburger--hover-color-black-squeeze:hover {
  fill: #f9fcfd;
}
.menu-nav-mobile-bar__hamburger--hover-color-athens-gray:hover {
  fill: #f4f4f4;
}
.menu-nav-mobile-bar__hamburger--hover-color-alto:hover {
  fill: #d8d8d8;
}
.menu-nav-mobile-bar__hamburger--hover-color-gray:hover {
  fill: #888;
}
.menu-nav-mobile-bar__hamburger--hover-color-venus:hover {
  fill: #7e7e8a;
}
.menu-nav-mobile-bar__hamburger--hover-color-dolphin:hover {
  fill: #6b6a77;
}
.menu-nav-mobile-bar__hamburger--hover-color-mercury:hover {
  fill: #e6e6e6;
}
.menu-nav-mobile-bar__hamburger--hover-color-text-black:hover {
  fill: #28283c;
}
.menu-nav-mobile-bar__hamburger--hover-color-polar:hover {
  fill: #eafbfa;
}
.menu-nav-mobile-bar__hamburger--hover-color-tundora:hover {
  fill: #484444;
}
.menu-nav-mobile-bar__hamburger--hover-color-jet:hover {
  fill: #3e3e50;
}
.menu-nav-mobile-bar__hamburger--hover-color-silver:hover {
  fill: #bdbdbd;
}
.menu-nav-mobile-bar__hamburger--hover-color-smoke:hover {
  fill: #bfbfbf;
}
.menu-nav-mobile-bar__hamburger--hover-color-white:hover {
  fill: #fff;
}
.menu-nav-mobile-bar__hamburger--hover-color-malachite:hover {
  fill: #00c040;
}
.menu-nav-mobile-bar__hamburger--hover-color-light-green:hover {
  fill: #c0f0dd;
}
.menu-nav-mobile-bar__hamburger--hover-color-coral:hover {
  fill: #ff774d;
}
.menu-nav-mobile-bar__hamburger--hover-color-bright-sun:hover {
  fill: #ffcc43;
}
.menu-nav-mobile-bar__hamburger--hover-color-shark:hover {
  fill: #272a2d;
}
.menu-nav-mobile-bar__hamburger--hover-color-royal-blue:hover {
  fill: #4945e2;
}
.menu-nav-mobile-bar__hamburger--hover-color-aquamarine-blue:hover {
  fill: #7ce5df;
}
.menu-nav-mobile-bar__hamburger--hover-color-scorpion:hover {
  fill: #595959;
}
.menu-nav-mobile-bar__hamburger--hover-color-heather:hover {
  fill: #b8c5d0;
}
.menu-nav-mobile-bar__hamburger--hover-color-red:hover {
  fill: #de2828;
}
.menu-nav-mobile-bar__hamburger--hover-color-lighter-teal:hover {
  fill: #d6f6f3;
}
.menu-nav-mobile-bar__hamburger--hover-color-blue-lagoon:hover {
  fill: #007986;
}
.menu-nav-mobile-bar__hamburger--hover-color-brightTeal:hover {
  fill: #00d8c4;
}
