.pill-button {
  margin: 0;
  width: 100%;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  border-radius: 30px;
  white-space: nowrap;
  background-color: #fff;
  text-transform: capitalize;
  border: 1px solid #dadada;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
.pill-button--width-auto {
  width: auto;
}
.pill-button--width-max {
  width: 100%;
}
.pill-button--selected {
  color: #fff;
  background-color: #03c2b9;
  border: 1px solid #03c2b9;
}
.pill-button--large {
  font-size: 18px;
  padding: 14px 20px;
}
.pill-button--medium {
  font-size: 18px;
  padding: 10px 18px;
}
.pill-button--small {
  font-size: 16px;
  padding: 8px 16px;
}
.pill-button--tiny {
  font-size: 12px;
  padding: 0px 10px;
  height: 28px;
}
.pill-button--disabled {
  color: #888 !important;
  border-color: #888 !important;
  cursor: default;
}
.pill-button--text-uppercase {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
.pill-button__icon {
  margin-left: 10px;
}
.pill-button__icon > svg {
  fill: #00d2c8;
}
.pill-button__icon--selected > svg {
  fill: #fff;
}
