.quick-start-card {
  position: relative;
  padding-bottom: 100%;
  background-color: #fff;
  border: solid 1px #e6e6e6;
  border-radius: 20px;
}
.quick-start-card--active {
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.quick-start-card--active:hover {
  border-color: #888;
  background-color: #fbfbfb;
}
.quick-start-card__wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.quick-start-card__icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  text-align: center;
}
.quick-start-card__icon {
  max-width: 60%;
  max-height: 80%;
  color: #00d8c4;
}
.quick-start-card__icon--disabled {
  color: #e6e6e6;
}
.quick-start-card__label {
  flex: 0 0 56px;
  border-top: solid 1px #e6e6e6;
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
}
.quick-start-card__label--disabled {
  color: #888;
}
.quick-start-card__check-icon {
  position: absolute;
  width: 34%;
  left: 33%;
  top: 33%;
}
