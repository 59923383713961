.rising-popup {
  position: absolute;
  height: 100%;
  animation: openPopup 500ms;
}
@media (min-width: 700px) {
  .rising-popup {
    width: 700px;
  }
}
.rising-popup--closing {
  animation-name: closePopup;
}
.rising-popup__wrapper {
  display: flex;
  background-color: white;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
}
.rising-popup__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 62px;
}
.rising-popup__header {
  display: flex;
}
.rising-popup__title {
  border-bottom: 1px solid #f4f4f4;
  color: #28283c;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  padding: 27px 0;
}
.rising-popup__content {
  flex: 1;
  padding-top: 20px;
}
.rising-popup__close {
  flex-basis: 62px;
  padding: 0 22px 0;
}
.rising-popup__close-icon {
  position: sticky;
  top: 30px;
  cursor: pointer;
}
@keyframes openPopup {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}
@keyframes closePopup {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
