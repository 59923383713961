@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.label-input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
}
.label-input:first-child {
  margin-top: 0;
}
.label-input__label {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  width: 100%;
  margin-bottom: 15px;
}
.label-input__input-wrap {
  width: 100%;
}
.label-input__icon-input {
  width: 100%;
  display: flex;
  position: relative;
}
.label-input__input {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: transparent;
  margin: 0;
  padding: 10px 20px;
  outline: none;
  -moz-appearance: textfield;
  transition: all 250ms;
}
.label-input__input::placeholder {
  color: #d8d8d8;
}
.label-input__input::-webkit-outer-spin-button,
.label-input__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.label-input__input--icon {
  padding-left: 35px;
}
.label-input__locked-text {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.25;
  font-weight: 400;
  color: #28283c;
}
.label-input__input-icon {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 15px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  color: #28283c;
  font-weight: 400;
  margin: 0;
  animation: fadeIn 250ms;
}
.label-input__textarea {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #28283c;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: transparent;
  margin: 0;
  padding: 10px 20px;
  min-height: 150px;
  outline: none;
}
.label-input__textarea::placeholder {
  color: #d8d8d8;
}
.label-input__character-count {
  padding-top: 15px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1;
  color: #d8d8d8;
}
.label-input__note {
  padding-top: 15px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.25;
  color: #d8d8d8;
}
.label-input__error {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  padding-top: 15px;
  line-height: 1.25;
  color: #e07688;
}
