.menu-nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 1024px) {
  .menu-nav-item {
    justify-content: flex-start;
  }
}
.menu-nav-item--color-camelot {
  color: #822d50;
}
.menu-nav-item--color-emerald {
  color: #32c864;
}
.menu-nav-item--color-sun {
  color: #faaa1e;
}
.menu-nav-item--color-robbinsEggBlue {
  color: #00d2c8;
}
.menu-nav-item--color-ebonyClay {
  color: #28283c;
}
.menu-nav-item--color-azalea {
  color: #fadcdc;
}
.menu-nav-item--color-cruise {
  color: #bff0db;
}
.menu-nav-item--color-astra {
  color: #fafabe;
}
.menu-nav-item--color-gallery {
  color: #f0f0f0;
}
.menu-nav-item--color-humming-bird {
  color: #d7f0fa;
}
.menu-nav-item--color-humming-bird-faded {
  color: #f0fafe;
}
.menu-nav-item--color-silver-chalice {
  color: #a6a6a6;
}
.menu-nav-item--color-catskill-white {
  color: #e1e9f0;
}
.menu-nav-item--color-bali-hai {
  color: #8298ab;
}
.menu-nav-item--color-french-lilac {
  color: #efe0f6;
}
.menu-nav-item--color-deep-blush {
  color: #e07688;
}
.menu-nav-item--color-pink {
  color: #fbdad8;
}
.menu-nav-item--color-gothic {
  color: #6991a1;
}
.menu-nav-item--color-polar-blue {
  color: #f0f9fc;
}
.menu-nav-item--color-prussian {
  color: #304659;
}
.menu-nav-item--color-sky {
  color: #e1e9f0;
}
.menu-nav-item--color-tulip-tree {
  color: #f0ac44;
}
.menu-nav-item--color-emeraldish {
  color: #03c2b9;
}
.menu-nav-item--color-jumbo {
  color: #7b7b86;
}
.menu-nav-item--color-pampas {
  color: #f8f7f5;
}
.menu-nav-item--color-dark-gallery {
  color: #eaeaea;
}
.menu-nav-item--color-concrete {
  color: #f2f2f2;
}
.menu-nav-item--color-sand {
  color: #fbfbfb;
}
.menu-nav-item--color-black-squeeze {
  color: #f9fcfd;
}
.menu-nav-item--color-athens-gray {
  color: #f4f4f4;
}
.menu-nav-item--color-alto {
  color: #d8d8d8;
}
.menu-nav-item--color-gray {
  color: #888;
}
.menu-nav-item--color-venus {
  color: #7e7e8a;
}
.menu-nav-item--color-dolphin {
  color: #6b6a77;
}
.menu-nav-item--color-mercury {
  color: #e6e6e6;
}
.menu-nav-item--color-text-black {
  color: #28283c;
}
.menu-nav-item--color-polar {
  color: #eafbfa;
}
.menu-nav-item--color-tundora {
  color: #484444;
}
.menu-nav-item--color-jet {
  color: #3e3e50;
}
.menu-nav-item--color-silver {
  color: #bdbdbd;
}
.menu-nav-item--color-smoke {
  color: #bfbfbf;
}
.menu-nav-item--color-white {
  color: #fff;
}
.menu-nav-item--color-malachite {
  color: #00c040;
}
.menu-nav-item--color-light-green {
  color: #c0f0dd;
}
.menu-nav-item--color-coral {
  color: #ff774d;
}
.menu-nav-item--color-bright-sun {
  color: #ffcc43;
}
.menu-nav-item--color-shark {
  color: #272a2d;
}
.menu-nav-item--color-royal-blue {
  color: #4945e2;
}
.menu-nav-item--color-aquamarine-blue {
  color: #7ce5df;
}
.menu-nav-item--color-scorpion {
  color: #595959;
}
.menu-nav-item--color-heather {
  color: #b8c5d0;
}
.menu-nav-item--color-red {
  color: #de2828;
}
.menu-nav-item--color-lighter-teal {
  color: #d6f6f3;
}
.menu-nav-item--color-blue-lagoon {
  color: #007986;
}
.menu-nav-item--color-brightTeal {
  color: #00d8c4;
}
.menu-nav-item--hover-color-camelot:hover {
  color: #822d50;
}
.menu-nav-item--hover-color-emerald:hover {
  color: #32c864;
}
.menu-nav-item--hover-color-sun:hover {
  color: #faaa1e;
}
.menu-nav-item--hover-color-robbinsEggBlue:hover {
  color: #00d2c8;
}
.menu-nav-item--hover-color-ebonyClay:hover {
  color: #28283c;
}
.menu-nav-item--hover-color-azalea:hover {
  color: #fadcdc;
}
.menu-nav-item--hover-color-cruise:hover {
  color: #bff0db;
}
.menu-nav-item--hover-color-astra:hover {
  color: #fafabe;
}
.menu-nav-item--hover-color-gallery:hover {
  color: #f0f0f0;
}
.menu-nav-item--hover-color-humming-bird:hover {
  color: #d7f0fa;
}
.menu-nav-item--hover-color-humming-bird-faded:hover {
  color: #f0fafe;
}
.menu-nav-item--hover-color-silver-chalice:hover {
  color: #a6a6a6;
}
.menu-nav-item--hover-color-catskill-white:hover {
  color: #e1e9f0;
}
.menu-nav-item--hover-color-bali-hai:hover {
  color: #8298ab;
}
.menu-nav-item--hover-color-french-lilac:hover {
  color: #efe0f6;
}
.menu-nav-item--hover-color-deep-blush:hover {
  color: #e07688;
}
.menu-nav-item--hover-color-pink:hover {
  color: #fbdad8;
}
.menu-nav-item--hover-color-gothic:hover {
  color: #6991a1;
}
.menu-nav-item--hover-color-polar-blue:hover {
  color: #f0f9fc;
}
.menu-nav-item--hover-color-prussian:hover {
  color: #304659;
}
.menu-nav-item--hover-color-sky:hover {
  color: #e1e9f0;
}
.menu-nav-item--hover-color-tulip-tree:hover {
  color: #f0ac44;
}
.menu-nav-item--hover-color-emeraldish:hover {
  color: #03c2b9;
}
.menu-nav-item--hover-color-jumbo:hover {
  color: #7b7b86;
}
.menu-nav-item--hover-color-pampas:hover {
  color: #f8f7f5;
}
.menu-nav-item--hover-color-dark-gallery:hover {
  color: #eaeaea;
}
.menu-nav-item--hover-color-concrete:hover {
  color: #f2f2f2;
}
.menu-nav-item--hover-color-sand:hover {
  color: #fbfbfb;
}
.menu-nav-item--hover-color-black-squeeze:hover {
  color: #f9fcfd;
}
.menu-nav-item--hover-color-athens-gray:hover {
  color: #f4f4f4;
}
.menu-nav-item--hover-color-alto:hover {
  color: #d8d8d8;
}
.menu-nav-item--hover-color-gray:hover {
  color: #888;
}
.menu-nav-item--hover-color-venus:hover {
  color: #7e7e8a;
}
.menu-nav-item--hover-color-dolphin:hover {
  color: #6b6a77;
}
.menu-nav-item--hover-color-mercury:hover {
  color: #e6e6e6;
}
.menu-nav-item--hover-color-text-black:hover {
  color: #28283c;
}
.menu-nav-item--hover-color-polar:hover {
  color: #eafbfa;
}
.menu-nav-item--hover-color-tundora:hover {
  color: #484444;
}
.menu-nav-item--hover-color-jet:hover {
  color: #3e3e50;
}
.menu-nav-item--hover-color-silver:hover {
  color: #bdbdbd;
}
.menu-nav-item--hover-color-smoke:hover {
  color: #bfbfbf;
}
.menu-nav-item--hover-color-white:hover {
  color: #fff;
}
.menu-nav-item--hover-color-malachite:hover {
  color: #00c040;
}
.menu-nav-item--hover-color-light-green:hover {
  color: #c0f0dd;
}
.menu-nav-item--hover-color-coral:hover {
  color: #ff774d;
}
.menu-nav-item--hover-color-bright-sun:hover {
  color: #ffcc43;
}
.menu-nav-item--hover-color-shark:hover {
  color: #272a2d;
}
.menu-nav-item--hover-color-royal-blue:hover {
  color: #4945e2;
}
.menu-nav-item--hover-color-aquamarine-blue:hover {
  color: #7ce5df;
}
.menu-nav-item--hover-color-scorpion:hover {
  color: #595959;
}
.menu-nav-item--hover-color-heather:hover {
  color: #b8c5d0;
}
.menu-nav-item--hover-color-red:hover {
  color: #de2828;
}
.menu-nav-item--hover-color-lighter-teal:hover {
  color: #d6f6f3;
}
.menu-nav-item--hover-color-blue-lagoon:hover {
  color: #007986;
}
.menu-nav-item--hover-color-brightTeal:hover {
  color: #00d8c4;
}
.menu-nav-item--active {
  justify-content: flex-start;
}
.menu-nav-item--active .menu-nav-item__icon {
  transform: rotate(90deg);
  order: 0;
  margin-right: 10px;
}
@media (min-width: 1024px) {
  .menu-nav-item--active .menu-nav-item__icon {
    order: 1;
    transform: rotate(180deg);
    margin-right: 0;
  }
}
.menu-nav-item__text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 21px;
  letter-spacing: 0.1px;
  order: 1;
  -webkit-font-smoothing: antialiased;
  text-align: center;
}
@media (min-width: 1024px) {
  .menu-nav-item__text {
    font-size: 15px;
  }
}
@media (min-width: 1440px) {
  .menu-nav-item__text {
    font-size: 16px;
  }
}
.menu-nav-item__text--bold {
  font-weight: 500;
}
.menu-nav-item__icon {
  margin-top: 5px;
  height: 6px;
  transform: rotate(-90deg);
  order: 1;
}
@media (min-width: 1024px) {
  .menu-nav-item__icon {
    margin-left: 5px;
    transform: rotate(360deg);
    transition: transform 250ms ease;
  }
}
.menu-nav-item__icon path {
  fill: currentColor;
}
