.text-input-button {
  display: flex;
}
.text-input-button:focus {
  border: solid 10px black;
  opacity: 0.3;
}
.text-input-button--accent-camelot.text-input-button--is-focused .text-input-button__button {
  border-color: #822d50;
  background-color: #822d50;
}
.text-input-button--accent-emerald.text-input-button--is-focused .text-input-button__button {
  border-color: #32c864;
  background-color: #32c864;
}
.text-input-button--accent-sun.text-input-button--is-focused .text-input-button__button {
  border-color: #faaa1e;
  background-color: #faaa1e;
}
.text-input-button--accent-robbinsEggBlue.text-input-button--is-focused .text-input-button__button {
  border-color: #00d2c8;
  background-color: #00d2c8;
}
.text-input-button--accent-ebonyClay.text-input-button--is-focused .text-input-button__button {
  border-color: #28283c;
  background-color: #28283c;
}
.text-input-button--accent-azalea.text-input-button--is-focused .text-input-button__button {
  border-color: #fadcdc;
  background-color: #fadcdc;
}
.text-input-button--accent-cruise.text-input-button--is-focused .text-input-button__button {
  border-color: #bff0db;
  background-color: #bff0db;
}
.text-input-button--accent-astra.text-input-button--is-focused .text-input-button__button {
  border-color: #fafabe;
  background-color: #fafabe;
}
.text-input-button--accent-gallery.text-input-button--is-focused .text-input-button__button {
  border-color: #f0f0f0;
  background-color: #f0f0f0;
}
.text-input-button--accent-humming-bird.text-input-button--is-focused .text-input-button__button {
  border-color: #d7f0fa;
  background-color: #d7f0fa;
}
.text-input-button--accent-humming-bird-faded.text-input-button--is-focused .text-input-button__button {
  border-color: #f0fafe;
  background-color: #f0fafe;
}
.text-input-button--accent-silver-chalice.text-input-button--is-focused .text-input-button__button {
  border-color: #a6a6a6;
  background-color: #a6a6a6;
}
.text-input-button--accent-catskill-white.text-input-button--is-focused .text-input-button__button {
  border-color: #e1e9f0;
  background-color: #e1e9f0;
}
.text-input-button--accent-bali-hai.text-input-button--is-focused .text-input-button__button {
  border-color: #8298ab;
  background-color: #8298ab;
}
.text-input-button--accent-french-lilac.text-input-button--is-focused .text-input-button__button {
  border-color: #efe0f6;
  background-color: #efe0f6;
}
.text-input-button--accent-deep-blush.text-input-button--is-focused .text-input-button__button {
  border-color: #e07688;
  background-color: #e07688;
}
.text-input-button--accent-pink.text-input-button--is-focused .text-input-button__button {
  border-color: #fbdad8;
  background-color: #fbdad8;
}
.text-input-button--accent-gothic.text-input-button--is-focused .text-input-button__button {
  border-color: #6991a1;
  background-color: #6991a1;
}
.text-input-button--accent-polar-blue.text-input-button--is-focused .text-input-button__button {
  border-color: #f0f9fc;
  background-color: #f0f9fc;
}
.text-input-button--accent-prussian.text-input-button--is-focused .text-input-button__button {
  border-color: #304659;
  background-color: #304659;
}
.text-input-button--accent-sky.text-input-button--is-focused .text-input-button__button {
  border-color: #e1e9f0;
  background-color: #e1e9f0;
}
.text-input-button--accent-tulip-tree.text-input-button--is-focused .text-input-button__button {
  border-color: #f0ac44;
  background-color: #f0ac44;
}
.text-input-button--accent-emeraldish.text-input-button--is-focused .text-input-button__button {
  border-color: #03c2b9;
  background-color: #03c2b9;
}
.text-input-button--accent-jumbo.text-input-button--is-focused .text-input-button__button {
  border-color: #7b7b86;
  background-color: #7b7b86;
}
.text-input-button--accent-pampas.text-input-button--is-focused .text-input-button__button {
  border-color: #f8f7f5;
  background-color: #f8f7f5;
}
.text-input-button--accent-dark-gallery.text-input-button--is-focused .text-input-button__button {
  border-color: #eaeaea;
  background-color: #eaeaea;
}
.text-input-button--accent-concrete.text-input-button--is-focused .text-input-button__button {
  border-color: #f2f2f2;
  background-color: #f2f2f2;
}
.text-input-button--accent-sand.text-input-button--is-focused .text-input-button__button {
  border-color: #fbfbfb;
  background-color: #fbfbfb;
}
.text-input-button--accent-black-squeeze.text-input-button--is-focused .text-input-button__button {
  border-color: #f9fcfd;
  background-color: #f9fcfd;
}
.text-input-button--accent-athens-gray.text-input-button--is-focused .text-input-button__button {
  border-color: #f4f4f4;
  background-color: #f4f4f4;
}
.text-input-button--accent-alto.text-input-button--is-focused .text-input-button__button {
  border-color: #d8d8d8;
  background-color: #d8d8d8;
}
.text-input-button--accent-gray.text-input-button--is-focused .text-input-button__button {
  border-color: #888;
  background-color: #888;
}
.text-input-button--accent-venus.text-input-button--is-focused .text-input-button__button {
  border-color: #7e7e8a;
  background-color: #7e7e8a;
}
.text-input-button--accent-dolphin.text-input-button--is-focused .text-input-button__button {
  border-color: #6b6a77;
  background-color: #6b6a77;
}
.text-input-button--accent-mercury.text-input-button--is-focused .text-input-button__button {
  border-color: #e6e6e6;
  background-color: #e6e6e6;
}
.text-input-button--accent-text-black.text-input-button--is-focused .text-input-button__button {
  border-color: #28283c;
  background-color: #28283c;
}
.text-input-button--accent-polar.text-input-button--is-focused .text-input-button__button {
  border-color: #eafbfa;
  background-color: #eafbfa;
}
.text-input-button--accent-tundora.text-input-button--is-focused .text-input-button__button {
  border-color: #484444;
  background-color: #484444;
}
.text-input-button--accent-jet.text-input-button--is-focused .text-input-button__button {
  border-color: #3e3e50;
  background-color: #3e3e50;
}
.text-input-button--accent-silver.text-input-button--is-focused .text-input-button__button {
  border-color: #bdbdbd;
  background-color: #bdbdbd;
}
.text-input-button--accent-smoke.text-input-button--is-focused .text-input-button__button {
  border-color: #bfbfbf;
  background-color: #bfbfbf;
}
.text-input-button--accent-white.text-input-button--is-focused .text-input-button__button {
  border-color: #fff;
  background-color: #fff;
}
.text-input-button--accent-malachite.text-input-button--is-focused .text-input-button__button {
  border-color: #00c040;
  background-color: #00c040;
}
.text-input-button--accent-light-green.text-input-button--is-focused .text-input-button__button {
  border-color: #c0f0dd;
  background-color: #c0f0dd;
}
.text-input-button--accent-coral.text-input-button--is-focused .text-input-button__button {
  border-color: #ff774d;
  background-color: #ff774d;
}
.text-input-button--accent-bright-sun.text-input-button--is-focused .text-input-button__button {
  border-color: #ffcc43;
  background-color: #ffcc43;
}
.text-input-button--accent-shark.text-input-button--is-focused .text-input-button__button {
  border-color: #272a2d;
  background-color: #272a2d;
}
.text-input-button--accent-royal-blue.text-input-button--is-focused .text-input-button__button {
  border-color: #4945e2;
  background-color: #4945e2;
}
.text-input-button--accent-aquamarine-blue.text-input-button--is-focused .text-input-button__button {
  border-color: #7ce5df;
  background-color: #7ce5df;
}
.text-input-button--accent-scorpion.text-input-button--is-focused .text-input-button__button {
  border-color: #595959;
  background-color: #595959;
}
.text-input-button--accent-heather.text-input-button--is-focused .text-input-button__button {
  border-color: #b8c5d0;
  background-color: #b8c5d0;
}
.text-input-button--accent-red.text-input-button--is-focused .text-input-button__button {
  border-color: #de2828;
  background-color: #de2828;
}
.text-input-button--accent-lighter-teal.text-input-button--is-focused .text-input-button__button {
  border-color: #d6f6f3;
  background-color: #d6f6f3;
}
.text-input-button--accent-blue-lagoon.text-input-button--is-focused .text-input-button__button {
  border-color: #007986;
  background-color: #007986;
}
.text-input-button--accent-brightTeal.text-input-button--is-focused .text-input-button__button {
  border-color: #00d8c4;
  background-color: #00d8c4;
}
.text-input-button__text-input-wrapper {
  flex: 1 1 auto;
}
.text-input-button__button {
  transition: all 250ms;
  flex-grow: 0;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-left-width: 0;
  cursor: pointer;
  font-size: 14px;
}
.text-input-button__button--color-camelot {
  color: #822d50;
}
.text-input-button__button--color-emerald {
  color: #32c864;
}
.text-input-button__button--color-sun {
  color: #faaa1e;
}
.text-input-button__button--color-robbinsEggBlue {
  color: #00d2c8;
}
.text-input-button__button--color-ebonyClay {
  color: #28283c;
}
.text-input-button__button--color-azalea {
  color: #fadcdc;
}
.text-input-button__button--color-cruise {
  color: #bff0db;
}
.text-input-button__button--color-astra {
  color: #fafabe;
}
.text-input-button__button--color-gallery {
  color: #f0f0f0;
}
.text-input-button__button--color-humming-bird {
  color: #d7f0fa;
}
.text-input-button__button--color-humming-bird-faded {
  color: #f0fafe;
}
.text-input-button__button--color-silver-chalice {
  color: #a6a6a6;
}
.text-input-button__button--color-catskill-white {
  color: #e1e9f0;
}
.text-input-button__button--color-bali-hai {
  color: #8298ab;
}
.text-input-button__button--color-french-lilac {
  color: #efe0f6;
}
.text-input-button__button--color-deep-blush {
  color: #e07688;
}
.text-input-button__button--color-pink {
  color: #fbdad8;
}
.text-input-button__button--color-gothic {
  color: #6991a1;
}
.text-input-button__button--color-polar-blue {
  color: #f0f9fc;
}
.text-input-button__button--color-prussian {
  color: #304659;
}
.text-input-button__button--color-sky {
  color: #e1e9f0;
}
.text-input-button__button--color-tulip-tree {
  color: #f0ac44;
}
.text-input-button__button--color-emeraldish {
  color: #03c2b9;
}
.text-input-button__button--color-jumbo {
  color: #7b7b86;
}
.text-input-button__button--color-pampas {
  color: #f8f7f5;
}
.text-input-button__button--color-dark-gallery {
  color: #eaeaea;
}
.text-input-button__button--color-concrete {
  color: #f2f2f2;
}
.text-input-button__button--color-sand {
  color: #fbfbfb;
}
.text-input-button__button--color-black-squeeze {
  color: #f9fcfd;
}
.text-input-button__button--color-athens-gray {
  color: #f4f4f4;
}
.text-input-button__button--color-alto {
  color: #d8d8d8;
}
.text-input-button__button--color-gray {
  color: #888;
}
.text-input-button__button--color-venus {
  color: #7e7e8a;
}
.text-input-button__button--color-dolphin {
  color: #6b6a77;
}
.text-input-button__button--color-mercury {
  color: #e6e6e6;
}
.text-input-button__button--color-text-black {
  color: #28283c;
}
.text-input-button__button--color-polar {
  color: #eafbfa;
}
.text-input-button__button--color-tundora {
  color: #484444;
}
.text-input-button__button--color-jet {
  color: #3e3e50;
}
.text-input-button__button--color-silver {
  color: #bdbdbd;
}
.text-input-button__button--color-smoke {
  color: #bfbfbf;
}
.text-input-button__button--color-white {
  color: #fff;
}
.text-input-button__button--color-malachite {
  color: #00c040;
}
.text-input-button__button--color-light-green {
  color: #c0f0dd;
}
.text-input-button__button--color-coral {
  color: #ff774d;
}
.text-input-button__button--color-bright-sun {
  color: #ffcc43;
}
.text-input-button__button--color-shark {
  color: #272a2d;
}
.text-input-button__button--color-royal-blue {
  color: #4945e2;
}
.text-input-button__button--color-aquamarine-blue {
  color: #7ce5df;
}
.text-input-button__button--color-scorpion {
  color: #595959;
}
.text-input-button__button--color-heather {
  color: #b8c5d0;
}
.text-input-button__button--color-red {
  color: #de2828;
}
.text-input-button__button--color-lighter-teal {
  color: #d6f6f3;
}
.text-input-button__button--color-blue-lagoon {
  color: #007986;
}
.text-input-button__button--color-brightTeal {
  color: #00d8c4;
}
.text-input-button__button--background-color-camelot {
  background-color: #822d50;
  border-color: #822d50;
}
.text-input-button__button--background-color-emerald {
  background-color: #32c864;
  border-color: #32c864;
}
.text-input-button__button--background-color-sun {
  background-color: #faaa1e;
  border-color: #faaa1e;
}
.text-input-button__button--background-color-robbinsEggBlue {
  background-color: #00d2c8;
  border-color: #00d2c8;
}
.text-input-button__button--background-color-ebonyClay {
  background-color: #28283c;
  border-color: #28283c;
}
.text-input-button__button--background-color-azalea {
  background-color: #fadcdc;
  border-color: #fadcdc;
}
.text-input-button__button--background-color-cruise {
  background-color: #bff0db;
  border-color: #bff0db;
}
.text-input-button__button--background-color-astra {
  background-color: #fafabe;
  border-color: #fafabe;
}
.text-input-button__button--background-color-gallery {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.text-input-button__button--background-color-humming-bird {
  background-color: #d7f0fa;
  border-color: #d7f0fa;
}
.text-input-button__button--background-color-humming-bird-faded {
  background-color: #f0fafe;
  border-color: #f0fafe;
}
.text-input-button__button--background-color-silver-chalice {
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}
.text-input-button__button--background-color-catskill-white {
  background-color: #e1e9f0;
  border-color: #e1e9f0;
}
.text-input-button__button--background-color-bali-hai {
  background-color: #8298ab;
  border-color: #8298ab;
}
.text-input-button__button--background-color-french-lilac {
  background-color: #efe0f6;
  border-color: #efe0f6;
}
.text-input-button__button--background-color-deep-blush {
  background-color: #e07688;
  border-color: #e07688;
}
.text-input-button__button--background-color-pink {
  background-color: #fbdad8;
  border-color: #fbdad8;
}
.text-input-button__button--background-color-gothic {
  background-color: #6991a1;
  border-color: #6991a1;
}
.text-input-button__button--background-color-polar-blue {
  background-color: #f0f9fc;
  border-color: #f0f9fc;
}
.text-input-button__button--background-color-prussian {
  background-color: #304659;
  border-color: #304659;
}
.text-input-button__button--background-color-sky {
  background-color: #e1e9f0;
  border-color: #e1e9f0;
}
.text-input-button__button--background-color-tulip-tree {
  background-color: #f0ac44;
  border-color: #f0ac44;
}
.text-input-button__button--background-color-emeraldish {
  background-color: #03c2b9;
  border-color: #03c2b9;
}
.text-input-button__button--background-color-jumbo {
  background-color: #7b7b86;
  border-color: #7b7b86;
}
.text-input-button__button--background-color-pampas {
  background-color: #f8f7f5;
  border-color: #f8f7f5;
}
.text-input-button__button--background-color-dark-gallery {
  background-color: #eaeaea;
  border-color: #eaeaea;
}
.text-input-button__button--background-color-concrete {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.text-input-button__button--background-color-sand {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
}
.text-input-button__button--background-color-black-squeeze {
  background-color: #f9fcfd;
  border-color: #f9fcfd;
}
.text-input-button__button--background-color-athens-gray {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.text-input-button__button--background-color-alto {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.text-input-button__button--background-color-gray {
  background-color: #888;
  border-color: #888;
}
.text-input-button__button--background-color-venus {
  background-color: #7e7e8a;
  border-color: #7e7e8a;
}
.text-input-button__button--background-color-dolphin {
  background-color: #6b6a77;
  border-color: #6b6a77;
}
.text-input-button__button--background-color-mercury {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}
.text-input-button__button--background-color-text-black {
  background-color: #28283c;
  border-color: #28283c;
}
.text-input-button__button--background-color-polar {
  background-color: #eafbfa;
  border-color: #eafbfa;
}
.text-input-button__button--background-color-tundora {
  background-color: #484444;
  border-color: #484444;
}
.text-input-button__button--background-color-jet {
  background-color: #3e3e50;
  border-color: #3e3e50;
}
.text-input-button__button--background-color-silver {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
.text-input-button__button--background-color-smoke {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}
.text-input-button__button--background-color-white {
  background-color: #fff;
  border-color: #fff;
}
.text-input-button__button--background-color-malachite {
  background-color: #00c040;
  border-color: #00c040;
}
.text-input-button__button--background-color-light-green {
  background-color: #c0f0dd;
  border-color: #c0f0dd;
}
.text-input-button__button--background-color-coral {
  background-color: #ff774d;
  border-color: #ff774d;
}
.text-input-button__button--background-color-bright-sun {
  background-color: #ffcc43;
  border-color: #ffcc43;
}
.text-input-button__button--background-color-shark {
  background-color: #272a2d;
  border-color: #272a2d;
}
.text-input-button__button--background-color-royal-blue {
  background-color: #4945e2;
  border-color: #4945e2;
}
.text-input-button__button--background-color-aquamarine-blue {
  background-color: #7ce5df;
  border-color: #7ce5df;
}
.text-input-button__button--background-color-scorpion {
  background-color: #595959;
  border-color: #595959;
}
.text-input-button__button--background-color-heather {
  background-color: #b8c5d0;
  border-color: #b8c5d0;
}
.text-input-button__button--background-color-red {
  background-color: #de2828;
  border-color: #de2828;
}
.text-input-button__button--background-color-lighter-teal {
  background-color: #d6f6f3;
  border-color: #d6f6f3;
}
.text-input-button__button--background-color-blue-lagoon {
  background-color: #007986;
  border-color: #007986;
}
.text-input-button__button--background-color-brightTeal {
  background-color: #00d8c4;
  border-color: #00d8c4;
}
.text-input-button__text-input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
