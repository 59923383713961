.tip-box {
  background-color: #f0fafe;
  border-radius: 5px;
  padding: 25px;
  width: 100%;
}
.tip-box__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.tip-box__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 24px;
  line-height: 1;
}
.tip-box__title--small {
  font-size: 20px;
}
.tip-box__toggle-icon {
  cursor: pointer;
  transition: transform 100ms;
}
.tip-box__toggle-icon path {
  stroke: #6991a1;
}
.tip-box__toggle-icon--down {
  transform: rotate(90deg);
}
.tip-box__toggle-icon--up {
  transform: rotate(-90deg);
}
.tip-box__body {
  width: 100%;
  padding-top: 15px;
}
.tip-box__body--open {
  display: block;
}
.tip-box__body--closed {
  display: none;
}
.tip-box__tip-wrap {
  width: 100%;
  margin-bottom: 20px;
}
.tip-box__tip-wrap:last-child {
  margin-bottom: 0;
}
.tip-box__tip {
  display: flex;
}
.tip-box__tip-icon {
  margin-top: 5px;
  margin-right: 10px;
}
.tip-box__tip-icon path {
  fill: #00d2c8;
  stroke: #00d2c8;
}
.tip-box__tip-text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #484444;
}
.tip-box__tip-text--small {
  font-size: 18px;
}
.tip-box__tip-link {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #484444 !important;
  cursor: pointer;
}
.tip-box__tip-link:hover {
  color: #484444 !important;
  text-decoration: underline;
}
.tip-box__tip-link--small {
  font-size: 18px;
}
.tip-box__example {
  margin-top: 20px;
  background-color: #d7f0fa;
  padding: 20px;
}
.tip-box__example-title {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #6991a1;
}
.tip-box__example-title--small {
  font-size: 18px;
}
.tip-box__example-text-lines {
  width: 100%;
}
.tip-box__example-text {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #484444;
  padding-top: 20px;
  padding-right: 10px;
}
.tip-box__example-text:first-child {
  padding-top: 0;
}
.tip-box__example-text--small {
  font-size: 18px;
}
