.class-format__wrapper {
  display: block;
}
.class-format__radio {
  padding-bottom: 14px;
}
.class-format__link,
.class-format__link:link,
.class-format__link:visited,
.class-format__link:hover,
.class-format__link:active {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  text-decoration-line: underline;
}
.class-format__link--camelot,
.class-format__link--camelot:link,
.class-format__link--camelot:visited,
.class-format__link--camelot:hover,
.class-format__link--camelot:active {
  color: #822d50;
}
.class-format__link--emerald,
.class-format__link--emerald:link,
.class-format__link--emerald:visited,
.class-format__link--emerald:hover,
.class-format__link--emerald:active {
  color: #32c864;
}
.class-format__link--sun,
.class-format__link--sun:link,
.class-format__link--sun:visited,
.class-format__link--sun:hover,
.class-format__link--sun:active {
  color: #faaa1e;
}
.class-format__link--robbinsEggBlue,
.class-format__link--robbinsEggBlue:link,
.class-format__link--robbinsEggBlue:visited,
.class-format__link--robbinsEggBlue:hover,
.class-format__link--robbinsEggBlue:active {
  color: #00d2c8;
}
.class-format__link--ebonyClay,
.class-format__link--ebonyClay:link,
.class-format__link--ebonyClay:visited,
.class-format__link--ebonyClay:hover,
.class-format__link--ebonyClay:active {
  color: #28283c;
}
.class-format__link--azalea,
.class-format__link--azalea:link,
.class-format__link--azalea:visited,
.class-format__link--azalea:hover,
.class-format__link--azalea:active {
  color: #fadcdc;
}
.class-format__link--cruise,
.class-format__link--cruise:link,
.class-format__link--cruise:visited,
.class-format__link--cruise:hover,
.class-format__link--cruise:active {
  color: #bff0db;
}
.class-format__link--astra,
.class-format__link--astra:link,
.class-format__link--astra:visited,
.class-format__link--astra:hover,
.class-format__link--astra:active {
  color: #fafabe;
}
.class-format__link--gallery,
.class-format__link--gallery:link,
.class-format__link--gallery:visited,
.class-format__link--gallery:hover,
.class-format__link--gallery:active {
  color: #f0f0f0;
}
.class-format__link--humming-bird,
.class-format__link--humming-bird:link,
.class-format__link--humming-bird:visited,
.class-format__link--humming-bird:hover,
.class-format__link--humming-bird:active {
  color: #d7f0fa;
}
.class-format__link--humming-bird-faded,
.class-format__link--humming-bird-faded:link,
.class-format__link--humming-bird-faded:visited,
.class-format__link--humming-bird-faded:hover,
.class-format__link--humming-bird-faded:active {
  color: #f0fafe;
}
.class-format__link--silver-chalice,
.class-format__link--silver-chalice:link,
.class-format__link--silver-chalice:visited,
.class-format__link--silver-chalice:hover,
.class-format__link--silver-chalice:active {
  color: #a6a6a6;
}
.class-format__link--catskill-white,
.class-format__link--catskill-white:link,
.class-format__link--catskill-white:visited,
.class-format__link--catskill-white:hover,
.class-format__link--catskill-white:active {
  color: #e1e9f0;
}
.class-format__link--bali-hai,
.class-format__link--bali-hai:link,
.class-format__link--bali-hai:visited,
.class-format__link--bali-hai:hover,
.class-format__link--bali-hai:active {
  color: #8298ab;
}
.class-format__link--french-lilac,
.class-format__link--french-lilac:link,
.class-format__link--french-lilac:visited,
.class-format__link--french-lilac:hover,
.class-format__link--french-lilac:active {
  color: #efe0f6;
}
.class-format__link--deep-blush,
.class-format__link--deep-blush:link,
.class-format__link--deep-blush:visited,
.class-format__link--deep-blush:hover,
.class-format__link--deep-blush:active {
  color: #e07688;
}
.class-format__link--pink,
.class-format__link--pink:link,
.class-format__link--pink:visited,
.class-format__link--pink:hover,
.class-format__link--pink:active {
  color: #fbdad8;
}
.class-format__link--gothic,
.class-format__link--gothic:link,
.class-format__link--gothic:visited,
.class-format__link--gothic:hover,
.class-format__link--gothic:active {
  color: #6991a1;
}
.class-format__link--polar-blue,
.class-format__link--polar-blue:link,
.class-format__link--polar-blue:visited,
.class-format__link--polar-blue:hover,
.class-format__link--polar-blue:active {
  color: #f0f9fc;
}
.class-format__link--prussian,
.class-format__link--prussian:link,
.class-format__link--prussian:visited,
.class-format__link--prussian:hover,
.class-format__link--prussian:active {
  color: #304659;
}
.class-format__link--sky,
.class-format__link--sky:link,
.class-format__link--sky:visited,
.class-format__link--sky:hover,
.class-format__link--sky:active {
  color: #e1e9f0;
}
.class-format__link--tulip-tree,
.class-format__link--tulip-tree:link,
.class-format__link--tulip-tree:visited,
.class-format__link--tulip-tree:hover,
.class-format__link--tulip-tree:active {
  color: #f0ac44;
}
.class-format__link--emeraldish,
.class-format__link--emeraldish:link,
.class-format__link--emeraldish:visited,
.class-format__link--emeraldish:hover,
.class-format__link--emeraldish:active {
  color: #03c2b9;
}
.class-format__link--jumbo,
.class-format__link--jumbo:link,
.class-format__link--jumbo:visited,
.class-format__link--jumbo:hover,
.class-format__link--jumbo:active {
  color: #7b7b86;
}
.class-format__link--pampas,
.class-format__link--pampas:link,
.class-format__link--pampas:visited,
.class-format__link--pampas:hover,
.class-format__link--pampas:active {
  color: #f8f7f5;
}
.class-format__link--dark-gallery,
.class-format__link--dark-gallery:link,
.class-format__link--dark-gallery:visited,
.class-format__link--dark-gallery:hover,
.class-format__link--dark-gallery:active {
  color: #eaeaea;
}
.class-format__link--concrete,
.class-format__link--concrete:link,
.class-format__link--concrete:visited,
.class-format__link--concrete:hover,
.class-format__link--concrete:active {
  color: #f2f2f2;
}
.class-format__link--sand,
.class-format__link--sand:link,
.class-format__link--sand:visited,
.class-format__link--sand:hover,
.class-format__link--sand:active {
  color: #fbfbfb;
}
.class-format__link--black-squeeze,
.class-format__link--black-squeeze:link,
.class-format__link--black-squeeze:visited,
.class-format__link--black-squeeze:hover,
.class-format__link--black-squeeze:active {
  color: #f9fcfd;
}
.class-format__link--athens-gray,
.class-format__link--athens-gray:link,
.class-format__link--athens-gray:visited,
.class-format__link--athens-gray:hover,
.class-format__link--athens-gray:active {
  color: #f4f4f4;
}
.class-format__link--alto,
.class-format__link--alto:link,
.class-format__link--alto:visited,
.class-format__link--alto:hover,
.class-format__link--alto:active {
  color: #d8d8d8;
}
.class-format__link--gray,
.class-format__link--gray:link,
.class-format__link--gray:visited,
.class-format__link--gray:hover,
.class-format__link--gray:active {
  color: #888;
}
.class-format__link--venus,
.class-format__link--venus:link,
.class-format__link--venus:visited,
.class-format__link--venus:hover,
.class-format__link--venus:active {
  color: #7e7e8a;
}
.class-format__link--dolphin,
.class-format__link--dolphin:link,
.class-format__link--dolphin:visited,
.class-format__link--dolphin:hover,
.class-format__link--dolphin:active {
  color: #6b6a77;
}
.class-format__link--mercury,
.class-format__link--mercury:link,
.class-format__link--mercury:visited,
.class-format__link--mercury:hover,
.class-format__link--mercury:active {
  color: #e6e6e6;
}
.class-format__link--text-black,
.class-format__link--text-black:link,
.class-format__link--text-black:visited,
.class-format__link--text-black:hover,
.class-format__link--text-black:active {
  color: #28283c;
}
.class-format__link--polar,
.class-format__link--polar:link,
.class-format__link--polar:visited,
.class-format__link--polar:hover,
.class-format__link--polar:active {
  color: #eafbfa;
}
.class-format__link--tundora,
.class-format__link--tundora:link,
.class-format__link--tundora:visited,
.class-format__link--tundora:hover,
.class-format__link--tundora:active {
  color: #484444;
}
.class-format__link--jet,
.class-format__link--jet:link,
.class-format__link--jet:visited,
.class-format__link--jet:hover,
.class-format__link--jet:active {
  color: #3e3e50;
}
.class-format__link--silver,
.class-format__link--silver:link,
.class-format__link--silver:visited,
.class-format__link--silver:hover,
.class-format__link--silver:active {
  color: #bdbdbd;
}
.class-format__link--smoke,
.class-format__link--smoke:link,
.class-format__link--smoke:visited,
.class-format__link--smoke:hover,
.class-format__link--smoke:active {
  color: #bfbfbf;
}
.class-format__link--white,
.class-format__link--white:link,
.class-format__link--white:visited,
.class-format__link--white:hover,
.class-format__link--white:active {
  color: #fff;
}
.class-format__link--malachite,
.class-format__link--malachite:link,
.class-format__link--malachite:visited,
.class-format__link--malachite:hover,
.class-format__link--malachite:active {
  color: #00c040;
}
.class-format__link--light-green,
.class-format__link--light-green:link,
.class-format__link--light-green:visited,
.class-format__link--light-green:hover,
.class-format__link--light-green:active {
  color: #c0f0dd;
}
.class-format__link--coral,
.class-format__link--coral:link,
.class-format__link--coral:visited,
.class-format__link--coral:hover,
.class-format__link--coral:active {
  color: #ff774d;
}
.class-format__link--bright-sun,
.class-format__link--bright-sun:link,
.class-format__link--bright-sun:visited,
.class-format__link--bright-sun:hover,
.class-format__link--bright-sun:active {
  color: #ffcc43;
}
.class-format__link--shark,
.class-format__link--shark:link,
.class-format__link--shark:visited,
.class-format__link--shark:hover,
.class-format__link--shark:active {
  color: #272a2d;
}
.class-format__link--royal-blue,
.class-format__link--royal-blue:link,
.class-format__link--royal-blue:visited,
.class-format__link--royal-blue:hover,
.class-format__link--royal-blue:active {
  color: #4945e2;
}
.class-format__link--aquamarine-blue,
.class-format__link--aquamarine-blue:link,
.class-format__link--aquamarine-blue:visited,
.class-format__link--aquamarine-blue:hover,
.class-format__link--aquamarine-blue:active {
  color: #7ce5df;
}
.class-format__link--scorpion,
.class-format__link--scorpion:link,
.class-format__link--scorpion:visited,
.class-format__link--scorpion:hover,
.class-format__link--scorpion:active {
  color: #595959;
}
.class-format__link--heather,
.class-format__link--heather:link,
.class-format__link--heather:visited,
.class-format__link--heather:hover,
.class-format__link--heather:active {
  color: #b8c5d0;
}
.class-format__link--red,
.class-format__link--red:link,
.class-format__link--red:visited,
.class-format__link--red:hover,
.class-format__link--red:active {
  color: #de2828;
}
.class-format__link--lighter-teal,
.class-format__link--lighter-teal:link,
.class-format__link--lighter-teal:visited,
.class-format__link--lighter-teal:hover,
.class-format__link--lighter-teal:active {
  color: #d6f6f3;
}
.class-format__link--blue-lagoon,
.class-format__link--blue-lagoon:link,
.class-format__link--blue-lagoon:visited,
.class-format__link--blue-lagoon:hover,
.class-format__link--blue-lagoon:active {
  color: #007986;
}
.class-format__link--brightTeal,
.class-format__link--brightTeal:link,
.class-format__link--brightTeal:visited,
.class-format__link--brightTeal:hover,
.class-format__link--brightTeal:active {
  color: #00d8c4;
}
