.radio-input-option__wrapper {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}
@media (min-width: 576px) {
  .radio-input-option__wrapper {
    align-items: initial;
  }
}
.radio-input-option__text--size-medium {
  margin-left: 15px;
}
.radio-input-option__text--size-large {
  margin-left: 22px;
}
.radio-input-option__label {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  color: #28283c;
  line-height: initial;
}
.radio-input-option__label--size-medium {
  font-size: 18px;
}
.radio-input-option__label--size-large {
  font-size: 20px;
  margin: 6px 0;
}
.radio-input-option__label--disabled {
  color: #bdbdbd;
}
.radio-input-option__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  color: #888;
  line-height: 1.4;
}
.radio-input-option__description--size-medium {
  font-size: 16px;
}
.radio-input-option__description--size-large {
  font-size: 18px;
}
.radio-input-option__description--disabled {
  color: #bdbdbd;
}
