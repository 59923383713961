.action-banner__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.action-banner__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  color: #28283c;
  margin-bottom: 20px;
}
.action-banner__title--size-medium {
  font-size: 21px;
}
@media (min-width: 834px) {
  .action-banner__title--size-medium {
    font-size: 30px;
  }
}
.action-banner__title--size-large {
  font-size: 36px;
}
@media (min-width: 834px) {
  .action-banner__title--size-large {
    font-size: 48px;
  }
}
@media (min-width: 1024px) {
  .action-banner__title--size-large {
    font-size: 70px;
  }
}
.action-banner__description {
  margin-bottom: 17px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  line-height: 1.2;
  color: #28283c;
}
.action-banner__description--size-medium {
  font-size: 16px;
}
@media (min-width: 834px) {
  .action-banner__description--size-medium {
    font-size: 18px;
  }
}
.action-banner__description--size-large {
  font-size: 20px;
  font-weight: 500;
}
@media (min-width: 834px) {
  .action-banner__description--size-large {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .action-banner__description--size-large {
    font-size: 36px;
  }
}
.action-banner__description-paragraph {
  margin: 0;
}
.action-banner__description-paragraph + .action-banner__description-paragraph__description-paragraph {
  margin-top: 23px;
}
.action-banner__description-link {
  display: inline-block;
  line-height: 20px;
}
.action-banner__description-link:link,
.action-banner__description-link:visited,
.action-banner__description-link:hover,
.action-banner__description-link:active {
  color: inherit;
  text-decoration: none;
}
.action-banner__space {
  flex: 1 1 auto;
}
.action-banner__actions {
  display: flex;
  flex-wrap: wrap;
}
.action-banner__action {
  display: inline-block;
  margin-top: 10px;
}
.action-banner__action:disabled {
  background: #e8e8e8;
  border-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.25);
}
.action-banner__action + .action-banner__action {
  margin-left: 11px;
}
