.students-filter-body {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
}
.students-filter-body__link,
.students-filter-body__link:link,
.students-filter-body__link:visited,
.students-filter-body__link:hover,
.students-filter-body__link:active {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #03c2b9;
}
