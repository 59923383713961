@-webkit-keyframes popout {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.31% {
    -webkit-transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.02% {
    -webkit-transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.62% {
    -webkit-transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.33% {
    -webkit-transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  33.93% {
    -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  45.15% {
    -webkit-transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  72.57% {
    -webkit-transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@keyframes popout {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.31% {
    -webkit-transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.02% {
    -webkit-transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.62% {
    -webkit-transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.33% {
    -webkit-transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  33.93% {
    -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  45.15% {
    -webkit-transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  72.57% {
    -webkit-transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cookie-toast {
  display: flex;
  background-color: #28283c;
  border-radius: 4px;
  padding: 10px;
  max-width: 300px;
}
@media (min-width: 576px) {
  .cookie-toast {
    padding: 15px;
  }
}
@media (min-width: 700px) {
  .cookie-toast {
    padding: 20px;
  }
}
.cookie-toast--hidden {
  display: none;
}
.cookie-toast--sun {
  background-color: #faaa1e;
}
.cookie-toast__text {
  flex: 1;
  letter-spacing: 0.3px;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  color: #d7f0fa;
  animation: popout 250ms, fadeIn 250ms;
}
@media (min-width: 576px) {
  .cookie-toast__text {
    font-size: 14px;
  }
}
@media (min-width: 700px) {
  .cookie-toast__text {
    font-size: 16px;
  }
}
.cookie-toast__text--sun {
  color: #822d50;
}
.cookie-toast__button {
  width: 34px;
  height: 34px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  animation: popout 250ms, fadeIn 250ms;
}
.cookie-toast__button g {
  stroke: #d7f0fa;
}
.cookie-toast__button--sun g {
  stroke: #28283c;
}
