.class-list-menu-item {
  display: flex;
}
.class-list-menu-item:link,
.class-list-menu-item:visited,
.class-list-menu-item:hover,
.class-list-menu-item:active {
  text-decoration: none;
}
.class-list-menu-item__thumbnail-wrapper {
  flex: 0 0 90px;
  height: 90px;
  border-radius: 2px;
  overflow: hidden;
}
.class-list-menu-item__thumbnail {
  display: block;
  width: 100%;
}
.class-list-menu-item__content {
  flex: 1;
  padding-left: 16px;
}
.class-list-menu-item__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #28283c;
  display: block;
  margin-bottom: 4px;
}
.class-list-menu-item__classes {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #28283c;
  opacity: 0.8;
  display: block;
}
