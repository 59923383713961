@media (max-width: 576px) {
  .container-fluid.mobile {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .container-fluid.mobile .class-page-menu {
    padding-right: 15px;
    padding-left: 15px;
    background-color: #f0f0f0;
  }
}
.class-page-menu {
  background: #fff;
}
.class-page-menu__wrapper {
  padding: 10px 0;
  width: 100%;
}
@media (min-width: 834px) {
  .class-page-menu__wrapper {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: space-between;
  }
}
.class-page-menu__menu-list {
  display: none;
}
@media (max-width: 576px) {
  .class-page-menu__menu-list .class-page-menu__link:link {
    margin-right: 0px;
  }
}
@media (min-width: 834px) {
  .class-page-menu__menu-list {
    display: flex;
  }
}
@media (max-width: 576px) {
  .class-page-menu__menu-list > * + * {
    margin-left: 14px;
  }
}
.class-page-menu__link:link,
.class-page-menu__link:visited,
.class-page-menu__link:hover,
.class-page-menu__link:active {
  font-weight: 500;
  line-height: 110%;
  margin-right: 40px;
  text-decoration: none;
  letter-spacing: 0.03em;
  font-size: 18px;
  color: #00d2c8;
  text-transform: capitalize;
  align-self: center;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
@media (max-width: 425px) {
  .class-page-menu__link:link,
  .class-page-menu__link:visited,
  .class-page-menu__link:hover,
  .class-page-menu__link:active {
    font-size: 15px;
  }
}
.class-page-menu__link.active {
  color: #434343;
}
.class-page-menu__book-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.class-page-menu.mobile {
  display: none;
}
@media (max-width: 576px) {
  .class-page-menu.mobile {
    display: flex;
  }
}
.class-page-menu.mobile .class-page-menu__menu-list {
  display: flex;
  justify-content: space-evenly;
}
.class-page-menu.mobile .class-page-menu__book-wrapper {
  display: none;
}
.class-page-menu__actions {
  display: flex;
}
@media (max-width: 576px) {
  .class-page-menu__actions {
    width: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .class-page-menu__actions .primary-button {
    align-self: flex-end;
  }
}
.class-page-menu__reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}
.class-page-menu__reviews--no-reviews {
  justify-content: center;
}
.class-page-menu__reviews--no-reviews span {
  font-size: 22px !important;
}
.class-page-menu__reviews span {
  font-size: 18px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
@media (max-width: 425px) {
  .class-page-menu__reviews span {
    font-size: 15px;
  }
}
@media (min-width: 576px) {
  .class-page-menu__reviews {
    display: none;
  }
}
@media (max-width: 576px) {
  .class-page-menu__action.secondary-button {
    display: none;
  }
}
.class-page-menu__action.secondary-button--width-max,
.class-page-menu__action.primary-button--width-max {
  width: 185px;
}
.class-page-menu__action + .class-page-menu__action {
  margin-left: 10px;
  text-decoration: underline;
}
.class-page-menu__price {
  display: inline-block;
}
.class-page-menu__price--with-discount,
.class-page-menu__price--with-discount span {
  text-decoration: line-through;
}
