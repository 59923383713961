.highlight-discount {
  display: inline-block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #bfefdc;
  background-color: #00d2c8;
  transform: rotate(-2.85deg);
  text-align: center;
}
.highlight-discount--small {
  font-size: 22px;
  line-height: 18px;
  height: 20px;
  width: 100px;
}
.highlight-discount--medium {
  font-size: 34px;
  line-height: 24px;
  height: 28px;
  width: 150px;
}
.highlight-discount--large {
  font-size: 48px;
  height: 40px;
  line-height: 34px;
  width: 200px;
}
