.gift-page-footer-bar {
  background-color: #f0f0f0;
}
.gift-page-footer-bar__wrapper {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
}
.gift-page-footer-bar__actions {
  display: flex;
}
.gift-page-footer-bar__action {
  width: 185px;
  margin-left: 10px;
}
.gift-page-footer-bar__action:first-child {
  margin-left: 0;
}
.gift-page-footer-bar__reviews {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gift-page-footer-bar__reviews .rating-summary {
  font-size: 15px;
}
@media (min-width: 425px) {
  .gift-page-footer-bar__reviews .rating-summary {
    font-size: 18px;
  }
}
