.avatar-blurb-with-tags {
  display: flex;
}
.avatar-blurb-with-tags__image-wrapper {
  flex: 0 0 51px;
  height: 51px;
  border-radius: 50%;
  overflow: hidden;
  background-image: #eee;
}
.avatar-blurb-with-tags__blurb {
  padding-left: 16px;
  color: currentColor;
  display: flex;
  flex-direction: column;
}
.avatar-blurb-with-tags__image {
  width: 100%;
  display: block;
}
.avatar-blurb-with-tags__name {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  line-height: 80%;
  color: currentColor;
  font-size: 18px;
  margin: 0 0 10px 0;
  line-height: 1.1;
}
.avatar-blurb-with-tags__tags {
  display: flex;
}
.avatar-blurb-with-tags__tag {
  display: inline-flex;
  align-items: center;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 0.8em;
  border-radius: 4px;
  height: 25px;
  padding: 0 10px;
  color: #fff;
  width: 100%;
  justify-content: center;
}
.avatar-blurb-with-tags__tag--camelot {
  background-color: #822d50;
}
.avatar-blurb-with-tags__tag--emerald {
  background-color: #32c864;
}
.avatar-blurb-with-tags__tag--sun {
  background-color: #faaa1e;
}
.avatar-blurb-with-tags__tag--robbinsEggBlue {
  background-color: #00d2c8;
}
.avatar-blurb-with-tags__tag--ebonyClay {
  background-color: #28283c;
}
.avatar-blurb-with-tags__tag--azalea {
  background-color: #fadcdc;
}
.avatar-blurb-with-tags__tag--cruise {
  background-color: #bff0db;
}
.avatar-blurb-with-tags__tag--astra {
  background-color: #fafabe;
}
.avatar-blurb-with-tags__tag--gallery {
  background-color: #f0f0f0;
}
.avatar-blurb-with-tags__tag--humming-bird {
  background-color: #d7f0fa;
}
.avatar-blurb-with-tags__tag--humming-bird-faded {
  background-color: #f0fafe;
}
.avatar-blurb-with-tags__tag--silver-chalice {
  background-color: #a6a6a6;
}
.avatar-blurb-with-tags__tag--catskill-white {
  background-color: #e1e9f0;
}
.avatar-blurb-with-tags__tag--bali-hai {
  background-color: #8298ab;
}
.avatar-blurb-with-tags__tag--french-lilac {
  background-color: #efe0f6;
}
.avatar-blurb-with-tags__tag--deep-blush {
  background-color: #e07688;
}
.avatar-blurb-with-tags__tag--pink {
  background-color: #fbdad8;
}
.avatar-blurb-with-tags__tag--gothic {
  background-color: #6991a1;
}
.avatar-blurb-with-tags__tag--polar-blue {
  background-color: #f0f9fc;
}
.avatar-blurb-with-tags__tag--prussian {
  background-color: #304659;
}
.avatar-blurb-with-tags__tag--sky {
  background-color: #e1e9f0;
}
.avatar-blurb-with-tags__tag--tulip-tree {
  background-color: #f0ac44;
}
.avatar-blurb-with-tags__tag--emeraldish {
  background-color: #03c2b9;
}
.avatar-blurb-with-tags__tag--jumbo {
  background-color: #7b7b86;
}
.avatar-blurb-with-tags__tag--pampas {
  background-color: #f8f7f5;
}
.avatar-blurb-with-tags__tag--dark-gallery {
  background-color: #eaeaea;
}
.avatar-blurb-with-tags__tag--concrete {
  background-color: #f2f2f2;
}
.avatar-blurb-with-tags__tag--sand {
  background-color: #fbfbfb;
}
.avatar-blurb-with-tags__tag--black-squeeze {
  background-color: #f9fcfd;
}
.avatar-blurb-with-tags__tag--athens-gray {
  background-color: #f4f4f4;
}
.avatar-blurb-with-tags__tag--alto {
  background-color: #d8d8d8;
}
.avatar-blurb-with-tags__tag--gray {
  background-color: #888;
}
.avatar-blurb-with-tags__tag--venus {
  background-color: #7e7e8a;
}
.avatar-blurb-with-tags__tag--dolphin {
  background-color: #6b6a77;
}
.avatar-blurb-with-tags__tag--mercury {
  background-color: #e6e6e6;
}
.avatar-blurb-with-tags__tag--text-black {
  background-color: #28283c;
}
.avatar-blurb-with-tags__tag--polar {
  background-color: #eafbfa;
}
.avatar-blurb-with-tags__tag--tundora {
  background-color: #484444;
}
.avatar-blurb-with-tags__tag--jet {
  background-color: #3e3e50;
}
.avatar-blurb-with-tags__tag--silver {
  background-color: #bdbdbd;
}
.avatar-blurb-with-tags__tag--smoke {
  background-color: #bfbfbf;
}
.avatar-blurb-with-tags__tag--white {
  background-color: #fff;
}
.avatar-blurb-with-tags__tag--malachite {
  background-color: #00c040;
}
.avatar-blurb-with-tags__tag--light-green {
  background-color: #c0f0dd;
}
.avatar-blurb-with-tags__tag--coral {
  background-color: #ff774d;
}
.avatar-blurb-with-tags__tag--bright-sun {
  background-color: #ffcc43;
}
.avatar-blurb-with-tags__tag--shark {
  background-color: #272a2d;
}
.avatar-blurb-with-tags__tag--royal-blue {
  background-color: #4945e2;
}
.avatar-blurb-with-tags__tag--aquamarine-blue {
  background-color: #7ce5df;
}
.avatar-blurb-with-tags__tag--scorpion {
  background-color: #595959;
}
.avatar-blurb-with-tags__tag--heather {
  background-color: #b8c5d0;
}
.avatar-blurb-with-tags__tag--red {
  background-color: #de2828;
}
.avatar-blurb-with-tags__tag--lighter-teal {
  background-color: #d6f6f3;
}
.avatar-blurb-with-tags__tag--blue-lagoon {
  background-color: #007986;
}
.avatar-blurb-with-tags__tag--brightTeal {
  background-color: #00d8c4;
}
.avatar-blurb-with-tags__tag + .avatar-blurb-with-tags__tag {
  margin-left: 10px;
}
