.product-selection-row {
  border: 1px solid #f0f0f0;
  background-color: white;
  border-radius: 10px;
  position: relative;
  line-height: normal;
}
.product-selection-row--selectable {
  cursor: pointer;
}
.product-selection-row--selected {
  background-color: #d5f5f3;
  border: 2px solid #03c2b9;
}
.product-selection-row--selected .react-select__control {
  background-color: transparent;
  border-color: #03c2b9;
}
.product-selection-row--selected .react-select__control:hover {
  border-color: #03c2b9;
}
.product-selection-row--selected .react-select__dropdown-indicator {
  color: #03c2b9;
}
.product-selection-row--selected .react-select__dropdown-indicator:hover {
  color: #03c2b9;
}
.product-selection-row--disabled {
  opacity: 0.5;
  cursor: default;
}
.product-selection-row__wrapper--selected {
  margin: -1px;
}
.product-selection-row__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #28283c;
}
.product-selection-row__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #28283c;
  opacity: 0.8;
}
.product-selection-row__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 45px;
}
.product-selection-row__price {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #28283c;
}
.product-selection-row__price--discount {
  font-weight: normal;
  color: #888;
  text-decoration: line-through;
}
.product-selection-row .select-box {
  display: inline-block;
  width: 86px;
}
.product-selection-row__sold-out {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
}
