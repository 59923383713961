.address__line {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  color: #28283c;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.03em;
  opacity: 0.7;
}
.address__line + .address__line {
  margin-top: 3px;
}
