.menu-nav-item-wrapper {
  flex-shrink: 0;
}
.menu-nav-item-wrapper--border {
  border-bottom: 1px solid #d8d8d8;
}
@media (min-width: 1024px) {
  .menu-nav-item-wrapper--border {
    border-bottom: none;
  }
}
.menu-nav-item-wrapper__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  background: inherit;
  border: none;
  justify-content: space-between;
  height: 100%;
}
@media (min-width: 1024px) {
  .menu-nav-item-wrapper__link {
    justify-content: flex-start;
  }
}
.menu-nav-item-wrapper__link:focus {
  outline: none;
}
.menu-nav-item-wrapper__link:hover,
.menu-nav-item-wrapper__link:active {
  text-decoration: none;
}
