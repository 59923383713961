.shape-with-text-sticker {
  padding: 24px;
}
@media (max-width: 834px) {
  .shape-with-text-sticker {
    padding: 20px;
  }
}
.shape-with-text-sticker--bg-camelot {
  background-color: #822d50;
  color: #822d50;
}
.shape-with-text-sticker--bg-emerald {
  background-color: #32c864;
  color: #32c864;
}
.shape-with-text-sticker--bg-sun {
  background-color: #faaa1e;
  color: #faaa1e;
}
.shape-with-text-sticker--bg-robbinsEggBlue {
  background-color: #00d2c8;
  color: #00d2c8;
}
.shape-with-text-sticker--bg-ebonyClay {
  background-color: #28283c;
  color: #28283c;
}
.shape-with-text-sticker--bg-azalea {
  background-color: #fadcdc;
  color: #fadcdc;
}
.shape-with-text-sticker--bg-cruise {
  background-color: #bff0db;
  color: #bff0db;
}
.shape-with-text-sticker--bg-astra {
  background-color: #fafabe;
  color: #fafabe;
}
.shape-with-text-sticker--bg-gallery {
  background-color: #f0f0f0;
  color: #f0f0f0;
}
.shape-with-text-sticker--bg-humming-bird {
  background-color: #d7f0fa;
  color: #d7f0fa;
}
.shape-with-text-sticker--bg-humming-bird-faded {
  background-color: #f0fafe;
  color: #f0fafe;
}
.shape-with-text-sticker--bg-silver-chalice {
  background-color: #a6a6a6;
  color: #a6a6a6;
}
.shape-with-text-sticker--bg-catskill-white {
  background-color: #e1e9f0;
  color: #e1e9f0;
}
.shape-with-text-sticker--bg-bali-hai {
  background-color: #8298ab;
  color: #8298ab;
}
.shape-with-text-sticker--bg-french-lilac {
  background-color: #efe0f6;
  color: #efe0f6;
}
.shape-with-text-sticker--bg-deep-blush {
  background-color: #e07688;
  color: #e07688;
}
.shape-with-text-sticker--bg-pink {
  background-color: #fbdad8;
  color: #fbdad8;
}
.shape-with-text-sticker--bg-gothic {
  background-color: #6991a1;
  color: #6991a1;
}
.shape-with-text-sticker--bg-polar-blue {
  background-color: #f0f9fc;
  color: #f0f9fc;
}
.shape-with-text-sticker--bg-prussian {
  background-color: #304659;
  color: #304659;
}
.shape-with-text-sticker--bg-sky {
  background-color: #e1e9f0;
  color: #e1e9f0;
}
.shape-with-text-sticker--bg-tulip-tree {
  background-color: #f0ac44;
  color: #f0ac44;
}
.shape-with-text-sticker--bg-emeraldish {
  background-color: #03c2b9;
  color: #03c2b9;
}
.shape-with-text-sticker--bg-jumbo {
  background-color: #7b7b86;
  color: #7b7b86;
}
.shape-with-text-sticker--bg-pampas {
  background-color: #f8f7f5;
  color: #f8f7f5;
}
.shape-with-text-sticker--bg-dark-gallery {
  background-color: #eaeaea;
  color: #eaeaea;
}
.shape-with-text-sticker--bg-concrete {
  background-color: #f2f2f2;
  color: #f2f2f2;
}
.shape-with-text-sticker--bg-sand {
  background-color: #fbfbfb;
  color: #fbfbfb;
}
.shape-with-text-sticker--bg-black-squeeze {
  background-color: #f9fcfd;
  color: #f9fcfd;
}
.shape-with-text-sticker--bg-athens-gray {
  background-color: #f4f4f4;
  color: #f4f4f4;
}
.shape-with-text-sticker--bg-alto {
  background-color: #d8d8d8;
  color: #d8d8d8;
}
.shape-with-text-sticker--bg-gray {
  background-color: #888;
  color: #888;
}
.shape-with-text-sticker--bg-venus {
  background-color: #7e7e8a;
  color: #7e7e8a;
}
.shape-with-text-sticker--bg-dolphin {
  background-color: #6b6a77;
  color: #6b6a77;
}
.shape-with-text-sticker--bg-mercury {
  background-color: #e6e6e6;
  color: #e6e6e6;
}
.shape-with-text-sticker--bg-text-black {
  background-color: #28283c;
  color: #28283c;
}
.shape-with-text-sticker--bg-polar {
  background-color: #eafbfa;
  color: #eafbfa;
}
.shape-with-text-sticker--bg-tundora {
  background-color: #484444;
  color: #484444;
}
.shape-with-text-sticker--bg-jet {
  background-color: #3e3e50;
  color: #3e3e50;
}
.shape-with-text-sticker--bg-silver {
  background-color: #bdbdbd;
  color: #bdbdbd;
}
.shape-with-text-sticker--bg-smoke {
  background-color: #bfbfbf;
  color: #bfbfbf;
}
.shape-with-text-sticker--bg-white {
  background-color: #fff;
  color: #fff;
}
.shape-with-text-sticker--bg-malachite {
  background-color: #00c040;
  color: #00c040;
}
.shape-with-text-sticker--bg-light-green {
  background-color: #c0f0dd;
  color: #c0f0dd;
}
.shape-with-text-sticker--bg-coral {
  background-color: #ff774d;
  color: #ff774d;
}
.shape-with-text-sticker--bg-bright-sun {
  background-color: #ffcc43;
  color: #ffcc43;
}
.shape-with-text-sticker--bg-shark {
  background-color: #272a2d;
  color: #272a2d;
}
.shape-with-text-sticker--bg-royal-blue {
  background-color: #4945e2;
  color: #4945e2;
}
.shape-with-text-sticker--bg-aquamarine-blue {
  background-color: #7ce5df;
  color: #7ce5df;
}
.shape-with-text-sticker--bg-scorpion {
  background-color: #595959;
  color: #595959;
}
.shape-with-text-sticker--bg-heather {
  background-color: #b8c5d0;
  color: #b8c5d0;
}
.shape-with-text-sticker--bg-red {
  background-color: #de2828;
  color: #de2828;
}
.shape-with-text-sticker--bg-lighter-teal {
  background-color: #d6f6f3;
  color: #d6f6f3;
}
.shape-with-text-sticker--bg-blue-lagoon {
  background-color: #007986;
  color: #007986;
}
.shape-with-text-sticker--bg-brightTeal {
  background-color: #00d8c4;
  color: #00d8c4;
}
.shape-with-text-sticker--box {
  border-radius: 10px;
  position: relative;
  text-align: center;
  max-width: 195px;
}
.shape-with-text-sticker--box:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: currentColor transparent transparent currentColor;
  border-width: 30px 20px;
  border-radius: 10px 0 0;
  transform: rotate(-90deg) translateX(-65%) skew(30deg);
  position: absolute;
  bottom: 0;
  left: 50px;
}
.shape-with-text-sticker--circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  padding: 54px 24px 34px;
  max-width: 194px;
  max-height: 194px;
  text-align: center;
}
@media (max-width: 834px) {
  .shape-with-text-sticker--circle {
    padding: 44px 20px 34px;
  }
}
.shape-with-text-sticker--circle:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: currentColor transparent transparent currentColor;
  border-width: 30px 30px;
  border-radius: 10px 0 0;
  transform: rotate(-72deg) translateX(1%) skew(40deg);
  position: absolute;
  bottom: 0;
  left: 30px;
}
.shape-with-text-sticker__title {
  display: block;
  font-size: 21px;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  margin-bottom: 6px;
  text-transform: uppercase;
  line-height: 100%;
}
@media (max-width: 768px) {
  .shape-with-text-sticker__title {
    font-size: 18px;
  }
}
.shape-with-text-sticker__title--camelot {
  color: #822d50;
}
.shape-with-text-sticker__title--emerald {
  color: #32c864;
}
.shape-with-text-sticker__title--sun {
  color: #faaa1e;
}
.shape-with-text-sticker__title--robbinsEggBlue {
  color: #00d2c8;
}
.shape-with-text-sticker__title--ebonyClay {
  color: #28283c;
}
.shape-with-text-sticker__title--azalea {
  color: #fadcdc;
}
.shape-with-text-sticker__title--cruise {
  color: #bff0db;
}
.shape-with-text-sticker__title--astra {
  color: #fafabe;
}
.shape-with-text-sticker__title--gallery {
  color: #f0f0f0;
}
.shape-with-text-sticker__title--humming-bird {
  color: #d7f0fa;
}
.shape-with-text-sticker__title--humming-bird-faded {
  color: #f0fafe;
}
.shape-with-text-sticker__title--silver-chalice {
  color: #a6a6a6;
}
.shape-with-text-sticker__title--catskill-white {
  color: #e1e9f0;
}
.shape-with-text-sticker__title--bali-hai {
  color: #8298ab;
}
.shape-with-text-sticker__title--french-lilac {
  color: #efe0f6;
}
.shape-with-text-sticker__title--deep-blush {
  color: #e07688;
}
.shape-with-text-sticker__title--pink {
  color: #fbdad8;
}
.shape-with-text-sticker__title--gothic {
  color: #6991a1;
}
.shape-with-text-sticker__title--polar-blue {
  color: #f0f9fc;
}
.shape-with-text-sticker__title--prussian {
  color: #304659;
}
.shape-with-text-sticker__title--sky {
  color: #e1e9f0;
}
.shape-with-text-sticker__title--tulip-tree {
  color: #f0ac44;
}
.shape-with-text-sticker__title--emeraldish {
  color: #03c2b9;
}
.shape-with-text-sticker__title--jumbo {
  color: #7b7b86;
}
.shape-with-text-sticker__title--pampas {
  color: #f8f7f5;
}
.shape-with-text-sticker__title--dark-gallery {
  color: #eaeaea;
}
.shape-with-text-sticker__title--concrete {
  color: #f2f2f2;
}
.shape-with-text-sticker__title--sand {
  color: #fbfbfb;
}
.shape-with-text-sticker__title--black-squeeze {
  color: #f9fcfd;
}
.shape-with-text-sticker__title--athens-gray {
  color: #f4f4f4;
}
.shape-with-text-sticker__title--alto {
  color: #d8d8d8;
}
.shape-with-text-sticker__title--gray {
  color: #888;
}
.shape-with-text-sticker__title--venus {
  color: #7e7e8a;
}
.shape-with-text-sticker__title--dolphin {
  color: #6b6a77;
}
.shape-with-text-sticker__title--mercury {
  color: #e6e6e6;
}
.shape-with-text-sticker__title--text-black {
  color: #28283c;
}
.shape-with-text-sticker__title--polar {
  color: #eafbfa;
}
.shape-with-text-sticker__title--tundora {
  color: #484444;
}
.shape-with-text-sticker__title--jet {
  color: #3e3e50;
}
.shape-with-text-sticker__title--silver {
  color: #bdbdbd;
}
.shape-with-text-sticker__title--smoke {
  color: #bfbfbf;
}
.shape-with-text-sticker__title--white {
  color: #fff;
}
.shape-with-text-sticker__title--malachite {
  color: #00c040;
}
.shape-with-text-sticker__title--light-green {
  color: #c0f0dd;
}
.shape-with-text-sticker__title--coral {
  color: #ff774d;
}
.shape-with-text-sticker__title--bright-sun {
  color: #ffcc43;
}
.shape-with-text-sticker__title--shark {
  color: #272a2d;
}
.shape-with-text-sticker__title--royal-blue {
  color: #4945e2;
}
.shape-with-text-sticker__title--aquamarine-blue {
  color: #7ce5df;
}
.shape-with-text-sticker__title--scorpion {
  color: #595959;
}
.shape-with-text-sticker__title--heather {
  color: #b8c5d0;
}
.shape-with-text-sticker__title--red {
  color: #de2828;
}
.shape-with-text-sticker__title--lighter-teal {
  color: #d6f6f3;
}
.shape-with-text-sticker__title--blue-lagoon {
  color: #007986;
}
.shape-with-text-sticker__title--brightTeal {
  color: #00d8c4;
}
.shape-with-text-sticker__description {
  font-size: 16px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  line-height: 112%;
}
@media (max-width: 768px) {
  .shape-with-text-sticker__description {
    font-size: 14px;
  }
}
.shape-with-text-sticker__description--camelot {
  color: #822d50;
}
.shape-with-text-sticker__description--emerald {
  color: #32c864;
}
.shape-with-text-sticker__description--sun {
  color: #faaa1e;
}
.shape-with-text-sticker__description--robbinsEggBlue {
  color: #00d2c8;
}
.shape-with-text-sticker__description--ebonyClay {
  color: #28283c;
}
.shape-with-text-sticker__description--azalea {
  color: #fadcdc;
}
.shape-with-text-sticker__description--cruise {
  color: #bff0db;
}
.shape-with-text-sticker__description--astra {
  color: #fafabe;
}
.shape-with-text-sticker__description--gallery {
  color: #f0f0f0;
}
.shape-with-text-sticker__description--humming-bird {
  color: #d7f0fa;
}
.shape-with-text-sticker__description--humming-bird-faded {
  color: #f0fafe;
}
.shape-with-text-sticker__description--silver-chalice {
  color: #a6a6a6;
}
.shape-with-text-sticker__description--catskill-white {
  color: #e1e9f0;
}
.shape-with-text-sticker__description--bali-hai {
  color: #8298ab;
}
.shape-with-text-sticker__description--french-lilac {
  color: #efe0f6;
}
.shape-with-text-sticker__description--deep-blush {
  color: #e07688;
}
.shape-with-text-sticker__description--pink {
  color: #fbdad8;
}
.shape-with-text-sticker__description--gothic {
  color: #6991a1;
}
.shape-with-text-sticker__description--polar-blue {
  color: #f0f9fc;
}
.shape-with-text-sticker__description--prussian {
  color: #304659;
}
.shape-with-text-sticker__description--sky {
  color: #e1e9f0;
}
.shape-with-text-sticker__description--tulip-tree {
  color: #f0ac44;
}
.shape-with-text-sticker__description--emeraldish {
  color: #03c2b9;
}
.shape-with-text-sticker__description--jumbo {
  color: #7b7b86;
}
.shape-with-text-sticker__description--pampas {
  color: #f8f7f5;
}
.shape-with-text-sticker__description--dark-gallery {
  color: #eaeaea;
}
.shape-with-text-sticker__description--concrete {
  color: #f2f2f2;
}
.shape-with-text-sticker__description--sand {
  color: #fbfbfb;
}
.shape-with-text-sticker__description--black-squeeze {
  color: #f9fcfd;
}
.shape-with-text-sticker__description--athens-gray {
  color: #f4f4f4;
}
.shape-with-text-sticker__description--alto {
  color: #d8d8d8;
}
.shape-with-text-sticker__description--gray {
  color: #888;
}
.shape-with-text-sticker__description--venus {
  color: #7e7e8a;
}
.shape-with-text-sticker__description--dolphin {
  color: #6b6a77;
}
.shape-with-text-sticker__description--mercury {
  color: #e6e6e6;
}
.shape-with-text-sticker__description--text-black {
  color: #28283c;
}
.shape-with-text-sticker__description--polar {
  color: #eafbfa;
}
.shape-with-text-sticker__description--tundora {
  color: #484444;
}
.shape-with-text-sticker__description--jet {
  color: #3e3e50;
}
.shape-with-text-sticker__description--silver {
  color: #bdbdbd;
}
.shape-with-text-sticker__description--smoke {
  color: #bfbfbf;
}
.shape-with-text-sticker__description--white {
  color: #fff;
}
.shape-with-text-sticker__description--malachite {
  color: #00c040;
}
.shape-with-text-sticker__description--light-green {
  color: #c0f0dd;
}
.shape-with-text-sticker__description--coral {
  color: #ff774d;
}
.shape-with-text-sticker__description--bright-sun {
  color: #ffcc43;
}
.shape-with-text-sticker__description--shark {
  color: #272a2d;
}
.shape-with-text-sticker__description--royal-blue {
  color: #4945e2;
}
.shape-with-text-sticker__description--aquamarine-blue {
  color: #7ce5df;
}
.shape-with-text-sticker__description--scorpion {
  color: #595959;
}
.shape-with-text-sticker__description--heather {
  color: #b8c5d0;
}
.shape-with-text-sticker__description--red {
  color: #de2828;
}
.shape-with-text-sticker__description--lighter-teal {
  color: #d6f6f3;
}
.shape-with-text-sticker__description--blue-lagoon {
  color: #007986;
}
.shape-with-text-sticker__description--brightTeal {
  color: #00d8c4;
}
