.join-banner {
  padding: 30px 0;
}
.join-banner__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 700px) {
  .join-banner__info {
    justify-content: center;
    margin: 0 32px;
  }
}
.join-banner__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  text-transform: uppercase;
  color: #28283c;
  margin: 35px 0 17px;
}
@media (min-width: 700px) {
  .join-banner__title {
    margin: 0 0 24px;
  }
}
@media (min-width: 1024px) {
  .join-banner__title {
    font-size: 42px;
  }
}
.join-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #28283c;
  max-width: 400px;
}
@media (min-width: 1024px) {
  .join-banner__description {
    font-size: 18px;
  }
}
.join-banner__description-paragraph {
  margin-top: 0;
}
.join-banner__description-paragraph:last-child {
  margin-bottom: 0;
}
.join-banner__image-wrapper {
  position: relative;
}
.join-banner__image {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 1.5591px 27.2303px rgba(40, 40, 60, 0.12);
  border-radius: 10px;
}
@media (min-width: 700px) {
  .join-banner__image {
    margin-top: 0;
  }
}
