.course-accordion-list-item {
  border-bottom: 1px solid #e6e6e6;
}
.course-accordion-list-item .accordion-box-toggle__tick path {
  stroke: #888;
}
.course-accordion-list-item__slot {
  padding: 30px 0;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.course-accordion-list-item__slot-content {
  flex: 1 1;
}
.course-accordion-list-item__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #822d50;
  line-height: 38px;
}
.course-accordion-list-item__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  color: #28283c;
  line-height: 1;
}
.course-accordion-list-item__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}
.course-accordion-list-item__action {
  width: 35px;
  height: 35px;
  border: solid 1px #e6e6e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
  padding-top: 3px;
  padding-left: 2px;
  margin-top: 2px;
  margin-right: 12px;
}
.course-accordion-list-item__action--hover-camelot:hover {
  background-color: #822d50;
}
.course-accordion-list-item__action--hover-ebonyClay:hover {
  background-color: #28283c;
}
.course-accordion-list-item__action:hover {
  border: none;
}
.course-accordion-list-item__action:hover .icon path {
  fill: #fff;
}
