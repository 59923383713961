.simple-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 18px;
}
.simple-cta__title {
  display: block;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #28283c;
}
@media (min-width: 835px) {
  .simple-cta__title {
    font-size: 42px;
  }
}
.simple-cta__description {
  display: block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #28283c;
  margin: 32px 0;
}
@media (min-width: 577px) {
  .simple-cta__description {
    max-width: 480px;
  }
}
@media (min-width: 835px) {
  .simple-cta__description {
    max-width: 595px;
    font-size: 20px;
  }
}
.simple-cta__cta:link,
.simple-cta__cta:hover {
  text-decoration: none;
}
@media (min-width: 577px) {
  .simple-cta__cta {
    display: none;
  }
}
@media (min-width: 577px) {
  .simple-cta--always-show-cta .simple-cta__cta {
    display: block;
  }
}
