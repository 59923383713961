.menu-nav-desktop {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .menu-nav-desktop {
    display: flex;
  }
}
