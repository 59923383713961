.menu-nav-user {
  display: none;
  position: relative;
}
@media (min-width: 1024px) {
  .menu-nav-user {
    display: flex;
    align-items: center;
  }
}
.menu-nav-user__avatar {
  display: block;
  cursor: pointer;
  height: 40px;
}
.menu-nav-user__avatar-image {
  height: 100%;
  border-radius: 50%;
}
.menu-nav-user__popout {
  position: absolute;
  padding: 30px;
  right: 0;
  top: 100%;
  background-color: white;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 1.5591px 27.2303px rgba(40, 40, 60, 0.12);
  animation: popout 250ms, fadeIn 250ms;
  width: 298px;
}
.menu-nav-user__popout:before {
  position: absolute;
  top: -7px;
  right: 13px;
  content: "";
  border-style: solid;
  border-color: transparent transparent white transparent;
  border-width: 0 7px 7px 7px;
}
.menu-nav-user__popout-heading {
  display: block;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 80%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding-bottom: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #dadada;
  color: #28283c;
}
