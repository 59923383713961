.rail__wrapper {
  display: flex;
  position: relative;
  transition: left 250ms;
}
.rail__wrapper--railing {
  transition: none;
}
.rail__item {
  margin: 0 10px;
}
.rail__item:first-child {
  margin-left: 0;
}
.rail__item:last-child {
  margin-right: 0;
}
.rail__item * {
  user-select: none;
}
