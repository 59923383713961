.product-landing-page-header {
  padding: 110px 0 50px;
}
@media (min-width: 1024px) {
  .product-landing-page-header {
    padding-top: 135px;
  }
}
.product-landing-page-header__highlights {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.product-landing-page-header__highlights:empty {
  margin-top: 0;
}
@media (min-width: 700px) {
  .product-landing-page-header__highlights {
    display: inline-flex;
    align-items: flex-start;
    margin-top: 0;
  }
}
.product-landing-page-header__highlight {
  padding: 0 10px;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  line-height: 100%;
}
@media (min-width: 834px) {
  .product-landing-page-header__highlight {
    font-size: 72px;
  }
}
.product-landing-page-header__highlight--1 {
  transform: matrix(1, 0.09, -0.09, 1, 0, 0);
}
.product-landing-page-header__highlight--2 {
  transform: matrix(1, -0.11, 0.03, 0.99, 0, 0);
}
.product-landing-page-header__highlight--color-camelot {
  color: #822d50;
}
.product-landing-page-header__highlight--color-emerald {
  color: #32c864;
}
.product-landing-page-header__highlight--color-sun {
  color: #faaa1e;
}
.product-landing-page-header__highlight--color-robbinsEggBlue {
  color: #00d2c8;
}
.product-landing-page-header__highlight--color-ebonyClay {
  color: #28283c;
}
.product-landing-page-header__highlight--color-azalea {
  color: #fadcdc;
}
.product-landing-page-header__highlight--color-cruise {
  color: #bff0db;
}
.product-landing-page-header__highlight--color-astra {
  color: #fafabe;
}
.product-landing-page-header__highlight--color-gallery {
  color: #f0f0f0;
}
.product-landing-page-header__highlight--color-humming-bird {
  color: #d7f0fa;
}
.product-landing-page-header__highlight--color-humming-bird-faded {
  color: #f0fafe;
}
.product-landing-page-header__highlight--color-silver-chalice {
  color: #a6a6a6;
}
.product-landing-page-header__highlight--color-catskill-white {
  color: #e1e9f0;
}
.product-landing-page-header__highlight--color-bali-hai {
  color: #8298ab;
}
.product-landing-page-header__highlight--color-french-lilac {
  color: #efe0f6;
}
.product-landing-page-header__highlight--color-deep-blush {
  color: #e07688;
}
.product-landing-page-header__highlight--color-pink {
  color: #fbdad8;
}
.product-landing-page-header__highlight--color-gothic {
  color: #6991a1;
}
.product-landing-page-header__highlight--color-polar-blue {
  color: #f0f9fc;
}
.product-landing-page-header__highlight--color-prussian {
  color: #304659;
}
.product-landing-page-header__highlight--color-sky {
  color: #e1e9f0;
}
.product-landing-page-header__highlight--color-tulip-tree {
  color: #f0ac44;
}
.product-landing-page-header__highlight--color-emeraldish {
  color: #03c2b9;
}
.product-landing-page-header__highlight--color-jumbo {
  color: #7b7b86;
}
.product-landing-page-header__highlight--color-pampas {
  color: #f8f7f5;
}
.product-landing-page-header__highlight--color-dark-gallery {
  color: #eaeaea;
}
.product-landing-page-header__highlight--color-concrete {
  color: #f2f2f2;
}
.product-landing-page-header__highlight--color-sand {
  color: #fbfbfb;
}
.product-landing-page-header__highlight--color-black-squeeze {
  color: #f9fcfd;
}
.product-landing-page-header__highlight--color-athens-gray {
  color: #f4f4f4;
}
.product-landing-page-header__highlight--color-alto {
  color: #d8d8d8;
}
.product-landing-page-header__highlight--color-gray {
  color: #888;
}
.product-landing-page-header__highlight--color-venus {
  color: #7e7e8a;
}
.product-landing-page-header__highlight--color-dolphin {
  color: #6b6a77;
}
.product-landing-page-header__highlight--color-mercury {
  color: #e6e6e6;
}
.product-landing-page-header__highlight--color-text-black {
  color: #28283c;
}
.product-landing-page-header__highlight--color-polar {
  color: #eafbfa;
}
.product-landing-page-header__highlight--color-tundora {
  color: #484444;
}
.product-landing-page-header__highlight--color-jet {
  color: #3e3e50;
}
.product-landing-page-header__highlight--color-silver {
  color: #bdbdbd;
}
.product-landing-page-header__highlight--color-smoke {
  color: #bfbfbf;
}
.product-landing-page-header__highlight--color-white {
  color: #fff;
}
.product-landing-page-header__highlight--color-malachite {
  color: #00c040;
}
.product-landing-page-header__highlight--color-light-green {
  color: #c0f0dd;
}
.product-landing-page-header__highlight--color-coral {
  color: #ff774d;
}
.product-landing-page-header__highlight--color-bright-sun {
  color: #ffcc43;
}
.product-landing-page-header__highlight--color-shark {
  color: #272a2d;
}
.product-landing-page-header__highlight--color-royal-blue {
  color: #4945e2;
}
.product-landing-page-header__highlight--color-aquamarine-blue {
  color: #7ce5df;
}
.product-landing-page-header__highlight--color-scorpion {
  color: #595959;
}
.product-landing-page-header__highlight--color-heather {
  color: #b8c5d0;
}
.product-landing-page-header__highlight--color-red {
  color: #de2828;
}
.product-landing-page-header__highlight--color-lighter-teal {
  color: #d6f6f3;
}
.product-landing-page-header__highlight--color-blue-lagoon {
  color: #007986;
}
.product-landing-page-header__highlight--color-brightTeal {
  color: #00d8c4;
}
.product-landing-page-header__highlight--background-color-camelot {
  background-color: #822d50;
}
.product-landing-page-header__highlight--background-color-emerald {
  background-color: #32c864;
}
.product-landing-page-header__highlight--background-color-sun {
  background-color: #faaa1e;
}
.product-landing-page-header__highlight--background-color-robbinsEggBlue {
  background-color: #00d2c8;
}
.product-landing-page-header__highlight--background-color-ebonyClay {
  background-color: #28283c;
}
.product-landing-page-header__highlight--background-color-azalea {
  background-color: #fadcdc;
}
.product-landing-page-header__highlight--background-color-cruise {
  background-color: #bff0db;
}
.product-landing-page-header__highlight--background-color-astra {
  background-color: #fafabe;
}
.product-landing-page-header__highlight--background-color-gallery {
  background-color: #f0f0f0;
}
.product-landing-page-header__highlight--background-color-humming-bird {
  background-color: #d7f0fa;
}
.product-landing-page-header__highlight--background-color-humming-bird-faded {
  background-color: #f0fafe;
}
.product-landing-page-header__highlight--background-color-silver-chalice {
  background-color: #a6a6a6;
}
.product-landing-page-header__highlight--background-color-catskill-white {
  background-color: #e1e9f0;
}
.product-landing-page-header__highlight--background-color-bali-hai {
  background-color: #8298ab;
}
.product-landing-page-header__highlight--background-color-french-lilac {
  background-color: #efe0f6;
}
.product-landing-page-header__highlight--background-color-deep-blush {
  background-color: #e07688;
}
.product-landing-page-header__highlight--background-color-pink {
  background-color: #fbdad8;
}
.product-landing-page-header__highlight--background-color-gothic {
  background-color: #6991a1;
}
.product-landing-page-header__highlight--background-color-polar-blue {
  background-color: #f0f9fc;
}
.product-landing-page-header__highlight--background-color-prussian {
  background-color: #304659;
}
.product-landing-page-header__highlight--background-color-sky {
  background-color: #e1e9f0;
}
.product-landing-page-header__highlight--background-color-tulip-tree {
  background-color: #f0ac44;
}
.product-landing-page-header__highlight--background-color-emeraldish {
  background-color: #03c2b9;
}
.product-landing-page-header__highlight--background-color-jumbo {
  background-color: #7b7b86;
}
.product-landing-page-header__highlight--background-color-pampas {
  background-color: #f8f7f5;
}
.product-landing-page-header__highlight--background-color-dark-gallery {
  background-color: #eaeaea;
}
.product-landing-page-header__highlight--background-color-concrete {
  background-color: #f2f2f2;
}
.product-landing-page-header__highlight--background-color-sand {
  background-color: #fbfbfb;
}
.product-landing-page-header__highlight--background-color-black-squeeze {
  background-color: #f9fcfd;
}
.product-landing-page-header__highlight--background-color-athens-gray {
  background-color: #f4f4f4;
}
.product-landing-page-header__highlight--background-color-alto {
  background-color: #d8d8d8;
}
.product-landing-page-header__highlight--background-color-gray {
  background-color: #888;
}
.product-landing-page-header__highlight--background-color-venus {
  background-color: #7e7e8a;
}
.product-landing-page-header__highlight--background-color-dolphin {
  background-color: #6b6a77;
}
.product-landing-page-header__highlight--background-color-mercury {
  background-color: #e6e6e6;
}
.product-landing-page-header__highlight--background-color-text-black {
  background-color: #28283c;
}
.product-landing-page-header__highlight--background-color-polar {
  background-color: #eafbfa;
}
.product-landing-page-header__highlight--background-color-tundora {
  background-color: #484444;
}
.product-landing-page-header__highlight--background-color-jet {
  background-color: #3e3e50;
}
.product-landing-page-header__highlight--background-color-silver {
  background-color: #bdbdbd;
}
.product-landing-page-header__highlight--background-color-smoke {
  background-color: #bfbfbf;
}
.product-landing-page-header__highlight--background-color-white {
  background-color: #fff;
}
.product-landing-page-header__highlight--background-color-malachite {
  background-color: #00c040;
}
.product-landing-page-header__highlight--background-color-light-green {
  background-color: #c0f0dd;
}
.product-landing-page-header__highlight--background-color-coral {
  background-color: #ff774d;
}
.product-landing-page-header__highlight--background-color-bright-sun {
  background-color: #ffcc43;
}
.product-landing-page-header__highlight--background-color-shark {
  background-color: #272a2d;
}
.product-landing-page-header__highlight--background-color-royal-blue {
  background-color: #4945e2;
}
.product-landing-page-header__highlight--background-color-aquamarine-blue {
  background-color: #7ce5df;
}
.product-landing-page-header__highlight--background-color-scorpion {
  background-color: #595959;
}
.product-landing-page-header__highlight--background-color-heather {
  background-color: #b8c5d0;
}
.product-landing-page-header__highlight--background-color-red {
  background-color: #de2828;
}
.product-landing-page-header__highlight--background-color-lighter-teal {
  background-color: #d6f6f3;
}
.product-landing-page-header__highlight--background-color-blue-lagoon {
  background-color: #007986;
}
.product-landing-page-header__highlight--background-color-brightTeal {
  background-color: #00d8c4;
}
.product-landing-page-header__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 36px;
  text-transform: uppercase;
  color: #28283c;
  line-height: 84%;
  margin: 50px 0 10px;
}
@media (min-width: 834px) {
  .product-landing-page-header__title {
    font-size: 48px;
  }
}
.product-landing-page-header__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 124%;
  letter-spacing: 0.01em;
  color: #28283c;
  opacity: 0.8;
}
@media (min-width: 834px) {
  .product-landing-page-header__description {
    font-size: 20px;
  }
}
@media (min-width: 700px) {
  .product-landing-page-header__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.product-landing-page-header__image {
  display: block;
  width: 100%;
  max-width: 600px;
}
.product-landing-page-header__buttons {
  margin: 0 -8px;
  text-align: center;
}
@media (min-width: 576px) {
  .product-landing-page-header__buttons {
    text-align: left;
  }
}
