.join-live-online-section__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #28283c;
  letter-spacing: 0.03em;
}
.join-live-online-section__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  color: #28283c;
  letter-spacing: 0.03em;
}
