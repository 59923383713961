.expandable-text__text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.01em;
  font-size: 18px;
  line-height: 1.5em;
}
.expandable-text__text p:first-child {
  margin-top: 0;
}
.expandable-text__read-more {
  display: inline-block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 80%;
  letter-spacing: 0.03em;
  color: #03c2b9;
  cursor: pointer;
  margin-top: 10px;
}
