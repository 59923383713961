.responsive-rail__mobile {
  display: none;
}
@media (max-width: 576px) {
  .responsive-rail__mobile {
    display: block;
  }
}
.responsive-rail__mobile-product-list {
  padding: 0;
  list-style: none;
}
.responsive-rail__mobile-product-list-item + .responsive-rail__mobile-product-list-item {
  margin-top: 14px;
}
.responsive-rail__mobile-product-list-title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 80%;
  margin-bottom: 23px;
  -webkit-font-smoothing: antialiased;
}
.responsive-rail__mobile-product-list-view-more {
  color: #00d2c8;
  text-decoration: none;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 80%;
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 3px;
  cursor: pointer;
}
.responsive-rail__tablet {
  display: none;
}
@media (min-width: 576px) and (max-width: 835px) {
  .responsive-rail__tablet {
    display: block;
  }
}
.responsive-rail__desktop {
  display: none;
}
@media (min-width: 834px) {
  .responsive-rail__desktop {
    display: block;
  }
}
.responsive-rail__desktop__see-more {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.responsive-rail__big-rail-card {
  width: 267px;
  height: 320px;
}
.responsive-rail__product-link {
  text-decoration: none;
  display: block;
}
.responsive-rail__product-link:hover,
.responsive-rail__product-link:visited,
.responsive-rail__product-link:active,
.responsive-rail__product-link:focus {
  color: inherit;
  text-decoration: none;
}
@media (min-width: 576px) and (max-width: 835px) {
  .responsive-rail__product-link {
    width: 267px;
    height: 320px;
  }
}
