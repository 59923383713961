.product-learning-materials__title {
  font-size: 28px;
  font-weight: bold;
  line-height: 100%;
  color: #28283c;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  text-align: center;
}
@media (min-width: 834px) {
  .product-learning-materials__title {
    font-size: 32px;
  }
}
.product-learning-materials__middle-section {
  align-self: center;
  margin: 30px 0;
}
@media (min-width: 834px) {
  .product-learning-materials__middle-section {
    margin: 0 0 100px;
  }
}
.product-learning-materials__note {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
}
.product-learning-materials__note-description {
  line-height: 24px;
}
.product-learning-materials__video-thumbnail-wrapper {
  position: relative;
}
.product-learning-materials__video-thumbnail {
  display: block;
  border-radius: 20px;
  width: 100%;
}
.product-learning-materials__play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px;
}
.product-learning-materials__play-icon path {
  fill: white;
}
