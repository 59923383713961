.balloon-card {
  max-width: 260px;
}
@media (min-width: 700px) {
  .balloon-card {
    max-width: 300px;
  }
}
.balloon-card__image {
  display: block;
  width: 76%;
  margin: 0 auto 36px;
}
@media (min-width: 700px) {
  .balloon-card__image {
    width: 66%;
  }
}
.balloon-card__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 90%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #28283c;
  margin: 7px 0;
}
@media (min-width: 1024px) {
  .balloon-card__title {
    font-size: 20px;
  }
}
.balloon-card__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #28283c;
}
@media (min-width: 1024px) {
  .balloon-card__description {
    font-size: 20px;
  }
}
