.drawer {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
}
@media (min-width: 576px) {
  .drawer {
    width: auto;
  }
}
.drawer--right {
  top: 0;
  left: 100%;
}
.drawer--left {
  top: 0;
  left: 50%;
}
.drawer__body {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  background-color: white;
}
.drawer__body--left {
  margin-left: -100%;
}
.drawer__body--left-open {
  box-shadow: 0 0 40px #565a5c;
  margin-left: 0;
}
.drawer__body--right-open {
  box-shadow: 0 0 40px #565a5c;
  margin-left: -100%;
}
.drawer__toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #d8d8d8;
}
.drawer__close-button {
  cursor: pointer;
}
.drawer__close-icon {
  display: block;
  color: #28283c;
  font-size: 42px;
}
.drawer__close-icon path {
  stroke: #28283c;
}
.drawer__content {
  padding-top: 69px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
