.menu-nav-search-input {
  position: relative;
  height: 44px;
  width: 44px;
  transition: all 250ms;
}
@media (min-width: 576px) {
  .menu-nav-search-input {
    width: 100%;
  }
}
.menu-nav-search-input--open {
  width: 100%;
}
.menu-nav-search-input--open .menu-nav-search-input__icon {
  height: 18px;
  top: 13px;
  left: 13px;
  cursor: default;
  fill: #28283c;
}
.menu-nav-search-input--open .menu-nav-search-input__icon:hover {
  fill: #28283c;
}
.menu-nav-search-input--open .menu-nav-search-input__input-text {
  opacity: 1;
  padding: 12px 20px 12px 44px;
}
.menu-nav-search-input__icon {
  position: absolute;
  left: 9px;
  top: 9px;
  height: 26px;
  cursor: pointer;
  transition: all 250ms;
}
@media (min-width: 576px) {
  .menu-nav-search-input__icon {
    height: 18px;
    top: 13px;
    left: 13px;
    cursor: default;
    fill: #28283c;
  }
  .menu-nav-search-input__icon:hover {
    fill: #28283c;
  }
}
.menu-nav-search-input__icon--color-camelot {
  fill: #822d50;
}
.menu-nav-search-input__icon--color-emerald {
  fill: #32c864;
}
.menu-nav-search-input__icon--color-sun {
  fill: #faaa1e;
}
.menu-nav-search-input__icon--color-robbinsEggBlue {
  fill: #00d2c8;
}
.menu-nav-search-input__icon--color-ebonyClay {
  fill: #28283c;
}
.menu-nav-search-input__icon--color-azalea {
  fill: #fadcdc;
}
.menu-nav-search-input__icon--color-cruise {
  fill: #bff0db;
}
.menu-nav-search-input__icon--color-astra {
  fill: #fafabe;
}
.menu-nav-search-input__icon--color-gallery {
  fill: #f0f0f0;
}
.menu-nav-search-input__icon--color-humming-bird {
  fill: #d7f0fa;
}
.menu-nav-search-input__icon--color-humming-bird-faded {
  fill: #f0fafe;
}
.menu-nav-search-input__icon--color-silver-chalice {
  fill: #a6a6a6;
}
.menu-nav-search-input__icon--color-catskill-white {
  fill: #e1e9f0;
}
.menu-nav-search-input__icon--color-bali-hai {
  fill: #8298ab;
}
.menu-nav-search-input__icon--color-french-lilac {
  fill: #efe0f6;
}
.menu-nav-search-input__icon--color-deep-blush {
  fill: #e07688;
}
.menu-nav-search-input__icon--color-pink {
  fill: #fbdad8;
}
.menu-nav-search-input__icon--color-gothic {
  fill: #6991a1;
}
.menu-nav-search-input__icon--color-polar-blue {
  fill: #f0f9fc;
}
.menu-nav-search-input__icon--color-prussian {
  fill: #304659;
}
.menu-nav-search-input__icon--color-sky {
  fill: #e1e9f0;
}
.menu-nav-search-input__icon--color-tulip-tree {
  fill: #f0ac44;
}
.menu-nav-search-input__icon--color-emeraldish {
  fill: #03c2b9;
}
.menu-nav-search-input__icon--color-jumbo {
  fill: #7b7b86;
}
.menu-nav-search-input__icon--color-pampas {
  fill: #f8f7f5;
}
.menu-nav-search-input__icon--color-dark-gallery {
  fill: #eaeaea;
}
.menu-nav-search-input__icon--color-concrete {
  fill: #f2f2f2;
}
.menu-nav-search-input__icon--color-sand {
  fill: #fbfbfb;
}
.menu-nav-search-input__icon--color-black-squeeze {
  fill: #f9fcfd;
}
.menu-nav-search-input__icon--color-athens-gray {
  fill: #f4f4f4;
}
.menu-nav-search-input__icon--color-alto {
  fill: #d8d8d8;
}
.menu-nav-search-input__icon--color-gray {
  fill: #888;
}
.menu-nav-search-input__icon--color-venus {
  fill: #7e7e8a;
}
.menu-nav-search-input__icon--color-dolphin {
  fill: #6b6a77;
}
.menu-nav-search-input__icon--color-mercury {
  fill: #e6e6e6;
}
.menu-nav-search-input__icon--color-text-black {
  fill: #28283c;
}
.menu-nav-search-input__icon--color-polar {
  fill: #eafbfa;
}
.menu-nav-search-input__icon--color-tundora {
  fill: #484444;
}
.menu-nav-search-input__icon--color-jet {
  fill: #3e3e50;
}
.menu-nav-search-input__icon--color-silver {
  fill: #bdbdbd;
}
.menu-nav-search-input__icon--color-smoke {
  fill: #bfbfbf;
}
.menu-nav-search-input__icon--color-white {
  fill: #fff;
}
.menu-nav-search-input__icon--color-malachite {
  fill: #00c040;
}
.menu-nav-search-input__icon--color-light-green {
  fill: #c0f0dd;
}
.menu-nav-search-input__icon--color-coral {
  fill: #ff774d;
}
.menu-nav-search-input__icon--color-bright-sun {
  fill: #ffcc43;
}
.menu-nav-search-input__icon--color-shark {
  fill: #272a2d;
}
.menu-nav-search-input__icon--color-royal-blue {
  fill: #4945e2;
}
.menu-nav-search-input__icon--color-aquamarine-blue {
  fill: #7ce5df;
}
.menu-nav-search-input__icon--color-scorpion {
  fill: #595959;
}
.menu-nav-search-input__icon--color-heather {
  fill: #b8c5d0;
}
.menu-nav-search-input__icon--color-red {
  fill: #de2828;
}
.menu-nav-search-input__icon--color-lighter-teal {
  fill: #d6f6f3;
}
.menu-nav-search-input__icon--color-blue-lagoon {
  fill: #007986;
}
.menu-nav-search-input__icon--color-brightTeal {
  fill: #00d8c4;
}
.menu-nav-search-input__icon--hover-color-camelot:hover {
  fill: #822d50;
}
.menu-nav-search-input__icon--hover-color-emerald:hover {
  fill: #32c864;
}
.menu-nav-search-input__icon--hover-color-sun:hover {
  fill: #faaa1e;
}
.menu-nav-search-input__icon--hover-color-robbinsEggBlue:hover {
  fill: #00d2c8;
}
.menu-nav-search-input__icon--hover-color-ebonyClay:hover {
  fill: #28283c;
}
.menu-nav-search-input__icon--hover-color-azalea:hover {
  fill: #fadcdc;
}
.menu-nav-search-input__icon--hover-color-cruise:hover {
  fill: #bff0db;
}
.menu-nav-search-input__icon--hover-color-astra:hover {
  fill: #fafabe;
}
.menu-nav-search-input__icon--hover-color-gallery:hover {
  fill: #f0f0f0;
}
.menu-nav-search-input__icon--hover-color-humming-bird:hover {
  fill: #d7f0fa;
}
.menu-nav-search-input__icon--hover-color-humming-bird-faded:hover {
  fill: #f0fafe;
}
.menu-nav-search-input__icon--hover-color-silver-chalice:hover {
  fill: #a6a6a6;
}
.menu-nav-search-input__icon--hover-color-catskill-white:hover {
  fill: #e1e9f0;
}
.menu-nav-search-input__icon--hover-color-bali-hai:hover {
  fill: #8298ab;
}
.menu-nav-search-input__icon--hover-color-french-lilac:hover {
  fill: #efe0f6;
}
.menu-nav-search-input__icon--hover-color-deep-blush:hover {
  fill: #e07688;
}
.menu-nav-search-input__icon--hover-color-pink:hover {
  fill: #fbdad8;
}
.menu-nav-search-input__icon--hover-color-gothic:hover {
  fill: #6991a1;
}
.menu-nav-search-input__icon--hover-color-polar-blue:hover {
  fill: #f0f9fc;
}
.menu-nav-search-input__icon--hover-color-prussian:hover {
  fill: #304659;
}
.menu-nav-search-input__icon--hover-color-sky:hover {
  fill: #e1e9f0;
}
.menu-nav-search-input__icon--hover-color-tulip-tree:hover {
  fill: #f0ac44;
}
.menu-nav-search-input__icon--hover-color-emeraldish:hover {
  fill: #03c2b9;
}
.menu-nav-search-input__icon--hover-color-jumbo:hover {
  fill: #7b7b86;
}
.menu-nav-search-input__icon--hover-color-pampas:hover {
  fill: #f8f7f5;
}
.menu-nav-search-input__icon--hover-color-dark-gallery:hover {
  fill: #eaeaea;
}
.menu-nav-search-input__icon--hover-color-concrete:hover {
  fill: #f2f2f2;
}
.menu-nav-search-input__icon--hover-color-sand:hover {
  fill: #fbfbfb;
}
.menu-nav-search-input__icon--hover-color-black-squeeze:hover {
  fill: #f9fcfd;
}
.menu-nav-search-input__icon--hover-color-athens-gray:hover {
  fill: #f4f4f4;
}
.menu-nav-search-input__icon--hover-color-alto:hover {
  fill: #d8d8d8;
}
.menu-nav-search-input__icon--hover-color-gray:hover {
  fill: #888;
}
.menu-nav-search-input__icon--hover-color-venus:hover {
  fill: #7e7e8a;
}
.menu-nav-search-input__icon--hover-color-dolphin:hover {
  fill: #6b6a77;
}
.menu-nav-search-input__icon--hover-color-mercury:hover {
  fill: #e6e6e6;
}
.menu-nav-search-input__icon--hover-color-text-black:hover {
  fill: #28283c;
}
.menu-nav-search-input__icon--hover-color-polar:hover {
  fill: #eafbfa;
}
.menu-nav-search-input__icon--hover-color-tundora:hover {
  fill: #484444;
}
.menu-nav-search-input__icon--hover-color-jet:hover {
  fill: #3e3e50;
}
.menu-nav-search-input__icon--hover-color-silver:hover {
  fill: #bdbdbd;
}
.menu-nav-search-input__icon--hover-color-smoke:hover {
  fill: #bfbfbf;
}
.menu-nav-search-input__icon--hover-color-white:hover {
  fill: #fff;
}
.menu-nav-search-input__icon--hover-color-malachite:hover {
  fill: #00c040;
}
.menu-nav-search-input__icon--hover-color-light-green:hover {
  fill: #c0f0dd;
}
.menu-nav-search-input__icon--hover-color-coral:hover {
  fill: #ff774d;
}
.menu-nav-search-input__icon--hover-color-bright-sun:hover {
  fill: #ffcc43;
}
.menu-nav-search-input__icon--hover-color-shark:hover {
  fill: #272a2d;
}
.menu-nav-search-input__icon--hover-color-royal-blue:hover {
  fill: #4945e2;
}
.menu-nav-search-input__icon--hover-color-aquamarine-blue:hover {
  fill: #7ce5df;
}
.menu-nav-search-input__icon--hover-color-scorpion:hover {
  fill: #595959;
}
.menu-nav-search-input__icon--hover-color-heather:hover {
  fill: #b8c5d0;
}
.menu-nav-search-input__icon--hover-color-red:hover {
  fill: #de2828;
}
.menu-nav-search-input__icon--hover-color-lighter-teal:hover {
  fill: #d6f6f3;
}
.menu-nav-search-input__icon--hover-color-blue-lagoon:hover {
  fill: #007986;
}
.menu-nav-search-input__icon--hover-color-brightTeal:hover {
  fill: #00d8c4;
}
.menu-nav-search-input__input-text {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  padding: 12px 12px 12px 12px;
  outline: none;
  color: #28283c;
  letter-spacing: 0.3px;
  border: 1px solid #f8f7f5;
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 250ms;
  opacity: 0;
}
@media (min-width: 576px) {
  .menu-nav-search-input__input-text {
    padding: 12px 20px 12px 44px;
    opacity: 1;
  }
}
.menu-nav-search-input__input-text::placeholder {
  color: #888;
}
