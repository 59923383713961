.menu-nav-mobile {
  width: 100%;
  padding-bottom: 100px;
}
@media (min-width: 576px) {
  .menu-nav-mobile {
    width: 320px;
  }
}
.menu-nav-mobile__items {
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu-nav-mobile__item {
  border-bottom: 1px solid #d8d8d8;
}
.menu-nav-mobile__avatar {
  width: 36px;
  border-radius: 50%;
  margin: -11px 10px;
}
.menu-nav-mobile__footer {
  display: block;
}
.menu-nav-mobile__footer-link:link,
.menu-nav-mobile__footer-link:visited,
.menu-nav-mobile__footer-link:hover,
.menu-nav-mobile__footer-link:active {
  line-height: 150%;
  color: #28283c;
  letter-spacing: 0.01em;
  font-size: 18px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  text-decoration: none;
}
.menu-nav-mobile__footer-link + .menu-nav-mobile__footer-link::before {
  width: 8px;
  height: 8px;
  margin: 0 18px;
  content: "";
  border-radius: 50%;
  display: inline-block;
  background-color: #c4c4c4;
}
