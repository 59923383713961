.mobile-class-list {
  padding: 0;
  list-style: none;
}
.mobile-class-list__item + .mobile-class-list__item {
  margin-top: 14px;
}
.mobile-class-list__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 80%;
  margin-bottom: 23px;
  -webkit-font-smoothing: antialiased;
}
.mobile-class-list__view-more {
  color: #00d2c8;
  text-decoration: none;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 80%;
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 3px;
  cursor: pointer;
}
