.tag-filter-button {
  background-color: white;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 15px 0 15px;
}
.tag-filter-button__label {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #28283c;
}
.tag-filter-button__icon {
  margin-left: 15px;
  cursor: pointer;
}
.tag-filter-button__icon path {
  stroke: #c4c4c4;
}
