.get-ready-tab__speech-bubble-wrapper {
  padding-bottom: 50px;
}
.get-ready-tab__bullet-points-wrapper {
  padding-bottom: 60px;
}
@media (min-width: 576px) {
  .get-ready-tab__bullet-points-wrapper {
    padding-bottom: 70px;
  }
}
.get-ready-tab__single-chat-wrapper {
  padding-bottom: 20px;
}
.get-ready-tab__list {
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  .get-ready-tab__list {
    margin-bottom: 40px;
  }
}
.get-ready-tab__list:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .get-ready-tab__list {
    margin-bottom: 0;
  }
}
.get-ready-tab__create-account {
  width: 200px;
  padding-bottom: 36px;
}
.get-ready-tab__make-account-wrapper {
  display: flex;
  flex-direction: column;
}
.get-ready-tab__make-account-title {
  font-size: 28px;
  line-height: 36px;
  color: #28283c;
  padding-bottom: 17px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
}
.get-ready-tab__make-account-description {
  font-size: 18px;
  line-height: 134%;
  color: #28283c;
  letter-spacing: 0.01em;
  padding-bottom: 25px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
