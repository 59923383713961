.sticky-cta {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 500;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
}
.sticky-cta__container {
  padding: 0 0 0;
  max-width: 1150px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
}
@media (max-width: 1280px) {
  .sticky-cta__container {
    padding: 0 70px 0;
  }
}
@media (max-width: 1024px) {
  .sticky-cta__container {
    padding: 0 40px 0;
  }
}
@media (max-width: 834px) {
  .sticky-cta__container {
    padding: 0 60px 0;
  }
}
@media (max-width: 576px) {
  .sticky-cta__container {
    padding: 0 14px 0;
    justify-content: center;
  }
}
@media (min-width: 577px) {
  .sticky-cta__container {
    justify-content: space-between;
  }
}
.sticky-cta__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #28283c;
  display: none;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 577px) {
  .sticky-cta__title {
    display: block;
  }
}
@media (min-width: 835px) {
  .sticky-cta__title {
    font-size: 18px;
  }
}
.sticky-cta__cta:link,
.sticky-cta__cta:hover {
  text-decoration: none;
}
