.review-card__name {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #28283c;
}
.review-card__date {
  color: #94949e;
}
.review-card__rating {
  margin-top: 10px;
}
.review-card__text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #28283c;
  margin-top: 20px;
}
