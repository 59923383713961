.customer-gift-card-card {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
}
.customer-gift-card-card__type-tag {
  display: inline-block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1;
  color: #28283c;
  text-transform: uppercase;
  padding: 5px 5px;
  background-color: #fadcdc;
  border-radius: 2px;
  align-self: flex-start;
  text-align: center;
  width: auto;
}
.customer-gift-card-card__class {
  margin: 0 0 10px;
  font-size: 21px;
  color: #28283c;
}
.customer-gift-card-card__details {
  display: flex;
}
.customer-gift-card-card__price {
  flex: 1;
  text-align: right;
}
.customer-gift-card-card__datetime {
  display: inline-block;
  color: #6b6a77;
  margin-right: 15px;
}
.customer-gift-card-card__icon {
  vertical-align: middle;
  margin-top: -4px;
}
.customer-gift-card-card__icon--action {
  fill: #28283c;
  margin-right: 10px;
}
.customer-gift-card-card__icon--general {
  margin-right: 4px;
}
.customer-gift-card-card__icon--general-fill {
  fill: #c5c5c5;
}
.customer-gift-card-card__icon--general-stroke {
  stroke: #c5c5c5;
}
.customer-gift-card-card__row {
  color: #28283c;
  padding: 20px;
  font-size: 18px;
  border-bottom: solid 1px #e6e6e6;
}
.customer-gift-card-card__row:last-child {
  border-bottom: none;
}
.customer-gift-card-card__row--flex {
  display: flex;
  justify-content: space-between;
}
.customer-gift-card-card__row--wrap {
  flex-wrap: wrap;
}
.customer-gift-card-card__row--center {
  text-align: center;
}
.customer-gift-card-card__row--filled {
  background-color: #f9f9f9;
}
.customer-gift-card-card__customer-name {
  flex-basis: 100%;
}
.customer-gift-card-card__address {
  flex-basis: 100%;
}
.customer-gift-card-card__cancelled {
  color: #888;
}
.customer-gift-card-card__action {
  cursor: pointer;
}
.customer-gift-card-card__input-label {
  font-weight: 500;
  font-size: 16px;
}
