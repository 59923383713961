.location-filter-body {
  display: flex;
  flex-direction: column;
}
.location-filter-body__input {
  position: relative;
}
.location-filter-body__input .loading-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.location-filter-body__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px -30px 0;
  border-top: 1px solid rgba(40, 40, 60, 0.1);
}
@media (min-width: 576px) {
  .location-filter-body__controls {
    border-top: none;
    margin: 0;
  }
}
.location-filter-body__clear-button {
  font-size: 14px;
  font-weight: 500;
  color: #c4c4c4;
  text-transform: uppercase;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  width: auto;
  cursor: pointer;
}
.location-filter-body__apply-button--mobile {
  width: 120px;
}
