.motivation-points__point-title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  color: #28283c;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 576px) {
  .motivation-points__point-title {
    font-size: 20px;
  }
}
.motivation-points__point-divider {
  width: 60px;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #28283c;
}
.motivation-points__point-divider--color-camelot {
  background-color: #822d50;
}
.motivation-points__point-divider--color-emerald {
  background-color: #32c864;
}
.motivation-points__point-divider--color-sun {
  background-color: #faaa1e;
}
.motivation-points__point-divider--color-robbinsEggBlue {
  background-color: #00d2c8;
}
.motivation-points__point-divider--color-ebonyClay {
  background-color: #28283c;
}
.motivation-points__point-divider--color-azalea {
  background-color: #fadcdc;
}
.motivation-points__point-divider--color-cruise {
  background-color: #bff0db;
}
.motivation-points__point-divider--color-astra {
  background-color: #fafabe;
}
.motivation-points__point-divider--color-gallery {
  background-color: #f0f0f0;
}
.motivation-points__point-divider--color-humming-bird {
  background-color: #d7f0fa;
}
.motivation-points__point-divider--color-humming-bird-faded {
  background-color: #f0fafe;
}
.motivation-points__point-divider--color-silver-chalice {
  background-color: #a6a6a6;
}
.motivation-points__point-divider--color-catskill-white {
  background-color: #e1e9f0;
}
.motivation-points__point-divider--color-bali-hai {
  background-color: #8298ab;
}
.motivation-points__point-divider--color-french-lilac {
  background-color: #efe0f6;
}
.motivation-points__point-divider--color-deep-blush {
  background-color: #e07688;
}
.motivation-points__point-divider--color-pink {
  background-color: #fbdad8;
}
.motivation-points__point-divider--color-gothic {
  background-color: #6991a1;
}
.motivation-points__point-divider--color-polar-blue {
  background-color: #f0f9fc;
}
.motivation-points__point-divider--color-prussian {
  background-color: #304659;
}
.motivation-points__point-divider--color-sky {
  background-color: #e1e9f0;
}
.motivation-points__point-divider--color-tulip-tree {
  background-color: #f0ac44;
}
.motivation-points__point-divider--color-emeraldish {
  background-color: #03c2b9;
}
.motivation-points__point-divider--color-jumbo {
  background-color: #7b7b86;
}
.motivation-points__point-divider--color-pampas {
  background-color: #f8f7f5;
}
.motivation-points__point-divider--color-dark-gallery {
  background-color: #eaeaea;
}
.motivation-points__point-divider--color-concrete {
  background-color: #f2f2f2;
}
.motivation-points__point-divider--color-sand {
  background-color: #fbfbfb;
}
.motivation-points__point-divider--color-black-squeeze {
  background-color: #f9fcfd;
}
.motivation-points__point-divider--color-athens-gray {
  background-color: #f4f4f4;
}
.motivation-points__point-divider--color-alto {
  background-color: #d8d8d8;
}
.motivation-points__point-divider--color-gray {
  background-color: #888;
}
.motivation-points__point-divider--color-venus {
  background-color: #7e7e8a;
}
.motivation-points__point-divider--color-dolphin {
  background-color: #6b6a77;
}
.motivation-points__point-divider--color-mercury {
  background-color: #e6e6e6;
}
.motivation-points__point-divider--color-text-black {
  background-color: #28283c;
}
.motivation-points__point-divider--color-polar {
  background-color: #eafbfa;
}
.motivation-points__point-divider--color-tundora {
  background-color: #484444;
}
.motivation-points__point-divider--color-jet {
  background-color: #3e3e50;
}
.motivation-points__point-divider--color-silver {
  background-color: #bdbdbd;
}
.motivation-points__point-divider--color-smoke {
  background-color: #bfbfbf;
}
.motivation-points__point-divider--color-white {
  background-color: #fff;
}
.motivation-points__point-divider--color-malachite {
  background-color: #00c040;
}
.motivation-points__point-divider--color-light-green {
  background-color: #c0f0dd;
}
.motivation-points__point-divider--color-coral {
  background-color: #ff774d;
}
.motivation-points__point-divider--color-bright-sun {
  background-color: #ffcc43;
}
.motivation-points__point-divider--color-shark {
  background-color: #272a2d;
}
.motivation-points__point-divider--color-royal-blue {
  background-color: #4945e2;
}
.motivation-points__point-divider--color-aquamarine-blue {
  background-color: #7ce5df;
}
.motivation-points__point-divider--color-scorpion {
  background-color: #595959;
}
.motivation-points__point-divider--color-heather {
  background-color: #b8c5d0;
}
.motivation-points__point-divider--color-red {
  background-color: #de2828;
}
.motivation-points__point-divider--color-lighter-teal {
  background-color: #d6f6f3;
}
.motivation-points__point-divider--color-blue-lagoon {
  background-color: #007986;
}
.motivation-points__point-divider--color-brightTeal {
  background-color: #00d8c4;
}
.motivation-points__point-description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #28283c;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 1024px) {
  .motivation-points__point-description {
    font-size: 18px;
  }
}
.motivation-points__point-description-link {
  color: #00d2c8;
  text-decoration: underline;
}
.motivation-points__point-description-link:hover {
  color: #00d2c8;
  text-decoration: underline;
}
.motivation-points__point-description-link--color-camelot {
  color: #822d50;
}
.motivation-points__point-description-link--color-camelot:hover {
  color: #822d50;
}
.motivation-points__point-description-link--color-emerald {
  color: #32c864;
}
.motivation-points__point-description-link--color-emerald:hover {
  color: #32c864;
}
.motivation-points__point-description-link--color-sun {
  color: #faaa1e;
}
.motivation-points__point-description-link--color-sun:hover {
  color: #faaa1e;
}
.motivation-points__point-description-link--color-robbinsEggBlue {
  color: #00d2c8;
}
.motivation-points__point-description-link--color-robbinsEggBlue:hover {
  color: #00d2c8;
}
.motivation-points__point-description-link--color-ebonyClay {
  color: #28283c;
}
.motivation-points__point-description-link--color-ebonyClay:hover {
  color: #28283c;
}
.motivation-points__point-description-link--color-azalea {
  color: #fadcdc;
}
.motivation-points__point-description-link--color-azalea:hover {
  color: #fadcdc;
}
.motivation-points__point-description-link--color-cruise {
  color: #bff0db;
}
.motivation-points__point-description-link--color-cruise:hover {
  color: #bff0db;
}
.motivation-points__point-description-link--color-astra {
  color: #fafabe;
}
.motivation-points__point-description-link--color-astra:hover {
  color: #fafabe;
}
.motivation-points__point-description-link--color-gallery {
  color: #f0f0f0;
}
.motivation-points__point-description-link--color-gallery:hover {
  color: #f0f0f0;
}
.motivation-points__point-description-link--color-humming-bird {
  color: #d7f0fa;
}
.motivation-points__point-description-link--color-humming-bird:hover {
  color: #d7f0fa;
}
.motivation-points__point-description-link--color-humming-bird-faded {
  color: #f0fafe;
}
.motivation-points__point-description-link--color-humming-bird-faded:hover {
  color: #f0fafe;
}
.motivation-points__point-description-link--color-silver-chalice {
  color: #a6a6a6;
}
.motivation-points__point-description-link--color-silver-chalice:hover {
  color: #a6a6a6;
}
.motivation-points__point-description-link--color-catskill-white {
  color: #e1e9f0;
}
.motivation-points__point-description-link--color-catskill-white:hover {
  color: #e1e9f0;
}
.motivation-points__point-description-link--color-bali-hai {
  color: #8298ab;
}
.motivation-points__point-description-link--color-bali-hai:hover {
  color: #8298ab;
}
.motivation-points__point-description-link--color-french-lilac {
  color: #efe0f6;
}
.motivation-points__point-description-link--color-french-lilac:hover {
  color: #efe0f6;
}
.motivation-points__point-description-link--color-deep-blush {
  color: #e07688;
}
.motivation-points__point-description-link--color-deep-blush:hover {
  color: #e07688;
}
.motivation-points__point-description-link--color-pink {
  color: #fbdad8;
}
.motivation-points__point-description-link--color-pink:hover {
  color: #fbdad8;
}
.motivation-points__point-description-link--color-gothic {
  color: #6991a1;
}
.motivation-points__point-description-link--color-gothic:hover {
  color: #6991a1;
}
.motivation-points__point-description-link--color-polar-blue {
  color: #f0f9fc;
}
.motivation-points__point-description-link--color-polar-blue:hover {
  color: #f0f9fc;
}
.motivation-points__point-description-link--color-prussian {
  color: #304659;
}
.motivation-points__point-description-link--color-prussian:hover {
  color: #304659;
}
.motivation-points__point-description-link--color-sky {
  color: #e1e9f0;
}
.motivation-points__point-description-link--color-sky:hover {
  color: #e1e9f0;
}
.motivation-points__point-description-link--color-tulip-tree {
  color: #f0ac44;
}
.motivation-points__point-description-link--color-tulip-tree:hover {
  color: #f0ac44;
}
.motivation-points__point-description-link--color-emeraldish {
  color: #03c2b9;
}
.motivation-points__point-description-link--color-emeraldish:hover {
  color: #03c2b9;
}
.motivation-points__point-description-link--color-jumbo {
  color: #7b7b86;
}
.motivation-points__point-description-link--color-jumbo:hover {
  color: #7b7b86;
}
.motivation-points__point-description-link--color-pampas {
  color: #f8f7f5;
}
.motivation-points__point-description-link--color-pampas:hover {
  color: #f8f7f5;
}
.motivation-points__point-description-link--color-dark-gallery {
  color: #eaeaea;
}
.motivation-points__point-description-link--color-dark-gallery:hover {
  color: #eaeaea;
}
.motivation-points__point-description-link--color-concrete {
  color: #f2f2f2;
}
.motivation-points__point-description-link--color-concrete:hover {
  color: #f2f2f2;
}
.motivation-points__point-description-link--color-sand {
  color: #fbfbfb;
}
.motivation-points__point-description-link--color-sand:hover {
  color: #fbfbfb;
}
.motivation-points__point-description-link--color-black-squeeze {
  color: #f9fcfd;
}
.motivation-points__point-description-link--color-black-squeeze:hover {
  color: #f9fcfd;
}
.motivation-points__point-description-link--color-athens-gray {
  color: #f4f4f4;
}
.motivation-points__point-description-link--color-athens-gray:hover {
  color: #f4f4f4;
}
.motivation-points__point-description-link--color-alto {
  color: #d8d8d8;
}
.motivation-points__point-description-link--color-alto:hover {
  color: #d8d8d8;
}
.motivation-points__point-description-link--color-gray {
  color: #888;
}
.motivation-points__point-description-link--color-gray:hover {
  color: #888;
}
.motivation-points__point-description-link--color-venus {
  color: #7e7e8a;
}
.motivation-points__point-description-link--color-venus:hover {
  color: #7e7e8a;
}
.motivation-points__point-description-link--color-dolphin {
  color: #6b6a77;
}
.motivation-points__point-description-link--color-dolphin:hover {
  color: #6b6a77;
}
.motivation-points__point-description-link--color-mercury {
  color: #e6e6e6;
}
.motivation-points__point-description-link--color-mercury:hover {
  color: #e6e6e6;
}
.motivation-points__point-description-link--color-text-black {
  color: #28283c;
}
.motivation-points__point-description-link--color-text-black:hover {
  color: #28283c;
}
.motivation-points__point-description-link--color-polar {
  color: #eafbfa;
}
.motivation-points__point-description-link--color-polar:hover {
  color: #eafbfa;
}
.motivation-points__point-description-link--color-tundora {
  color: #484444;
}
.motivation-points__point-description-link--color-tundora:hover {
  color: #484444;
}
.motivation-points__point-description-link--color-jet {
  color: #3e3e50;
}
.motivation-points__point-description-link--color-jet:hover {
  color: #3e3e50;
}
.motivation-points__point-description-link--color-silver {
  color: #bdbdbd;
}
.motivation-points__point-description-link--color-silver:hover {
  color: #bdbdbd;
}
.motivation-points__point-description-link--color-smoke {
  color: #bfbfbf;
}
.motivation-points__point-description-link--color-smoke:hover {
  color: #bfbfbf;
}
.motivation-points__point-description-link--color-white {
  color: #fff;
}
.motivation-points__point-description-link--color-white:hover {
  color: #fff;
}
.motivation-points__point-description-link--color-malachite {
  color: #00c040;
}
.motivation-points__point-description-link--color-malachite:hover {
  color: #00c040;
}
.motivation-points__point-description-link--color-light-green {
  color: #c0f0dd;
}
.motivation-points__point-description-link--color-light-green:hover {
  color: #c0f0dd;
}
.motivation-points__point-description-link--color-coral {
  color: #ff774d;
}
.motivation-points__point-description-link--color-coral:hover {
  color: #ff774d;
}
.motivation-points__point-description-link--color-bright-sun {
  color: #ffcc43;
}
.motivation-points__point-description-link--color-bright-sun:hover {
  color: #ffcc43;
}
.motivation-points__point-description-link--color-shark {
  color: #272a2d;
}
.motivation-points__point-description-link--color-shark:hover {
  color: #272a2d;
}
.motivation-points__point-description-link--color-royal-blue {
  color: #4945e2;
}
.motivation-points__point-description-link--color-royal-blue:hover {
  color: #4945e2;
}
.motivation-points__point-description-link--color-aquamarine-blue {
  color: #7ce5df;
}
.motivation-points__point-description-link--color-aquamarine-blue:hover {
  color: #7ce5df;
}
.motivation-points__point-description-link--color-scorpion {
  color: #595959;
}
.motivation-points__point-description-link--color-scorpion:hover {
  color: #595959;
}
.motivation-points__point-description-link--color-heather {
  color: #b8c5d0;
}
.motivation-points__point-description-link--color-heather:hover {
  color: #b8c5d0;
}
.motivation-points__point-description-link--color-red {
  color: #de2828;
}
.motivation-points__point-description-link--color-red:hover {
  color: #de2828;
}
.motivation-points__point-description-link--color-lighter-teal {
  color: #d6f6f3;
}
.motivation-points__point-description-link--color-lighter-teal:hover {
  color: #d6f6f3;
}
.motivation-points__point-description-link--color-blue-lagoon {
  color: #007986;
}
.motivation-points__point-description-link--color-blue-lagoon:hover {
  color: #007986;
}
.motivation-points__point-description-link--color-brightTeal {
  color: #00d8c4;
}
.motivation-points__point-description-link--color-brightTeal:hover {
  color: #00d8c4;
}
