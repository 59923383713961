.image-blurb__content {
  color: #28283c;
}
.image-blurb__content--color-camelot {
  color: #822d50;
}
.image-blurb__content--color-emerald {
  color: #32c864;
}
.image-blurb__content--color-sun {
  color: #faaa1e;
}
.image-blurb__content--color-robbinsEggBlue {
  color: #00d2c8;
}
.image-blurb__content--color-ebonyClay {
  color: #28283c;
}
.image-blurb__content--color-azalea {
  color: #fadcdc;
}
.image-blurb__content--color-cruise {
  color: #bff0db;
}
.image-blurb__content--color-astra {
  color: #fafabe;
}
.image-blurb__content--color-gallery {
  color: #f0f0f0;
}
.image-blurb__content--color-humming-bird {
  color: #d7f0fa;
}
.image-blurb__content--color-humming-bird-faded {
  color: #f0fafe;
}
.image-blurb__content--color-silver-chalice {
  color: #a6a6a6;
}
.image-blurb__content--color-catskill-white {
  color: #e1e9f0;
}
.image-blurb__content--color-bali-hai {
  color: #8298ab;
}
.image-blurb__content--color-french-lilac {
  color: #efe0f6;
}
.image-blurb__content--color-deep-blush {
  color: #e07688;
}
.image-blurb__content--color-pink {
  color: #fbdad8;
}
.image-blurb__content--color-gothic {
  color: #6991a1;
}
.image-blurb__content--color-polar-blue {
  color: #f0f9fc;
}
.image-blurb__content--color-prussian {
  color: #304659;
}
.image-blurb__content--color-sky {
  color: #e1e9f0;
}
.image-blurb__content--color-tulip-tree {
  color: #f0ac44;
}
.image-blurb__content--color-emeraldish {
  color: #03c2b9;
}
.image-blurb__content--color-jumbo {
  color: #7b7b86;
}
.image-blurb__content--color-pampas {
  color: #f8f7f5;
}
.image-blurb__content--color-dark-gallery {
  color: #eaeaea;
}
.image-blurb__content--color-concrete {
  color: #f2f2f2;
}
.image-blurb__content--color-sand {
  color: #fbfbfb;
}
.image-blurb__content--color-black-squeeze {
  color: #f9fcfd;
}
.image-blurb__content--color-athens-gray {
  color: #f4f4f4;
}
.image-blurb__content--color-alto {
  color: #d8d8d8;
}
.image-blurb__content--color-gray {
  color: #888;
}
.image-blurb__content--color-venus {
  color: #7e7e8a;
}
.image-blurb__content--color-dolphin {
  color: #6b6a77;
}
.image-blurb__content--color-mercury {
  color: #e6e6e6;
}
.image-blurb__content--color-text-black {
  color: #28283c;
}
.image-blurb__content--color-polar {
  color: #eafbfa;
}
.image-blurb__content--color-tundora {
  color: #484444;
}
.image-blurb__content--color-jet {
  color: #3e3e50;
}
.image-blurb__content--color-silver {
  color: #bdbdbd;
}
.image-blurb__content--color-smoke {
  color: #bfbfbf;
}
.image-blurb__content--color-white {
  color: #fff;
}
.image-blurb__content--color-malachite {
  color: #00c040;
}
.image-blurb__content--color-light-green {
  color: #c0f0dd;
}
.image-blurb__content--color-coral {
  color: #ff774d;
}
.image-blurb__content--color-bright-sun {
  color: #ffcc43;
}
.image-blurb__content--color-shark {
  color: #272a2d;
}
.image-blurb__content--color-royal-blue {
  color: #4945e2;
}
.image-blurb__content--color-aquamarine-blue {
  color: #7ce5df;
}
.image-blurb__content--color-scorpion {
  color: #595959;
}
.image-blurb__content--color-heather {
  color: #b8c5d0;
}
.image-blurb__content--color-red {
  color: #de2828;
}
.image-blurb__content--color-lighter-teal {
  color: #d6f6f3;
}
.image-blurb__content--color-blue-lagoon {
  color: #007986;
}
.image-blurb__content--color-brightTeal {
  color: #00d8c4;
}
.image-blurb__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 90%;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.image-blurb__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
}
.image-blurb__button {
  text-transform: uppercase;
}
