.bright-icon-pill {
  display: inline-flex;
  align-items: center;
  padding: 1px 8px;
}
.bright-icon-pill--camelot {
  background-color: #822d50;
}
.bright-icon-pill--emerald {
  background-color: #32c864;
}
.bright-icon-pill--sun {
  background-color: #faaa1e;
}
.bright-icon-pill--robbinsEggBlue {
  background-color: #00d2c8;
}
.bright-icon-pill--ebonyClay {
  background-color: #28283c;
}
.bright-icon-pill--azalea {
  background-color: #fadcdc;
}
.bright-icon-pill--cruise {
  background-color: #bff0db;
}
.bright-icon-pill--astra {
  background-color: #fafabe;
}
.bright-icon-pill--gallery {
  background-color: #f0f0f0;
}
.bright-icon-pill--humming-bird {
  background-color: #d7f0fa;
}
.bright-icon-pill--humming-bird-faded {
  background-color: #f0fafe;
}
.bright-icon-pill--silver-chalice {
  background-color: #a6a6a6;
}
.bright-icon-pill--catskill-white {
  background-color: #e1e9f0;
}
.bright-icon-pill--bali-hai {
  background-color: #8298ab;
}
.bright-icon-pill--french-lilac {
  background-color: #efe0f6;
}
.bright-icon-pill--deep-blush {
  background-color: #e07688;
}
.bright-icon-pill--pink {
  background-color: #fbdad8;
}
.bright-icon-pill--gothic {
  background-color: #6991a1;
}
.bright-icon-pill--polar-blue {
  background-color: #f0f9fc;
}
.bright-icon-pill--prussian {
  background-color: #304659;
}
.bright-icon-pill--sky {
  background-color: #e1e9f0;
}
.bright-icon-pill--tulip-tree {
  background-color: #f0ac44;
}
.bright-icon-pill--emeraldish {
  background-color: #03c2b9;
}
.bright-icon-pill--jumbo {
  background-color: #7b7b86;
}
.bright-icon-pill--pampas {
  background-color: #f8f7f5;
}
.bright-icon-pill--dark-gallery {
  background-color: #eaeaea;
}
.bright-icon-pill--concrete {
  background-color: #f2f2f2;
}
.bright-icon-pill--sand {
  background-color: #fbfbfb;
}
.bright-icon-pill--black-squeeze {
  background-color: #f9fcfd;
}
.bright-icon-pill--athens-gray {
  background-color: #f4f4f4;
}
.bright-icon-pill--alto {
  background-color: #d8d8d8;
}
.bright-icon-pill--gray {
  background-color: #888;
}
.bright-icon-pill--venus {
  background-color: #7e7e8a;
}
.bright-icon-pill--dolphin {
  background-color: #6b6a77;
}
.bright-icon-pill--mercury {
  background-color: #e6e6e6;
}
.bright-icon-pill--text-black {
  background-color: #28283c;
}
.bright-icon-pill--polar {
  background-color: #eafbfa;
}
.bright-icon-pill--tundora {
  background-color: #484444;
}
.bright-icon-pill--jet {
  background-color: #3e3e50;
}
.bright-icon-pill--silver {
  background-color: #bdbdbd;
}
.bright-icon-pill--smoke {
  background-color: #bfbfbf;
}
.bright-icon-pill--white {
  background-color: #fff;
}
.bright-icon-pill--malachite {
  background-color: #00c040;
}
.bright-icon-pill--light-green {
  background-color: #c0f0dd;
}
.bright-icon-pill--coral {
  background-color: #ff774d;
}
.bright-icon-pill--bright-sun {
  background-color: #ffcc43;
}
.bright-icon-pill--shark {
  background-color: #272a2d;
}
.bright-icon-pill--royal-blue {
  background-color: #4945e2;
}
.bright-icon-pill--aquamarine-blue {
  background-color: #7ce5df;
}
.bright-icon-pill--scorpion {
  background-color: #595959;
}
.bright-icon-pill--heather {
  background-color: #b8c5d0;
}
.bright-icon-pill--red {
  background-color: #de2828;
}
.bright-icon-pill--lighter-teal {
  background-color: #d6f6f3;
}
.bright-icon-pill--blue-lagoon {
  background-color: #007986;
}
.bright-icon-pill--brightTeal {
  background-color: #00d8c4;
}
.bright-icon-pill__icon {
  margin-right: 5px;
}
.bright-icon-pill__text {
  color: #28283c;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 1.3;
}
