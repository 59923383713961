.category-card {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
}
.category-card__thumbnail {
  display: block;
}
.category-card__thumbnail-image {
  display: block;
  width: 100%;
}
.category-card__label-link {
  display: block;
  padding: 10px;
  color: #28283c;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}
.category-card__label-link:hover,
.category-card__label-link:visited,
.category-card__label-link:active,
.category-card__label-link:focus {
  color: #28283c;
  text-decoration: none;
}
@media (min-width: 1200px) {
  .category-card__label-link {
    font-size: 18px;
    padding: 16px;
  }
}
.category-card__label {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0.02em;
}
@media (min-width: 1200px) {
  .category-card__label {
    font-weight: 500;
  }
}
