.customer-card {
  display: flex;
}
.customer-card__avatar {
  flex: 0 0 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}
@media (min-width: 768px) {
  .customer-card__avatar {
    flex: 0 0 80px;
    height: 80px;
    margin-right: 30px;
  }
}
.customer-card__info {
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.customer-card__name {
  color: #28283c;
  font-size: 24px;
  margin: 0 0 4px;
}
@media (min-width: 768px) {
  .customer-card__name {
    font-size: 36px;
  }
}
.customer-card__details {
  line-height: 1.3;
  font-size: 18px;
  color: #6b6a77;
}
@media (min-width: 768px) {
  .customer-card__details {
    font-size: 20px;
  }
}
.customer-card__details svg path {
  fill: currentColor;
}
