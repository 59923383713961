.bullet-points-input__list {
  padding: 0;
  list-style: none;
}
.bullet-points-input__list-item {
  display: flex;
  margin: 10px 0;
}
.bullet-points-input__list-item:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #28283c;
  margin-right: 25px;
  margin-top: 15px;
}
.bullet-points-input__list-item:hover .bullet-points-input__remove-item-icon {
  display: inline;
}
.bullet-points-input__list-item:hover .input-text__input {
  padding-right: 40px;
}
.bullet-points-input__list-item .input-text {
  flex-grow: 1;
}
.bullet-points-input__add-bullet-point {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #00d2c8;
}
.bullet-points-input__add-icon {
  margin-right: 10px;
}
.bullet-points-input__add-icon path {
  fill: #00d2c8;
}
.bullet-points-input__add-icon circle {
  stroke: #00d2c8;
}
.bullet-points-input__remove-item {
  position: relative;
  display: flex;
  align-items: center;
  flex-basis: 0;
}
.bullet-points-input__remove-item-icon {
  display: none;
  position: absolute;
  right: 0;
  opacity: 0.25;
  margin-right: 14px;
  cursor: pointer;
}
