.booking-receipt-card {
  padding: 30px 25px;
  border-radius: 4px;
  border-top: solid 8px;
  background-color: white;
}
.booking-receipt-card--top-border-color-camelot {
  border-color: #822d50;
}
.booking-receipt-card--top-border-color-emerald {
  border-color: #32c864;
}
.booking-receipt-card--top-border-color-sun {
  border-color: #faaa1e;
}
.booking-receipt-card--top-border-color-robbinsEggBlue {
  border-color: #00d2c8;
}
.booking-receipt-card--top-border-color-ebonyClay {
  border-color: #28283c;
}
.booking-receipt-card--top-border-color-azalea {
  border-color: #fadcdc;
}
.booking-receipt-card--top-border-color-cruise {
  border-color: #bff0db;
}
.booking-receipt-card--top-border-color-astra {
  border-color: #fafabe;
}
.booking-receipt-card--top-border-color-gallery {
  border-color: #f0f0f0;
}
.booking-receipt-card--top-border-color-humming-bird {
  border-color: #d7f0fa;
}
.booking-receipt-card--top-border-color-humming-bird-faded {
  border-color: #f0fafe;
}
.booking-receipt-card--top-border-color-silver-chalice {
  border-color: #a6a6a6;
}
.booking-receipt-card--top-border-color-catskill-white {
  border-color: #e1e9f0;
}
.booking-receipt-card--top-border-color-bali-hai {
  border-color: #8298ab;
}
.booking-receipt-card--top-border-color-french-lilac {
  border-color: #efe0f6;
}
.booking-receipt-card--top-border-color-deep-blush {
  border-color: #e07688;
}
.booking-receipt-card--top-border-color-pink {
  border-color: #fbdad8;
}
.booking-receipt-card--top-border-color-gothic {
  border-color: #6991a1;
}
.booking-receipt-card--top-border-color-polar-blue {
  border-color: #f0f9fc;
}
.booking-receipt-card--top-border-color-prussian {
  border-color: #304659;
}
.booking-receipt-card--top-border-color-sky {
  border-color: #e1e9f0;
}
.booking-receipt-card--top-border-color-tulip-tree {
  border-color: #f0ac44;
}
.booking-receipt-card--top-border-color-emeraldish {
  border-color: #03c2b9;
}
.booking-receipt-card--top-border-color-jumbo {
  border-color: #7b7b86;
}
.booking-receipt-card--top-border-color-pampas {
  border-color: #f8f7f5;
}
.booking-receipt-card--top-border-color-dark-gallery {
  border-color: #eaeaea;
}
.booking-receipt-card--top-border-color-concrete {
  border-color: #f2f2f2;
}
.booking-receipt-card--top-border-color-sand {
  border-color: #fbfbfb;
}
.booking-receipt-card--top-border-color-black-squeeze {
  border-color: #f9fcfd;
}
.booking-receipt-card--top-border-color-athens-gray {
  border-color: #f4f4f4;
}
.booking-receipt-card--top-border-color-alto {
  border-color: #d8d8d8;
}
.booking-receipt-card--top-border-color-gray {
  border-color: #888;
}
.booking-receipt-card--top-border-color-venus {
  border-color: #7e7e8a;
}
.booking-receipt-card--top-border-color-dolphin {
  border-color: #6b6a77;
}
.booking-receipt-card--top-border-color-mercury {
  border-color: #e6e6e6;
}
.booking-receipt-card--top-border-color-text-black {
  border-color: #28283c;
}
.booking-receipt-card--top-border-color-polar {
  border-color: #eafbfa;
}
.booking-receipt-card--top-border-color-tundora {
  border-color: #484444;
}
.booking-receipt-card--top-border-color-jet {
  border-color: #3e3e50;
}
.booking-receipt-card--top-border-color-silver {
  border-color: #bdbdbd;
}
.booking-receipt-card--top-border-color-smoke {
  border-color: #bfbfbf;
}
.booking-receipt-card--top-border-color-white {
  border-color: #fff;
}
.booking-receipt-card--top-border-color-malachite {
  border-color: #00c040;
}
.booking-receipt-card--top-border-color-light-green {
  border-color: #c0f0dd;
}
.booking-receipt-card--top-border-color-coral {
  border-color: #ff774d;
}
.booking-receipt-card--top-border-color-bright-sun {
  border-color: #ffcc43;
}
.booking-receipt-card--top-border-color-shark {
  border-color: #272a2d;
}
.booking-receipt-card--top-border-color-royal-blue {
  border-color: #4945e2;
}
.booking-receipt-card--top-border-color-aquamarine-blue {
  border-color: #7ce5df;
}
.booking-receipt-card--top-border-color-scorpion {
  border-color: #595959;
}
.booking-receipt-card--top-border-color-heather {
  border-color: #b8c5d0;
}
.booking-receipt-card--top-border-color-red {
  border-color: #de2828;
}
.booking-receipt-card--top-border-color-lighter-teal {
  border-color: #d6f6f3;
}
.booking-receipt-card--top-border-color-blue-lagoon {
  border-color: #007986;
}
.booking-receipt-card--top-border-color-brightTeal {
  border-color: #00d8c4;
}
.booking-receipt-card__header {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(40, 40, 60, 0.1);
}
.booking-receipt-card__title {
  font-size: 24px;
  line-height: 100%;
  font-weight: bold;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  text-transform: uppercase;
  color: #28283c;
}
.booking-receipt-card__avatar {
  display: none;
}
@media (min-width: 834px) {
  .booking-receipt-card__avatar {
    width: 124px;
    display: inline-block;
  }
}
.booking-receipt-card__details {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  border-bottom: 1px solid rgba(40, 40, 60, 0.1);
}
.booking-receipt-card__icon-label {
  padding-bottom: 10px;
}
@media (min-width: 576px) {
  .booking-receipt-card__icon-label {
    padding-bottom: 20px;
  }
}
.booking-receipt-card__join-class {
  padding-top: 20px;
}
.booking-receipt-card__tips {
  padding-top: 20px;
}
.booking-receipt-card__label,
.booking-receipt-card__link {
  color: #888;
  line-height: 134%;
  letter-spacing: 0.04em;
  font-size: 14px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.booking-receipt-card__link {
  color: #888 !important;
  text-decoration: underline !important;
  text-decoration-color: #00d2c8 !important;
}
.booking-receipt-card__link:active,
.booking-receipt-card__link:hover,
.booking-receipt-card__link:focus,
.booking-receipt-card__link:visited {
  color: #888 !important;
  text-decoration: underline !important;
  text-decoration-color: #00d2c8 !important;
}
