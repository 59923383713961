.tips-item {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #28283c;
  margin-left: 16px;
}
.tips-item::before {
  content: "\2022";
  color: #00d2c8;
  font-weight: bold;
  font-size: 24px;
  display: inline-block;
  width: 16px;
  margin-left: -16px;
}
