.image-aligned-banner {
  display: flex;
  padding: 40px 0;
}
@media (max-width: 576px) {
  .image-aligned-banner {
    flex-direction: column;
  }
}
.image-aligned-banner--right {
  flex-direction: row-reverse;
}
@media (max-width: 576px) {
  .image-aligned-banner--right {
    flex-direction: column;
  }
}
.image-aligned-banner__aside {
  flex: 1;
  display: flex;
  padding: 0 30px 0 0;
}
@media (max-width: 576px) {
  .image-aligned-banner__aside {
    margin-bottom: 58px;
    padding: 0;
  }
}
.image-aligned-banner__aside-content {
  display: inline-block;
  position: relative;
  align-self: center;
}
.image-aligned-banner__main {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #28283c;
  padding: 0 0 0 30px;
}
@media (max-width: 576px) {
  .image-aligned-banner__main {
    padding: 0;
  }
}
.image-aligned-banner__image-container {
  display: flex;
  align-items: flex-start;
  max-height: 459px;
  max-width: 484px;
  overflow: hidden;
}
.image-aligned-banner__image {
  display: block;
  width: 100%;
}
.image-aligned-banner--right .image-aligned-banner__aside {
  padding: 0 0 0 30px;
  justify-content: flex-end;
}
@media (max-width: 576px) {
  .image-aligned-banner--right .image-aligned-banner__aside {
    padding: 0;
  }
}
.image-aligned-banner--right .image-aligned-banner__main {
  padding: 0 30px 0 0;
}
.image-aligned-banner__title {
  display: block;
  font-weight: bold;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 100%;
  margin-bottom: 40px;
  max-width: 380px;
}
@media (max-width: 834px) {
  .image-aligned-banner__title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.image-aligned-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.01em;
  margin: 0 0 30px;
  max-width: 502px;
}
@media (max-width: 834px) {
  .image-aligned-banner__description {
    margin: 0 0 20px;
    font-size: 16px;
  }
}
.image-aligned-banner__button-wrap {
  display: inline-block;
  padding-top: 10px;
}
.image-aligned-banner__button {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-decoration: none;
}
.image-aligned-banner__text-sticker {
  max-width: 186px;
}
@media (max-width: 576px) {
  .image-aligned-banner__text-sticker {
    max-width: 162px;
  }
}
@media (max-width: 834px) {
  .image-aligned-banner__text-sticker {
    max-width: 160px;
  }
}
.image-aligned-banner__image-sticker {
  display: block;
  transform: rotate(15.75deg);
  width: 112px;
}
@media (max-width: 834px) {
  .image-aligned-banner__image-sticker {
    width: 80px;
  }
}
.image-aligned-banner__list {
  padding: 0 0 0 20px;
  margin: 0 0 30px 0;
}
.image-aligned-banner__list-item {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.01em;
}
@media (max-width: 834px) {
  .image-aligned-banner__list-item {
    font-size: 16px;
  }
}
