.tips-example {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  background-color: #d7f0fa;
  font-size: 20px;
  line-height: 1.4;
  padding: 16px;
}
.tips-example__title {
  color: #6991a1;
}
