.pagination {
  display: flex;
}
.pagination__step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
}
.pagination__step--disabled {
  opacity: 0.4;
}
.pagination__skip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
}
.pagination--camelot .pagination__step {
  border-color: #822d50;
  color: #822d50;
}
.pagination--camelot .pagination__skip {
  color: #822d50;
  margin: 0 4px;
}
.pagination--camelot .pagination__step path {
  fill: #822d50;
}
.pagination--camelot .pagination__step:hover {
  background-color: rgba(130, 45, 80, 0.3);
  color: #822d50;
}
.pagination--camelot .pagination__step--active,
.pagination--camelot .pagination__step--active:hover {
  color: white;
  background-color: #822d50;
}
.pagination--camelot .pagination__step--skip {
  color: #822d50;
  border-color: transparent;
}
.pagination--emerald .pagination__step {
  border-color: #32c864;
  color: #32c864;
}
.pagination--emerald .pagination__skip {
  color: #32c864;
  margin: 0 4px;
}
.pagination--emerald .pagination__step path {
  fill: #32c864;
}
.pagination--emerald .pagination__step:hover {
  background-color: rgba(50, 200, 100, 0.3);
  color: #32c864;
}
.pagination--emerald .pagination__step--active,
.pagination--emerald .pagination__step--active:hover {
  color: white;
  background-color: #32c864;
}
.pagination--emerald .pagination__step--skip {
  color: #32c864;
  border-color: transparent;
}
.pagination--sun .pagination__step {
  border-color: #faaa1e;
  color: #faaa1e;
}
.pagination--sun .pagination__skip {
  color: #faaa1e;
  margin: 0 4px;
}
.pagination--sun .pagination__step path {
  fill: #faaa1e;
}
.pagination--sun .pagination__step:hover {
  background-color: rgba(250, 170, 30, 0.3);
  color: #faaa1e;
}
.pagination--sun .pagination__step--active,
.pagination--sun .pagination__step--active:hover {
  color: white;
  background-color: #faaa1e;
}
.pagination--sun .pagination__step--skip {
  color: #faaa1e;
  border-color: transparent;
}
.pagination--robbinsEggBlue .pagination__step {
  border-color: #00d2c8;
  color: #00d2c8;
}
.pagination--robbinsEggBlue .pagination__skip {
  color: #00d2c8;
  margin: 0 4px;
}
.pagination--robbinsEggBlue .pagination__step path {
  fill: #00d2c8;
}
.pagination--robbinsEggBlue .pagination__step:hover {
  background-color: rgba(0, 210, 200, 0.3);
  color: #00d2c8;
}
.pagination--robbinsEggBlue .pagination__step--active,
.pagination--robbinsEggBlue .pagination__step--active:hover {
  color: white;
  background-color: #00d2c8;
}
.pagination--robbinsEggBlue .pagination__step--skip {
  color: #00d2c8;
  border-color: transparent;
}
.pagination--ebonyClay .pagination__step {
  border-color: #28283c;
  color: #28283c;
}
.pagination--ebonyClay .pagination__skip {
  color: #28283c;
  margin: 0 4px;
}
.pagination--ebonyClay .pagination__step path {
  fill: #28283c;
}
.pagination--ebonyClay .pagination__step:hover {
  background-color: rgba(40, 40, 60, 0.3);
  color: #28283c;
}
.pagination--ebonyClay .pagination__step--active,
.pagination--ebonyClay .pagination__step--active:hover {
  color: white;
  background-color: #28283c;
}
.pagination--ebonyClay .pagination__step--skip {
  color: #28283c;
  border-color: transparent;
}
.pagination--azalea .pagination__step {
  border-color: #fadcdc;
  color: #fadcdc;
}
.pagination--azalea .pagination__skip {
  color: #fadcdc;
  margin: 0 4px;
}
.pagination--azalea .pagination__step path {
  fill: #fadcdc;
}
.pagination--azalea .pagination__step:hover {
  background-color: rgba(250, 220, 220, 0.3);
  color: #fadcdc;
}
.pagination--azalea .pagination__step--active,
.pagination--azalea .pagination__step--active:hover {
  color: white;
  background-color: #fadcdc;
}
.pagination--azalea .pagination__step--skip {
  color: #fadcdc;
  border-color: transparent;
}
.pagination--cruise .pagination__step {
  border-color: #bff0db;
  color: #bff0db;
}
.pagination--cruise .pagination__skip {
  color: #bff0db;
  margin: 0 4px;
}
.pagination--cruise .pagination__step path {
  fill: #bff0db;
}
.pagination--cruise .pagination__step:hover {
  background-color: rgba(191, 240, 219, 0.3);
  color: #bff0db;
}
.pagination--cruise .pagination__step--active,
.pagination--cruise .pagination__step--active:hover {
  color: white;
  background-color: #bff0db;
}
.pagination--cruise .pagination__step--skip {
  color: #bff0db;
  border-color: transparent;
}
.pagination--astra .pagination__step {
  border-color: #fafabe;
  color: #fafabe;
}
.pagination--astra .pagination__skip {
  color: #fafabe;
  margin: 0 4px;
}
.pagination--astra .pagination__step path {
  fill: #fafabe;
}
.pagination--astra .pagination__step:hover {
  background-color: rgba(250, 250, 190, 0.3);
  color: #fafabe;
}
.pagination--astra .pagination__step--active,
.pagination--astra .pagination__step--active:hover {
  color: white;
  background-color: #fafabe;
}
.pagination--astra .pagination__step--skip {
  color: #fafabe;
  border-color: transparent;
}
.pagination--gallery .pagination__step {
  border-color: #f0f0f0;
  color: #f0f0f0;
}
.pagination--gallery .pagination__skip {
  color: #f0f0f0;
  margin: 0 4px;
}
.pagination--gallery .pagination__step path {
  fill: #f0f0f0;
}
.pagination--gallery .pagination__step:hover {
  background-color: rgba(240, 240, 240, 0.3);
  color: #f0f0f0;
}
.pagination--gallery .pagination__step--active,
.pagination--gallery .pagination__step--active:hover {
  color: white;
  background-color: #f0f0f0;
}
.pagination--gallery .pagination__step--skip {
  color: #f0f0f0;
  border-color: transparent;
}
.pagination--humming-bird .pagination__step {
  border-color: #d7f0fa;
  color: #d7f0fa;
}
.pagination--humming-bird .pagination__skip {
  color: #d7f0fa;
  margin: 0 4px;
}
.pagination--humming-bird .pagination__step path {
  fill: #d7f0fa;
}
.pagination--humming-bird .pagination__step:hover {
  background-color: rgba(215, 240, 250, 0.3);
  color: #d7f0fa;
}
.pagination--humming-bird .pagination__step--active,
.pagination--humming-bird .pagination__step--active:hover {
  color: white;
  background-color: #d7f0fa;
}
.pagination--humming-bird .pagination__step--skip {
  color: #d7f0fa;
  border-color: transparent;
}
.pagination--humming-bird-faded .pagination__step {
  border-color: #f0fafe;
  color: #f0fafe;
}
.pagination--humming-bird-faded .pagination__skip {
  color: #f0fafe;
  margin: 0 4px;
}
.pagination--humming-bird-faded .pagination__step path {
  fill: #f0fafe;
}
.pagination--humming-bird-faded .pagination__step:hover {
  background-color: rgba(240, 250, 254, 0.3);
  color: #f0fafe;
}
.pagination--humming-bird-faded .pagination__step--active,
.pagination--humming-bird-faded .pagination__step--active:hover {
  color: white;
  background-color: #f0fafe;
}
.pagination--humming-bird-faded .pagination__step--skip {
  color: #f0fafe;
  border-color: transparent;
}
.pagination--silver-chalice .pagination__step {
  border-color: #a6a6a6;
  color: #a6a6a6;
}
.pagination--silver-chalice .pagination__skip {
  color: #a6a6a6;
  margin: 0 4px;
}
.pagination--silver-chalice .pagination__step path {
  fill: #a6a6a6;
}
.pagination--silver-chalice .pagination__step:hover {
  background-color: rgba(166, 166, 166, 0.3);
  color: #a6a6a6;
}
.pagination--silver-chalice .pagination__step--active,
.pagination--silver-chalice .pagination__step--active:hover {
  color: white;
  background-color: #a6a6a6;
}
.pagination--silver-chalice .pagination__step--skip {
  color: #a6a6a6;
  border-color: transparent;
}
.pagination--catskill-white .pagination__step {
  border-color: #e1e9f0;
  color: #e1e9f0;
}
.pagination--catskill-white .pagination__skip {
  color: #e1e9f0;
  margin: 0 4px;
}
.pagination--catskill-white .pagination__step path {
  fill: #e1e9f0;
}
.pagination--catskill-white .pagination__step:hover {
  background-color: rgba(225, 233, 240, 0.3);
  color: #e1e9f0;
}
.pagination--catskill-white .pagination__step--active,
.pagination--catskill-white .pagination__step--active:hover {
  color: white;
  background-color: #e1e9f0;
}
.pagination--catskill-white .pagination__step--skip {
  color: #e1e9f0;
  border-color: transparent;
}
.pagination--bali-hai .pagination__step {
  border-color: #8298ab;
  color: #8298ab;
}
.pagination--bali-hai .pagination__skip {
  color: #8298ab;
  margin: 0 4px;
}
.pagination--bali-hai .pagination__step path {
  fill: #8298ab;
}
.pagination--bali-hai .pagination__step:hover {
  background-color: rgba(130, 152, 171, 0.3);
  color: #8298ab;
}
.pagination--bali-hai .pagination__step--active,
.pagination--bali-hai .pagination__step--active:hover {
  color: white;
  background-color: #8298ab;
}
.pagination--bali-hai .pagination__step--skip {
  color: #8298ab;
  border-color: transparent;
}
.pagination--french-lilac .pagination__step {
  border-color: #efe0f6;
  color: #efe0f6;
}
.pagination--french-lilac .pagination__skip {
  color: #efe0f6;
  margin: 0 4px;
}
.pagination--french-lilac .pagination__step path {
  fill: #efe0f6;
}
.pagination--french-lilac .pagination__step:hover {
  background-color: rgba(239, 224, 246, 0.3);
  color: #efe0f6;
}
.pagination--french-lilac .pagination__step--active,
.pagination--french-lilac .pagination__step--active:hover {
  color: white;
  background-color: #efe0f6;
}
.pagination--french-lilac .pagination__step--skip {
  color: #efe0f6;
  border-color: transparent;
}
.pagination--deep-blush .pagination__step {
  border-color: #e07688;
  color: #e07688;
}
.pagination--deep-blush .pagination__skip {
  color: #e07688;
  margin: 0 4px;
}
.pagination--deep-blush .pagination__step path {
  fill: #e07688;
}
.pagination--deep-blush .pagination__step:hover {
  background-color: rgba(224, 118, 136, 0.3);
  color: #e07688;
}
.pagination--deep-blush .pagination__step--active,
.pagination--deep-blush .pagination__step--active:hover {
  color: white;
  background-color: #e07688;
}
.pagination--deep-blush .pagination__step--skip {
  color: #e07688;
  border-color: transparent;
}
.pagination--pink .pagination__step {
  border-color: #fbdad8;
  color: #fbdad8;
}
.pagination--pink .pagination__skip {
  color: #fbdad8;
  margin: 0 4px;
}
.pagination--pink .pagination__step path {
  fill: #fbdad8;
}
.pagination--pink .pagination__step:hover {
  background-color: rgba(251, 218, 216, 0.3);
  color: #fbdad8;
}
.pagination--pink .pagination__step--active,
.pagination--pink .pagination__step--active:hover {
  color: white;
  background-color: #fbdad8;
}
.pagination--pink .pagination__step--skip {
  color: #fbdad8;
  border-color: transparent;
}
.pagination--gothic .pagination__step {
  border-color: #6991a1;
  color: #6991a1;
}
.pagination--gothic .pagination__skip {
  color: #6991a1;
  margin: 0 4px;
}
.pagination--gothic .pagination__step path {
  fill: #6991a1;
}
.pagination--gothic .pagination__step:hover {
  background-color: rgba(105, 145, 161, 0.3);
  color: #6991a1;
}
.pagination--gothic .pagination__step--active,
.pagination--gothic .pagination__step--active:hover {
  color: white;
  background-color: #6991a1;
}
.pagination--gothic .pagination__step--skip {
  color: #6991a1;
  border-color: transparent;
}
.pagination--polar-blue .pagination__step {
  border-color: #f0f9fc;
  color: #f0f9fc;
}
.pagination--polar-blue .pagination__skip {
  color: #f0f9fc;
  margin: 0 4px;
}
.pagination--polar-blue .pagination__step path {
  fill: #f0f9fc;
}
.pagination--polar-blue .pagination__step:hover {
  background-color: rgba(240, 249, 252, 0.3);
  color: #f0f9fc;
}
.pagination--polar-blue .pagination__step--active,
.pagination--polar-blue .pagination__step--active:hover {
  color: white;
  background-color: #f0f9fc;
}
.pagination--polar-blue .pagination__step--skip {
  color: #f0f9fc;
  border-color: transparent;
}
.pagination--prussian .pagination__step {
  border-color: #304659;
  color: #304659;
}
.pagination--prussian .pagination__skip {
  color: #304659;
  margin: 0 4px;
}
.pagination--prussian .pagination__step path {
  fill: #304659;
}
.pagination--prussian .pagination__step:hover {
  background-color: rgba(48, 70, 89, 0.3);
  color: #304659;
}
.pagination--prussian .pagination__step--active,
.pagination--prussian .pagination__step--active:hover {
  color: white;
  background-color: #304659;
}
.pagination--prussian .pagination__step--skip {
  color: #304659;
  border-color: transparent;
}
.pagination--sky .pagination__step {
  border-color: #e1e9f0;
  color: #e1e9f0;
}
.pagination--sky .pagination__skip {
  color: #e1e9f0;
  margin: 0 4px;
}
.pagination--sky .pagination__step path {
  fill: #e1e9f0;
}
.pagination--sky .pagination__step:hover {
  background-color: rgba(225, 233, 240, 0.3);
  color: #e1e9f0;
}
.pagination--sky .pagination__step--active,
.pagination--sky .pagination__step--active:hover {
  color: white;
  background-color: #e1e9f0;
}
.pagination--sky .pagination__step--skip {
  color: #e1e9f0;
  border-color: transparent;
}
.pagination--tulip-tree .pagination__step {
  border-color: #f0ac44;
  color: #f0ac44;
}
.pagination--tulip-tree .pagination__skip {
  color: #f0ac44;
  margin: 0 4px;
}
.pagination--tulip-tree .pagination__step path {
  fill: #f0ac44;
}
.pagination--tulip-tree .pagination__step:hover {
  background-color: rgba(240, 172, 68, 0.3);
  color: #f0ac44;
}
.pagination--tulip-tree .pagination__step--active,
.pagination--tulip-tree .pagination__step--active:hover {
  color: white;
  background-color: #f0ac44;
}
.pagination--tulip-tree .pagination__step--skip {
  color: #f0ac44;
  border-color: transparent;
}
.pagination--emeraldish .pagination__step {
  border-color: #03c2b9;
  color: #03c2b9;
}
.pagination--emeraldish .pagination__skip {
  color: #03c2b9;
  margin: 0 4px;
}
.pagination--emeraldish .pagination__step path {
  fill: #03c2b9;
}
.pagination--emeraldish .pagination__step:hover {
  background-color: rgba(3, 194, 185, 0.3);
  color: #03c2b9;
}
.pagination--emeraldish .pagination__step--active,
.pagination--emeraldish .pagination__step--active:hover {
  color: white;
  background-color: #03c2b9;
}
.pagination--emeraldish .pagination__step--skip {
  color: #03c2b9;
  border-color: transparent;
}
.pagination--jumbo .pagination__step {
  border-color: #7b7b86;
  color: #7b7b86;
}
.pagination--jumbo .pagination__skip {
  color: #7b7b86;
  margin: 0 4px;
}
.pagination--jumbo .pagination__step path {
  fill: #7b7b86;
}
.pagination--jumbo .pagination__step:hover {
  background-color: rgba(123, 123, 134, 0.3);
  color: #7b7b86;
}
.pagination--jumbo .pagination__step--active,
.pagination--jumbo .pagination__step--active:hover {
  color: white;
  background-color: #7b7b86;
}
.pagination--jumbo .pagination__step--skip {
  color: #7b7b86;
  border-color: transparent;
}
.pagination--pampas .pagination__step {
  border-color: #f8f7f5;
  color: #f8f7f5;
}
.pagination--pampas .pagination__skip {
  color: #f8f7f5;
  margin: 0 4px;
}
.pagination--pampas .pagination__step path {
  fill: #f8f7f5;
}
.pagination--pampas .pagination__step:hover {
  background-color: rgba(248, 247, 245, 0.3);
  color: #f8f7f5;
}
.pagination--pampas .pagination__step--active,
.pagination--pampas .pagination__step--active:hover {
  color: white;
  background-color: #f8f7f5;
}
.pagination--pampas .pagination__step--skip {
  color: #f8f7f5;
  border-color: transparent;
}
.pagination--dark-gallery .pagination__step {
  border-color: #eaeaea;
  color: #eaeaea;
}
.pagination--dark-gallery .pagination__skip {
  color: #eaeaea;
  margin: 0 4px;
}
.pagination--dark-gallery .pagination__step path {
  fill: #eaeaea;
}
.pagination--dark-gallery .pagination__step:hover {
  background-color: rgba(234, 234, 234, 0.3);
  color: #eaeaea;
}
.pagination--dark-gallery .pagination__step--active,
.pagination--dark-gallery .pagination__step--active:hover {
  color: white;
  background-color: #eaeaea;
}
.pagination--dark-gallery .pagination__step--skip {
  color: #eaeaea;
  border-color: transparent;
}
.pagination--concrete .pagination__step {
  border-color: #f2f2f2;
  color: #f2f2f2;
}
.pagination--concrete .pagination__skip {
  color: #f2f2f2;
  margin: 0 4px;
}
.pagination--concrete .pagination__step path {
  fill: #f2f2f2;
}
.pagination--concrete .pagination__step:hover {
  background-color: rgba(242, 242, 242, 0.3);
  color: #f2f2f2;
}
.pagination--concrete .pagination__step--active,
.pagination--concrete .pagination__step--active:hover {
  color: white;
  background-color: #f2f2f2;
}
.pagination--concrete .pagination__step--skip {
  color: #f2f2f2;
  border-color: transparent;
}
.pagination--sand .pagination__step {
  border-color: #fbfbfb;
  color: #fbfbfb;
}
.pagination--sand .pagination__skip {
  color: #fbfbfb;
  margin: 0 4px;
}
.pagination--sand .pagination__step path {
  fill: #fbfbfb;
}
.pagination--sand .pagination__step:hover {
  background-color: rgba(251, 251, 251, 0.3);
  color: #fbfbfb;
}
.pagination--sand .pagination__step--active,
.pagination--sand .pagination__step--active:hover {
  color: white;
  background-color: #fbfbfb;
}
.pagination--sand .pagination__step--skip {
  color: #fbfbfb;
  border-color: transparent;
}
.pagination--black-squeeze .pagination__step {
  border-color: #f9fcfd;
  color: #f9fcfd;
}
.pagination--black-squeeze .pagination__skip {
  color: #f9fcfd;
  margin: 0 4px;
}
.pagination--black-squeeze .pagination__step path {
  fill: #f9fcfd;
}
.pagination--black-squeeze .pagination__step:hover {
  background-color: rgba(249, 252, 253, 0.3);
  color: #f9fcfd;
}
.pagination--black-squeeze .pagination__step--active,
.pagination--black-squeeze .pagination__step--active:hover {
  color: white;
  background-color: #f9fcfd;
}
.pagination--black-squeeze .pagination__step--skip {
  color: #f9fcfd;
  border-color: transparent;
}
.pagination--athens-gray .pagination__step {
  border-color: #f4f4f4;
  color: #f4f4f4;
}
.pagination--athens-gray .pagination__skip {
  color: #f4f4f4;
  margin: 0 4px;
}
.pagination--athens-gray .pagination__step path {
  fill: #f4f4f4;
}
.pagination--athens-gray .pagination__step:hover {
  background-color: rgba(244, 244, 244, 0.3);
  color: #f4f4f4;
}
.pagination--athens-gray .pagination__step--active,
.pagination--athens-gray .pagination__step--active:hover {
  color: white;
  background-color: #f4f4f4;
}
.pagination--athens-gray .pagination__step--skip {
  color: #f4f4f4;
  border-color: transparent;
}
.pagination--alto .pagination__step {
  border-color: #d8d8d8;
  color: #d8d8d8;
}
.pagination--alto .pagination__skip {
  color: #d8d8d8;
  margin: 0 4px;
}
.pagination--alto .pagination__step path {
  fill: #d8d8d8;
}
.pagination--alto .pagination__step:hover {
  background-color: rgba(216, 216, 216, 0.3);
  color: #d8d8d8;
}
.pagination--alto .pagination__step--active,
.pagination--alto .pagination__step--active:hover {
  color: white;
  background-color: #d8d8d8;
}
.pagination--alto .pagination__step--skip {
  color: #d8d8d8;
  border-color: transparent;
}
.pagination--gray .pagination__step {
  border-color: #888;
  color: #888;
}
.pagination--gray .pagination__skip {
  color: #888;
  margin: 0 4px;
}
.pagination--gray .pagination__step path {
  fill: #888;
}
.pagination--gray .pagination__step:hover {
  background-color: rgba(136, 136, 136, 0.3);
  color: #888;
}
.pagination--gray .pagination__step--active,
.pagination--gray .pagination__step--active:hover {
  color: white;
  background-color: #888;
}
.pagination--gray .pagination__step--skip {
  color: #888;
  border-color: transparent;
}
.pagination--venus .pagination__step {
  border-color: #7e7e8a;
  color: #7e7e8a;
}
.pagination--venus .pagination__skip {
  color: #7e7e8a;
  margin: 0 4px;
}
.pagination--venus .pagination__step path {
  fill: #7e7e8a;
}
.pagination--venus .pagination__step:hover {
  background-color: rgba(126, 126, 138, 0.3);
  color: #7e7e8a;
}
.pagination--venus .pagination__step--active,
.pagination--venus .pagination__step--active:hover {
  color: white;
  background-color: #7e7e8a;
}
.pagination--venus .pagination__step--skip {
  color: #7e7e8a;
  border-color: transparent;
}
.pagination--dolphin .pagination__step {
  border-color: #6b6a77;
  color: #6b6a77;
}
.pagination--dolphin .pagination__skip {
  color: #6b6a77;
  margin: 0 4px;
}
.pagination--dolphin .pagination__step path {
  fill: #6b6a77;
}
.pagination--dolphin .pagination__step:hover {
  background-color: rgba(107, 106, 119, 0.3);
  color: #6b6a77;
}
.pagination--dolphin .pagination__step--active,
.pagination--dolphin .pagination__step--active:hover {
  color: white;
  background-color: #6b6a77;
}
.pagination--dolphin .pagination__step--skip {
  color: #6b6a77;
  border-color: transparent;
}
.pagination--mercury .pagination__step {
  border-color: #e6e6e6;
  color: #e6e6e6;
}
.pagination--mercury .pagination__skip {
  color: #e6e6e6;
  margin: 0 4px;
}
.pagination--mercury .pagination__step path {
  fill: #e6e6e6;
}
.pagination--mercury .pagination__step:hover {
  background-color: rgba(230, 230, 230, 0.3);
  color: #e6e6e6;
}
.pagination--mercury .pagination__step--active,
.pagination--mercury .pagination__step--active:hover {
  color: white;
  background-color: #e6e6e6;
}
.pagination--mercury .pagination__step--skip {
  color: #e6e6e6;
  border-color: transparent;
}
.pagination--text-black .pagination__step {
  border-color: #28283c;
  color: #28283c;
}
.pagination--text-black .pagination__skip {
  color: #28283c;
  margin: 0 4px;
}
.pagination--text-black .pagination__step path {
  fill: #28283c;
}
.pagination--text-black .pagination__step:hover {
  background-color: rgba(40, 40, 60, 0.3);
  color: #28283c;
}
.pagination--text-black .pagination__step--active,
.pagination--text-black .pagination__step--active:hover {
  color: white;
  background-color: #28283c;
}
.pagination--text-black .pagination__step--skip {
  color: #28283c;
  border-color: transparent;
}
.pagination--polar .pagination__step {
  border-color: #eafbfa;
  color: #eafbfa;
}
.pagination--polar .pagination__skip {
  color: #eafbfa;
  margin: 0 4px;
}
.pagination--polar .pagination__step path {
  fill: #eafbfa;
}
.pagination--polar .pagination__step:hover {
  background-color: rgba(234, 251, 250, 0.3);
  color: #eafbfa;
}
.pagination--polar .pagination__step--active,
.pagination--polar .pagination__step--active:hover {
  color: white;
  background-color: #eafbfa;
}
.pagination--polar .pagination__step--skip {
  color: #eafbfa;
  border-color: transparent;
}
.pagination--tundora .pagination__step {
  border-color: #484444;
  color: #484444;
}
.pagination--tundora .pagination__skip {
  color: #484444;
  margin: 0 4px;
}
.pagination--tundora .pagination__step path {
  fill: #484444;
}
.pagination--tundora .pagination__step:hover {
  background-color: rgba(72, 68, 68, 0.3);
  color: #484444;
}
.pagination--tundora .pagination__step--active,
.pagination--tundora .pagination__step--active:hover {
  color: white;
  background-color: #484444;
}
.pagination--tundora .pagination__step--skip {
  color: #484444;
  border-color: transparent;
}
.pagination--jet .pagination__step {
  border-color: #3e3e50;
  color: #3e3e50;
}
.pagination--jet .pagination__skip {
  color: #3e3e50;
  margin: 0 4px;
}
.pagination--jet .pagination__step path {
  fill: #3e3e50;
}
.pagination--jet .pagination__step:hover {
  background-color: rgba(62, 62, 80, 0.3);
  color: #3e3e50;
}
.pagination--jet .pagination__step--active,
.pagination--jet .pagination__step--active:hover {
  color: white;
  background-color: #3e3e50;
}
.pagination--jet .pagination__step--skip {
  color: #3e3e50;
  border-color: transparent;
}
.pagination--silver .pagination__step {
  border-color: #bdbdbd;
  color: #bdbdbd;
}
.pagination--silver .pagination__skip {
  color: #bdbdbd;
  margin: 0 4px;
}
.pagination--silver .pagination__step path {
  fill: #bdbdbd;
}
.pagination--silver .pagination__step:hover {
  background-color: rgba(189, 189, 189, 0.3);
  color: #bdbdbd;
}
.pagination--silver .pagination__step--active,
.pagination--silver .pagination__step--active:hover {
  color: white;
  background-color: #bdbdbd;
}
.pagination--silver .pagination__step--skip {
  color: #bdbdbd;
  border-color: transparent;
}
.pagination--smoke .pagination__step {
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.pagination--smoke .pagination__skip {
  color: #bfbfbf;
  margin: 0 4px;
}
.pagination--smoke .pagination__step path {
  fill: #bfbfbf;
}
.pagination--smoke .pagination__step:hover {
  background-color: rgba(191, 191, 191, 0.3);
  color: #bfbfbf;
}
.pagination--smoke .pagination__step--active,
.pagination--smoke .pagination__step--active:hover {
  color: white;
  background-color: #bfbfbf;
}
.pagination--smoke .pagination__step--skip {
  color: #bfbfbf;
  border-color: transparent;
}
.pagination--white .pagination__step {
  border-color: #fff;
  color: #fff;
}
.pagination--white .pagination__skip {
  color: #fff;
  margin: 0 4px;
}
.pagination--white .pagination__step path {
  fill: #fff;
}
.pagination--white .pagination__step:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.pagination--white .pagination__step--active,
.pagination--white .pagination__step--active:hover {
  color: white;
  background-color: #fff;
}
.pagination--white .pagination__step--skip {
  color: #fff;
  border-color: transparent;
}
.pagination--malachite .pagination__step {
  border-color: #00c040;
  color: #00c040;
}
.pagination--malachite .pagination__skip {
  color: #00c040;
  margin: 0 4px;
}
.pagination--malachite .pagination__step path {
  fill: #00c040;
}
.pagination--malachite .pagination__step:hover {
  background-color: rgba(0, 192, 64, 0.3);
  color: #00c040;
}
.pagination--malachite .pagination__step--active,
.pagination--malachite .pagination__step--active:hover {
  color: white;
  background-color: #00c040;
}
.pagination--malachite .pagination__step--skip {
  color: #00c040;
  border-color: transparent;
}
.pagination--light-green .pagination__step {
  border-color: #c0f0dd;
  color: #c0f0dd;
}
.pagination--light-green .pagination__skip {
  color: #c0f0dd;
  margin: 0 4px;
}
.pagination--light-green .pagination__step path {
  fill: #c0f0dd;
}
.pagination--light-green .pagination__step:hover {
  background-color: rgba(192, 240, 221, 0.3);
  color: #c0f0dd;
}
.pagination--light-green .pagination__step--active,
.pagination--light-green .pagination__step--active:hover {
  color: white;
  background-color: #c0f0dd;
}
.pagination--light-green .pagination__step--skip {
  color: #c0f0dd;
  border-color: transparent;
}
.pagination--coral .pagination__step {
  border-color: #ff774d;
  color: #ff774d;
}
.pagination--coral .pagination__skip {
  color: #ff774d;
  margin: 0 4px;
}
.pagination--coral .pagination__step path {
  fill: #ff774d;
}
.pagination--coral .pagination__step:hover {
  background-color: rgba(255, 119, 77, 0.3);
  color: #ff774d;
}
.pagination--coral .pagination__step--active,
.pagination--coral .pagination__step--active:hover {
  color: white;
  background-color: #ff774d;
}
.pagination--coral .pagination__step--skip {
  color: #ff774d;
  border-color: transparent;
}
.pagination--bright-sun .pagination__step {
  border-color: #ffcc43;
  color: #ffcc43;
}
.pagination--bright-sun .pagination__skip {
  color: #ffcc43;
  margin: 0 4px;
}
.pagination--bright-sun .pagination__step path {
  fill: #ffcc43;
}
.pagination--bright-sun .pagination__step:hover {
  background-color: rgba(255, 204, 67, 0.3);
  color: #ffcc43;
}
.pagination--bright-sun .pagination__step--active,
.pagination--bright-sun .pagination__step--active:hover {
  color: white;
  background-color: #ffcc43;
}
.pagination--bright-sun .pagination__step--skip {
  color: #ffcc43;
  border-color: transparent;
}
.pagination--shark .pagination__step {
  border-color: #272a2d;
  color: #272a2d;
}
.pagination--shark .pagination__skip {
  color: #272a2d;
  margin: 0 4px;
}
.pagination--shark .pagination__step path {
  fill: #272a2d;
}
.pagination--shark .pagination__step:hover {
  background-color: rgba(39, 42, 45, 0.3);
  color: #272a2d;
}
.pagination--shark .pagination__step--active,
.pagination--shark .pagination__step--active:hover {
  color: white;
  background-color: #272a2d;
}
.pagination--shark .pagination__step--skip {
  color: #272a2d;
  border-color: transparent;
}
.pagination--royal-blue .pagination__step {
  border-color: #4945e2;
  color: #4945e2;
}
.pagination--royal-blue .pagination__skip {
  color: #4945e2;
  margin: 0 4px;
}
.pagination--royal-blue .pagination__step path {
  fill: #4945e2;
}
.pagination--royal-blue .pagination__step:hover {
  background-color: rgba(73, 69, 226, 0.3);
  color: #4945e2;
}
.pagination--royal-blue .pagination__step--active,
.pagination--royal-blue .pagination__step--active:hover {
  color: white;
  background-color: #4945e2;
}
.pagination--royal-blue .pagination__step--skip {
  color: #4945e2;
  border-color: transparent;
}
.pagination--aquamarine-blue .pagination__step {
  border-color: #7ce5df;
  color: #7ce5df;
}
.pagination--aquamarine-blue .pagination__skip {
  color: #7ce5df;
  margin: 0 4px;
}
.pagination--aquamarine-blue .pagination__step path {
  fill: #7ce5df;
}
.pagination--aquamarine-blue .pagination__step:hover {
  background-color: rgba(124, 229, 223, 0.3);
  color: #7ce5df;
}
.pagination--aquamarine-blue .pagination__step--active,
.pagination--aquamarine-blue .pagination__step--active:hover {
  color: white;
  background-color: #7ce5df;
}
.pagination--aquamarine-blue .pagination__step--skip {
  color: #7ce5df;
  border-color: transparent;
}
.pagination--scorpion .pagination__step {
  border-color: #595959;
  color: #595959;
}
.pagination--scorpion .pagination__skip {
  color: #595959;
  margin: 0 4px;
}
.pagination--scorpion .pagination__step path {
  fill: #595959;
}
.pagination--scorpion .pagination__step:hover {
  background-color: rgba(89, 89, 89, 0.3);
  color: #595959;
}
.pagination--scorpion .pagination__step--active,
.pagination--scorpion .pagination__step--active:hover {
  color: white;
  background-color: #595959;
}
.pagination--scorpion .pagination__step--skip {
  color: #595959;
  border-color: transparent;
}
.pagination--heather .pagination__step {
  border-color: #b8c5d0;
  color: #b8c5d0;
}
.pagination--heather .pagination__skip {
  color: #b8c5d0;
  margin: 0 4px;
}
.pagination--heather .pagination__step path {
  fill: #b8c5d0;
}
.pagination--heather .pagination__step:hover {
  background-color: rgba(184, 197, 208, 0.3);
  color: #b8c5d0;
}
.pagination--heather .pagination__step--active,
.pagination--heather .pagination__step--active:hover {
  color: white;
  background-color: #b8c5d0;
}
.pagination--heather .pagination__step--skip {
  color: #b8c5d0;
  border-color: transparent;
}
.pagination--red .pagination__step {
  border-color: #de2828;
  color: #de2828;
}
.pagination--red .pagination__skip {
  color: #de2828;
  margin: 0 4px;
}
.pagination--red .pagination__step path {
  fill: #de2828;
}
.pagination--red .pagination__step:hover {
  background-color: rgba(222, 40, 40, 0.3);
  color: #de2828;
}
.pagination--red .pagination__step--active,
.pagination--red .pagination__step--active:hover {
  color: white;
  background-color: #de2828;
}
.pagination--red .pagination__step--skip {
  color: #de2828;
  border-color: transparent;
}
.pagination--lighter-teal .pagination__step {
  border-color: #d6f6f3;
  color: #d6f6f3;
}
.pagination--lighter-teal .pagination__skip {
  color: #d6f6f3;
  margin: 0 4px;
}
.pagination--lighter-teal .pagination__step path {
  fill: #d6f6f3;
}
.pagination--lighter-teal .pagination__step:hover {
  background-color: rgba(214, 246, 243, 0.3);
  color: #d6f6f3;
}
.pagination--lighter-teal .pagination__step--active,
.pagination--lighter-teal .pagination__step--active:hover {
  color: white;
  background-color: #d6f6f3;
}
.pagination--lighter-teal .pagination__step--skip {
  color: #d6f6f3;
  border-color: transparent;
}
.pagination--blue-lagoon .pagination__step {
  border-color: #007986;
  color: #007986;
}
.pagination--blue-lagoon .pagination__skip {
  color: #007986;
  margin: 0 4px;
}
.pagination--blue-lagoon .pagination__step path {
  fill: #007986;
}
.pagination--blue-lagoon .pagination__step:hover {
  background-color: rgba(0, 121, 134, 0.3);
  color: #007986;
}
.pagination--blue-lagoon .pagination__step--active,
.pagination--blue-lagoon .pagination__step--active:hover {
  color: white;
  background-color: #007986;
}
.pagination--blue-lagoon .pagination__step--skip {
  color: #007986;
  border-color: transparent;
}
.pagination--brightTeal .pagination__step {
  border-color: #00d8c4;
  color: #00d8c4;
}
.pagination--brightTeal .pagination__skip {
  color: #00d8c4;
  margin: 0 4px;
}
.pagination--brightTeal .pagination__step path {
  fill: #00d8c4;
}
.pagination--brightTeal .pagination__step:hover {
  background-color: rgba(0, 216, 196, 0.3);
  color: #00d8c4;
}
.pagination--brightTeal .pagination__step--active,
.pagination--brightTeal .pagination__step--active:hover {
  color: white;
  background-color: #00d8c4;
}
.pagination--brightTeal .pagination__step--skip {
  color: #00d8c4;
  border-color: transparent;
}
.pagination__step + .pagination__step {
  margin-left: 8px;
}
