.sign-up-form {
  padding: 30px;
}
.sign-up-form__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
}
.sign-up-form__form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.sign-up-form__form-input {
  width: 100%;
  padding-bottom: 20px;
}
.sign-up-form__form-button {
  width: 100%;
  margin-bottom: 20px;
}
.sign-up-form__form-error {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #e07688;
  margin-bottom: 20px;
}
.sign-up-form__form-label {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  cursor: pointer;
}
.sign-up-form__form-label:hover {
  text-decoration: underline;
}
.sign-up-form__divider {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0;
}
.sign-up-form__social-buttons {
  padding-bottom: 20px;
}
.sign-up-form__social-button {
  margin-bottom: 10px;
}
.sign-up-form__social-button:last-child {
  margin-bottom: 0px;
}
.sign-up-form__label {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
  margin-bottom: 20px;
  cursor: pointer;
}
.sign-up-form__link {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: #822d50;
  cursor: pointer;
  margin: 0 5px;
}
.sign-up-form__link:hover {
  text-decoration: underline;
}
.sign-up-form__error {
  width: 100%;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #e07688;
  text-align: center;
  padding: 10px 0;
}
.sign-up-form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f4f4f4;
  padding-top: 20px;
}
.sign-up-form__footer-text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: #28283c;
}
