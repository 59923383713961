.product-page-header__content {
  margin-bottom: 25px;
}
.product-page-header__stories {
  margin: 0 -15px;
}
@media (min-width: 834px) {
  .product-page-header__stories {
    margin: 0;
    overflow: hidden;
    border-radius: 10px;
  }
}
.product-page-header__title {
  display: block;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-weight: bold;
  font-size: 42px;
  line-height: 90%;
  color: #28283c;
  text-transform: uppercase;
}
@media (min-width: 834px) {
  .product-page-header__title {
    font-size: 52px;
  }
}
.product-page-header__description {
  display: block;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 144%;
}
.product-page-header__info-wrapper {
  display: flex;
}
.product-page-header__info {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.product-page-header__info-label {
  color: #a6a6a6;
  font-size: 16px;
  display: block;
}
.product-page-header__info-value {
  color: #28283c;
  font-size: 18px;
}
.product-page-header__line-through {
  text-decoration: line-through;
}
.product-page-header__sub-description {
  margin-top: 15px;
  display: inline-block;
}
.product-page-header__sub-description:before {
  display: block;
  content: " ";
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #ff774d;
}
.product-page-header__sub-description-title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #a6a6a6;
  padding: 0 5px 3px;
}
.product-page-header__sub-description-txt {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 18px;
  padding: 0 5px;
  line-height: 1;
  color: #28283c;
}
