.rail-carousel-2__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #28283c;
  margin: 0;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
}
.rail-carousel-2__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #28283c;
  opacity: 0.6;
  margin: 0;
  letter-spacing: 0.03em;
  line-height: 1.2;
}
@media (min-width: 992px) {
  .rail-carousel-2__description {
    font-size: 18px;
  }
}
.rail-carousel-2__body {
  margin-top: 23px;
  display: grid;
  column-gap: 16px;
  justify-items: center;
}
.rail-carousel-2__body--column-number-1 {
  grid-template-columns: repeat(1, 1fr);
}
.rail-carousel-2__body--column-number-2 {
  grid-template-columns: repeat(2, 1fr);
}
.rail-carousel-2__body--column-number-3 {
  grid-template-columns: repeat(3, 1fr);
}
.rail-carousel-2__body--column-number-4 {
  grid-template-columns: repeat(4, 1fr);
}
.rail-carousel-2__body--column-number-5 {
  grid-template-columns: repeat(5, 1fr);
}
.rail-carousel-2__body--column-number-6 {
  grid-template-columns: repeat(6, 1fr);
}
.rail-carousel-2__body--column-number-7 {
  grid-template-columns: repeat(7, 1fr);
}
.rail-carousel-2__body--column-number-8 {
  grid-template-columns: repeat(8, 1fr);
}
.rail-carousel-2__body--column-number-9 {
  grid-template-columns: repeat(9, 1fr);
}
.rail-carousel-2__body--column-number-10 {
  grid-template-columns: repeat(10, 1fr);
}
.rail-carousel-2__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rail-carousel-2__nav {
  display: none;
}
@media (min-width: 576px) {
  .rail-carousel-2__nav {
    display: block;
  }
}
.rail-carousel-2__nav-item {
  display: inline-flex;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  text-align: center;
  margin-left: 7px;
  margin-top: 2.5px;
  cursor: pointer;
  border: 2px solid #03c2b9;
}
.rail-carousel-2__nav-item--disabled {
  opacity: 0.5;
}
.rail-carousel-2__nav-icon {
  display: block;
  margin: auto;
  fill: #03c2b9;
}
