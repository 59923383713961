.text-sticker {
  padding: 24px;
}
@media (max-width: 834px) {
  .text-sticker {
    padding: 20px;
  }
}
.text-sticker--camelot {
  color: #822d50;
}
.text-sticker--emerald {
  color: #32c864;
}
.text-sticker--sun {
  color: #faaa1e;
}
.text-sticker--robbinsEggBlue {
  color: #00d2c8;
}
.text-sticker--ebonyClay {
  color: #28283c;
}
.text-sticker--azalea {
  color: #fadcdc;
}
.text-sticker--cruise {
  color: #bff0db;
}
.text-sticker--astra {
  color: #fafabe;
}
.text-sticker--gallery {
  color: #f0f0f0;
}
.text-sticker--humming-bird {
  color: #d7f0fa;
}
.text-sticker--humming-bird-faded {
  color: #f0fafe;
}
.text-sticker--silver-chalice {
  color: #a6a6a6;
}
.text-sticker--catskill-white {
  color: #e1e9f0;
}
.text-sticker--bali-hai {
  color: #8298ab;
}
.text-sticker--french-lilac {
  color: #efe0f6;
}
.text-sticker--deep-blush {
  color: #e07688;
}
.text-sticker--pink {
  color: #fbdad8;
}
.text-sticker--gothic {
  color: #6991a1;
}
.text-sticker--polar-blue {
  color: #f0f9fc;
}
.text-sticker--prussian {
  color: #304659;
}
.text-sticker--sky {
  color: #e1e9f0;
}
.text-sticker--tulip-tree {
  color: #f0ac44;
}
.text-sticker--emeraldish {
  color: #03c2b9;
}
.text-sticker--jumbo {
  color: #7b7b86;
}
.text-sticker--pampas {
  color: #f8f7f5;
}
.text-sticker--dark-gallery {
  color: #eaeaea;
}
.text-sticker--concrete {
  color: #f2f2f2;
}
.text-sticker--sand {
  color: #fbfbfb;
}
.text-sticker--black-squeeze {
  color: #f9fcfd;
}
.text-sticker--athens-gray {
  color: #f4f4f4;
}
.text-sticker--alto {
  color: #d8d8d8;
}
.text-sticker--gray {
  color: #888;
}
.text-sticker--venus {
  color: #7e7e8a;
}
.text-sticker--dolphin {
  color: #6b6a77;
}
.text-sticker--mercury {
  color: #e6e6e6;
}
.text-sticker--text-black {
  color: #28283c;
}
.text-sticker--polar {
  color: #eafbfa;
}
.text-sticker--tundora {
  color: #484444;
}
.text-sticker--jet {
  color: #3e3e50;
}
.text-sticker--silver {
  color: #bdbdbd;
}
.text-sticker--smoke {
  color: #bfbfbf;
}
.text-sticker--white {
  color: #fff;
}
.text-sticker--malachite {
  color: #00c040;
}
.text-sticker--light-green {
  color: #c0f0dd;
}
.text-sticker--coral {
  color: #ff774d;
}
.text-sticker--bright-sun {
  color: #ffcc43;
}
.text-sticker--shark {
  color: #272a2d;
}
.text-sticker--royal-blue {
  color: #4945e2;
}
.text-sticker--aquamarine-blue {
  color: #7ce5df;
}
.text-sticker--scorpion {
  color: #595959;
}
.text-sticker--heather {
  color: #b8c5d0;
}
.text-sticker--red {
  color: #de2828;
}
.text-sticker--lighter-teal {
  color: #d6f6f3;
}
.text-sticker--blue-lagoon {
  color: #007986;
}
.text-sticker--brightTeal {
  color: #00d8c4;
}
.text-sticker--bg-camelot {
  background-color: #822d50;
}
.text-sticker--bg-emerald {
  background-color: #32c864;
}
.text-sticker--bg-sun {
  background-color: #faaa1e;
}
.text-sticker--bg-robbinsEggBlue {
  background-color: #00d2c8;
}
.text-sticker--bg-ebonyClay {
  background-color: #28283c;
}
.text-sticker--bg-azalea {
  background-color: #fadcdc;
}
.text-sticker--bg-cruise {
  background-color: #bff0db;
}
.text-sticker--bg-astra {
  background-color: #fafabe;
}
.text-sticker--bg-gallery {
  background-color: #f0f0f0;
}
.text-sticker--bg-humming-bird {
  background-color: #d7f0fa;
}
.text-sticker--bg-humming-bird-faded {
  background-color: #f0fafe;
}
.text-sticker--bg-silver-chalice {
  background-color: #a6a6a6;
}
.text-sticker--bg-catskill-white {
  background-color: #e1e9f0;
}
.text-sticker--bg-bali-hai {
  background-color: #8298ab;
}
.text-sticker--bg-french-lilac {
  background-color: #efe0f6;
}
.text-sticker--bg-deep-blush {
  background-color: #e07688;
}
.text-sticker--bg-pink {
  background-color: #fbdad8;
}
.text-sticker--bg-gothic {
  background-color: #6991a1;
}
.text-sticker--bg-polar-blue {
  background-color: #f0f9fc;
}
.text-sticker--bg-prussian {
  background-color: #304659;
}
.text-sticker--bg-sky {
  background-color: #e1e9f0;
}
.text-sticker--bg-tulip-tree {
  background-color: #f0ac44;
}
.text-sticker--bg-emeraldish {
  background-color: #03c2b9;
}
.text-sticker--bg-jumbo {
  background-color: #7b7b86;
}
.text-sticker--bg-pampas {
  background-color: #f8f7f5;
}
.text-sticker--bg-dark-gallery {
  background-color: #eaeaea;
}
.text-sticker--bg-concrete {
  background-color: #f2f2f2;
}
.text-sticker--bg-sand {
  background-color: #fbfbfb;
}
.text-sticker--bg-black-squeeze {
  background-color: #f9fcfd;
}
.text-sticker--bg-athens-gray {
  background-color: #f4f4f4;
}
.text-sticker--bg-alto {
  background-color: #d8d8d8;
}
.text-sticker--bg-gray {
  background-color: #888;
}
.text-sticker--bg-venus {
  background-color: #7e7e8a;
}
.text-sticker--bg-dolphin {
  background-color: #6b6a77;
}
.text-sticker--bg-mercury {
  background-color: #e6e6e6;
}
.text-sticker--bg-text-black {
  background-color: #28283c;
}
.text-sticker--bg-polar {
  background-color: #eafbfa;
}
.text-sticker--bg-tundora {
  background-color: #484444;
}
.text-sticker--bg-jet {
  background-color: #3e3e50;
}
.text-sticker--bg-silver {
  background-color: #bdbdbd;
}
.text-sticker--bg-smoke {
  background-color: #bfbfbf;
}
.text-sticker--bg-white {
  background-color: #fff;
}
.text-sticker--bg-malachite {
  background-color: #00c040;
}
.text-sticker--bg-light-green {
  background-color: #c0f0dd;
}
.text-sticker--bg-coral {
  background-color: #ff774d;
}
.text-sticker--bg-bright-sun {
  background-color: #ffcc43;
}
.text-sticker--bg-shark {
  background-color: #272a2d;
}
.text-sticker--bg-royal-blue {
  background-color: #4945e2;
}
.text-sticker--bg-aquamarine-blue {
  background-color: #7ce5df;
}
.text-sticker--bg-scorpion {
  background-color: #595959;
}
.text-sticker--bg-heather {
  background-color: #b8c5d0;
}
.text-sticker--bg-red {
  background-color: #de2828;
}
.text-sticker--bg-lighter-teal {
  background-color: #d6f6f3;
}
.text-sticker--bg-blue-lagoon {
  background-color: #007986;
}
.text-sticker--bg-brightTeal {
  background-color: #00d8c4;
}
.text-sticker__title {
  display: block;
  font-size: 18px;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  margin-bottom: 6px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .text-sticker__title {
    font-size: 16px;
  }
}
.text-sticker__description {
  font-size: 16px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  line-height: 21px;
}
@media (max-width: 768px) {
  .text-sticker__description {
    font-size: 14px;
  }
}
