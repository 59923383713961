.social-sign-in-button {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  border-radius: 50px;
  cursor: pointer;
  outline: none !important;
  justify-content: center;
  border: 1px solid #28283c;
  background-color: white;
  color: #28283c;
}
.social-sign-in-button--width-auto {
  display: inline-flex;
  width: auto;
}
.social-sign-in-button--width-auto .social-sign-in-button__icon {
  position: static;
  vertical-align: middle;
}
.social-sign-in-button--width-max {
  display: flex;
  width: 100%;
}
.social-sign-in-button--large {
  font-size: 18px;
  padding: 14px 20px;
  height: 48px;
}
.social-sign-in-button--medium {
  font-size: 18px;
  padding: 10px 18px;
  height: 40px;
}
.social-sign-in-button--small {
  font-size: 16px;
  padding: 8px 16px;
  height: 34px;
}
.social-sign-in-button--tiny {
  font-size: 12px;
  padding: 0px 10px;
  height: 28px;
}
.social-sign-in-button--disabled {
  color: white;
  border-color: #bdbdbd;
  background-color: #bdbdbd;
  cursor: default;
}
.social-sign-in-button__text {
  position: relative;
  flex-grow: 1;
}
.social-sign-in-button__icon {
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
}
