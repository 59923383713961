.tab {
  display: flex;
  position: relative;
  padding: 0 20px;
  transition: 300ms all;
  flex-shrink: 0;
}
.tab:first-child {
  padding-left: 0;
}
.tab:last-child {
  padding-right: 0;
}
.tab__wrapper {
  display: inline-block;
  padding: 0 0 13px;
  border-bottom: 3px solid transparent;
}
.tab__wrapper:not(.tab__wrapper--active) {
  border-bottom-color: transparent;
}
.tab__wrapper--accent-camelot {
  border-bottom-color: #822d50;
}
.tab__wrapper--accent-emerald {
  border-bottom-color: #32c864;
}
.tab__wrapper--accent-sun {
  border-bottom-color: #faaa1e;
}
.tab__wrapper--accent-robbinsEggBlue {
  border-bottom-color: #00d2c8;
}
.tab__wrapper--accent-ebonyClay {
  border-bottom-color: #28283c;
}
.tab__wrapper--accent-azalea {
  border-bottom-color: #fadcdc;
}
.tab__wrapper--accent-cruise {
  border-bottom-color: #bff0db;
}
.tab__wrapper--accent-astra {
  border-bottom-color: #fafabe;
}
.tab__wrapper--accent-gallery {
  border-bottom-color: #f0f0f0;
}
.tab__wrapper--accent-humming-bird {
  border-bottom-color: #d7f0fa;
}
.tab__wrapper--accent-humming-bird-faded {
  border-bottom-color: #f0fafe;
}
.tab__wrapper--accent-silver-chalice {
  border-bottom-color: #a6a6a6;
}
.tab__wrapper--accent-catskill-white {
  border-bottom-color: #e1e9f0;
}
.tab__wrapper--accent-bali-hai {
  border-bottom-color: #8298ab;
}
.tab__wrapper--accent-french-lilac {
  border-bottom-color: #efe0f6;
}
.tab__wrapper--accent-deep-blush {
  border-bottom-color: #e07688;
}
.tab__wrapper--accent-pink {
  border-bottom-color: #fbdad8;
}
.tab__wrapper--accent-gothic {
  border-bottom-color: #6991a1;
}
.tab__wrapper--accent-polar-blue {
  border-bottom-color: #f0f9fc;
}
.tab__wrapper--accent-prussian {
  border-bottom-color: #304659;
}
.tab__wrapper--accent-sky {
  border-bottom-color: #e1e9f0;
}
.tab__wrapper--accent-tulip-tree {
  border-bottom-color: #f0ac44;
}
.tab__wrapper--accent-emeraldish {
  border-bottom-color: #03c2b9;
}
.tab__wrapper--accent-jumbo {
  border-bottom-color: #7b7b86;
}
.tab__wrapper--accent-pampas {
  border-bottom-color: #f8f7f5;
}
.tab__wrapper--accent-dark-gallery {
  border-bottom-color: #eaeaea;
}
.tab__wrapper--accent-concrete {
  border-bottom-color: #f2f2f2;
}
.tab__wrapper--accent-sand {
  border-bottom-color: #fbfbfb;
}
.tab__wrapper--accent-black-squeeze {
  border-bottom-color: #f9fcfd;
}
.tab__wrapper--accent-athens-gray {
  border-bottom-color: #f4f4f4;
}
.tab__wrapper--accent-alto {
  border-bottom-color: #d8d8d8;
}
.tab__wrapper--accent-gray {
  border-bottom-color: #888;
}
.tab__wrapper--accent-venus {
  border-bottom-color: #7e7e8a;
}
.tab__wrapper--accent-dolphin {
  border-bottom-color: #6b6a77;
}
.tab__wrapper--accent-mercury {
  border-bottom-color: #e6e6e6;
}
.tab__wrapper--accent-text-black {
  border-bottom-color: #28283c;
}
.tab__wrapper--accent-polar {
  border-bottom-color: #eafbfa;
}
.tab__wrapper--accent-tundora {
  border-bottom-color: #484444;
}
.tab__wrapper--accent-jet {
  border-bottom-color: #3e3e50;
}
.tab__wrapper--accent-silver {
  border-bottom-color: #bdbdbd;
}
.tab__wrapper--accent-smoke {
  border-bottom-color: #bfbfbf;
}
.tab__wrapper--accent-white {
  border-bottom-color: #fff;
}
.tab__wrapper--accent-malachite {
  border-bottom-color: #00c040;
}
.tab__wrapper--accent-light-green {
  border-bottom-color: #c0f0dd;
}
.tab__wrapper--accent-coral {
  border-bottom-color: #ff774d;
}
.tab__wrapper--accent-bright-sun {
  border-bottom-color: #ffcc43;
}
.tab__wrapper--accent-shark {
  border-bottom-color: #272a2d;
}
.tab__wrapper--accent-royal-blue {
  border-bottom-color: #4945e2;
}
.tab__wrapper--accent-aquamarine-blue {
  border-bottom-color: #7ce5df;
}
.tab__wrapper--accent-scorpion {
  border-bottom-color: #595959;
}
.tab__wrapper--accent-heather {
  border-bottom-color: #b8c5d0;
}
.tab__wrapper--accent-red {
  border-bottom-color: #de2828;
}
.tab__wrapper--accent-lighter-teal {
  border-bottom-color: #d6f6f3;
}
.tab__wrapper--accent-blue-lagoon {
  border-bottom-color: #007986;
}
.tab__wrapper--accent-brightTeal {
  border-bottom-color: #00d8c4;
}
.tab__label {
  cursor: pointer;
  font-weight: 500;
  line-height: 24px;
  color: #28283c;
  text-decoration: none;
  letter-spacing: 0.01em;
  font-size: 18px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.tab__label:active,
.tab__label:hover,
.tab__label:focus,
.tab__label:visited {
  color: #28283c;
  text-decoration: none;
}
.tab__label--disabled {
  color: #bdbdbd;
  cursor: default;
}
.tab__label--disabled:active,
.tab__label--disabled:hover,
.tab__label--disabled:focus,
.tab__label--disabled:visited {
  color: #bdbdbd;
}
.tab__notification {
  width: 15px;
  right: -17px;
  color: #FFFFFF;
  line-height: 140%;
  position: absolute;
  text-align: center;
  border-radius: 10px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  font-size: 10px;
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
}
.tab__notification--camelot {
  background-color: #822d50;
}
.tab__notification--emerald {
  background-color: #32c864;
}
.tab__notification--sun {
  background-color: #faaa1e;
}
.tab__notification--robbinsEggBlue {
  background-color: #00d2c8;
}
.tab__notification--ebonyClay {
  background-color: #28283c;
}
.tab__notification--azalea {
  background-color: #fadcdc;
}
.tab__notification--cruise {
  background-color: #bff0db;
}
.tab__notification--astra {
  background-color: #fafabe;
}
.tab__notification--gallery {
  background-color: #f0f0f0;
}
.tab__notification--humming-bird {
  background-color: #d7f0fa;
}
.tab__notification--humming-bird-faded {
  background-color: #f0fafe;
}
.tab__notification--silver-chalice {
  background-color: #a6a6a6;
}
.tab__notification--catskill-white {
  background-color: #e1e9f0;
}
.tab__notification--bali-hai {
  background-color: #8298ab;
}
.tab__notification--french-lilac {
  background-color: #efe0f6;
}
.tab__notification--deep-blush {
  background-color: #e07688;
}
.tab__notification--pink {
  background-color: #fbdad8;
}
.tab__notification--gothic {
  background-color: #6991a1;
}
.tab__notification--polar-blue {
  background-color: #f0f9fc;
}
.tab__notification--prussian {
  background-color: #304659;
}
.tab__notification--sky {
  background-color: #e1e9f0;
}
.tab__notification--tulip-tree {
  background-color: #f0ac44;
}
.tab__notification--emeraldish {
  background-color: #03c2b9;
}
.tab__notification--jumbo {
  background-color: #7b7b86;
}
.tab__notification--pampas {
  background-color: #f8f7f5;
}
.tab__notification--dark-gallery {
  background-color: #eaeaea;
}
.tab__notification--concrete {
  background-color: #f2f2f2;
}
.tab__notification--sand {
  background-color: #fbfbfb;
}
.tab__notification--black-squeeze {
  background-color: #f9fcfd;
}
.tab__notification--athens-gray {
  background-color: #f4f4f4;
}
.tab__notification--alto {
  background-color: #d8d8d8;
}
.tab__notification--gray {
  background-color: #888;
}
.tab__notification--venus {
  background-color: #7e7e8a;
}
.tab__notification--dolphin {
  background-color: #6b6a77;
}
.tab__notification--mercury {
  background-color: #e6e6e6;
}
.tab__notification--text-black {
  background-color: #28283c;
}
.tab__notification--polar {
  background-color: #eafbfa;
}
.tab__notification--tundora {
  background-color: #484444;
}
.tab__notification--jet {
  background-color: #3e3e50;
}
.tab__notification--silver {
  background-color: #bdbdbd;
}
.tab__notification--smoke {
  background-color: #bfbfbf;
}
.tab__notification--white {
  background-color: #fff;
}
.tab__notification--malachite {
  background-color: #00c040;
}
.tab__notification--light-green {
  background-color: #c0f0dd;
}
.tab__notification--coral {
  background-color: #ff774d;
}
.tab__notification--bright-sun {
  background-color: #ffcc43;
}
.tab__notification--shark {
  background-color: #272a2d;
}
.tab__notification--royal-blue {
  background-color: #4945e2;
}
.tab__notification--aquamarine-blue {
  background-color: #7ce5df;
}
.tab__notification--scorpion {
  background-color: #595959;
}
.tab__notification--heather {
  background-color: #b8c5d0;
}
.tab__notification--red {
  background-color: #de2828;
}
.tab__notification--lighter-teal {
  background-color: #d6f6f3;
}
.tab__notification--blue-lagoon {
  background-color: #007986;
}
.tab__notification--brightTeal {
  background-color: #00d8c4;
}
.tab__icon {
  flex-shrink: 0;
  margin-left: 8px;
  color: #28283c;
}
.tab__icon--disabled {
  color: #bdbdbd;
}
.tab__icon path {
  fill: currentColor;
}
