.about-product-section__description {
  line-height: 134%;
  letter-spacing: 0.01em;
  font-size: 18px;
  font-family: "Founders Grotesk", "Arial", sans-serif;
}
.about-product-section__description-header {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #28283c;
}
.about-product-section__description-paragraph:first-child {
  margin-top: 0;
}
.about-product-section__description-list {
  padding-left: 16px;
}
.about-product-section__list {
  margin-bottom: 40px;
}
.about-product-section__list:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .about-product-section__list {
    margin-bottom: 0;
  }
}
