.join-header-banner {
  padding: 30px 0;
}
.join-header-banner__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 700px) {
  .join-header-banner__info {
    justify-content: center;
  }
}
.join-header-banner__title {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 84%;
  text-transform: uppercase;
  color: #28283c;
  margin: 0 0 17px;
}
@media (min-width: 700px) {
  .join-header-banner__title {
    margin-bottom: 24px;
  }
}
@media (min-width: 1024px) {
  .join-header-banner__title {
    font-size: 62px;
  }
}
.join-header-banner__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 124%;
  letter-spacing: 0.01em;
}
@media (min-width: 834px) {
  .join-header-banner__description {
    font-size: 18px;
    max-width: 500px;
  }
}
@media (min-width: 1024px) {
  .join-header-banner__description {
    font-size: 20px;
  }
}
.join-header-banner__cta {
  margin-top: 10px;
}
.join-header-banner__image {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 30px;
}
@media (min-width: 700px) {
  .join-header-banner__image {
    margin-top: 0;
  }
}
.join-header-banner__image-wrapper {
  position: relative;
}
.join-header-banner__image-sticker {
  display: inline-block;
  background-color: #4945e2;
  transform: matrix(0.99, -0.16, 0.16, 0.99, 0, 0);
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12.2909px;
  line-height: 80%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding: 8px 11px;
  color: #d7f0fa;
  position: absolute;
  left: -5px;
  bottom: 0;
}
@media (min-width: 700px) {
  .join-header-banner__image-sticker {
    top: -5px;
    bottom: auto;
    left: -35px;
  }
}
@media (min-width: 1024px) {
  .join-header-banner__image-sticker {
    top: -15px;
    left: -45px;
    font-size: 18px;
    padding: 12px 26px;
  }
}
.join-header-banner__image-sticker-arrow {
  position: absolute;
  transform: rotate(40deg);
  bottom: 10px;
  height: 80px;
}
@media (min-width: 700px) {
  .join-header-banner__image-sticker-arrow {
    top: 0;
    left: -10px;
  }
}
@media (min-width: 1024px) {
  .join-header-banner__image-sticker-arrow {
    top: 5px;
    height: 120px;
  }
}
.join-header-banner__image-sticker-arrow path {
  fill: #d7f0fa;
}
