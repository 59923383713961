.dashboard-page-section {
  width: 100%;
  color: #28283c;
}
.dashboard-page-section--restricted {
  color: #bdbdbd;
}
.dashboard-page-section--spacing-gap-small {
  margin-bottom: 30px;
}
.dashboard-page-section--spacing-gap-large {
  margin-bottom: 60px;
}
.dashboard-page-section__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 10px;
}
.dashboard-page-section__lock path {
  fill: currentColor;
}
.dashboard-page-section__title-note {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-left: 5px;
}
.dashboard-page-section__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
}
.dashboard-page-section__tips {
  max-width: 546px;
  padding-top: 30px;
}
.dashboard-page-section__tips:first-child {
  padding-top: 0;
}
.dashboard-page-section__body {
  padding-top: 30px;
  max-width: 546px;
}
.dashboard-page-section__body--max-width {
  width: 100%;
  max-width: none;
}
