.media-card {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
@media (min-width: 835px) {
  .media-card {
    padding: 28px;
  }
}
.media-card__header {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #28283c;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 835px) {
  .media-card__header {
    margin-bottom: 28px;
    font-size: 21px;
  }
}
.media-card__title {
  margin-right: 0.5ex;
}
.media-card__type {
  opacity: 0.5;
}
.media-card__media-wrapper {
  border-radius: 4px;
  overflow: hidden;
  background-color: #434343;
}
.media-card__media {
  display: block;
  max-width: 100%;
}
