.course-accordion-list-add {
  padding-top: 30px;
}
.course-accordion-list-add__text {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 28px;
  line-height: 1;
  color: #28283c;
  font-weight: 600;
  margin: 0 0 30px;
}
