.toggle-popout-modal {
  z-index: 451;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(40, 40, 40, 0.7);
  justify-content: center;
  align-items: center;
  animation: fadeIn 250ms;
  overflow-y: auto;
  text-align: center;
}
@media (min-width: 576px) {
  .toggle-popout-modal {
    background-color: transparent;
    position: absolute;
    overflow-y: visible;
  }
}
.toggle-popout-modal__align {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.toggle-popout-modal__container {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  outline: none;
  animation: popout 250ms;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 576px) {
  .toggle-popout-modal__container {
    width: auto;
    position: absolute;
  }
}
.toggle-popout-modal__container--top {
  top: 0;
}
.toggle-popout-modal__container--right {
  right: 0;
}
.toggle-popout-modal__container--bottom {
  bottom: 0;
}
.toggle-popout-modal__container--left {
  left: 0;
}
