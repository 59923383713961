.customer-review-card {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  padding: 10px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
}
.customer-review-card__review-icon {
  flex: 0 0 20px;
  margin: 10px;
}
.customer-review-card__info {
  display: flex;
}
.customer-review-card__class {
  flex-grow: 1;
  font-size: 18px;
  font-weight: normal;
  color: #28283c;
  margin: 10px 0;
}
.customer-review-card__review {
  font-size: 18px;
  color: #6b6a77;
  flex: 0 0 83px;
  margin: 0 83px 0 40px;
}
@media (min-width: 992px) {
  .customer-review-card__review {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .customer-review-card__review {
    margin-right: 83px;
  }
}
.customer-review-card__rating {
  margin: 10px 0;
}
