.dashboard-page-header {
  width: 100%;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e6e6e6;
}
.dashboard-page-header__title {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 40px;
  line-height: 1;
  color: #28283c;
  font-weight: 600;
  margin-bottom: 16px;
}
.dashboard-page-header__title--uppercase {
  font-family: "Founders Grotesk Condensed", "Arial Bold", sans-serif;
  text-transform: uppercase;
}
.dashboard-page-header__description {
  font-family: "Founders Grotesk", "Arial", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #28283c;
}
