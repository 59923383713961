.accordion {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
}
.accordion__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  cursor: pointer;
}
.accordion__title {
  font-size: 16px;
  font-weight: 500;
  color: #28283c;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
}
.accordion__title--icon {
  width: 10px;
  fill: #03c2b9;
}
.accordion__body {
  transition: height 500ms ease 0s;
}
.accordion__body--open {
  height: auto;
  max-height: 600px;
}
.accordion__body--closed {
  overflow: hidden;
  height: 0px;
}
