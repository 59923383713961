@-webkit-keyframes popout {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.31% {
    -webkit-transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.02% {
    -webkit-transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.62% {
    -webkit-transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.33% {
    -webkit-transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  33.93% {
    -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  45.15% {
    -webkit-transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  72.57% {
    -webkit-transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@keyframes popout {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.31% {
    -webkit-transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.02% {
    -webkit-transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.62% {
    -webkit-transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.33% {
    -webkit-transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  33.93% {
    -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  45.15% {
    -webkit-transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  72.57% {
    -webkit-transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fatBounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  1.47% {
    transform: matrix3d(0.077, 0, 0, 0, 0, 0.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  2.2% {
    transform: matrix3d(0.235, 0, 0, 0, 0, 0.235, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  2.94% {
    transform: matrix3d(0.501, 0, 0, 0, 0, 0.501, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.4% {
    transform: matrix3d(1.233, 0, 0, 0, 0, 1.233, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.4% {
    transform: matrix3d(1.233, 0, 0, 0, 0, 1.233, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.87% {
    transform: matrix3d(1.841, 0, 0, 0, 0, 1.841, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.61% {
    transform: matrix3d(1.975, 0, 0, 0, 0, 1.975, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  7.47% {
    transform: matrix3d(1.964, 0, 0, 0, 0, 1.964, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8.81% {
    transform: matrix3d(1.688, 0, 0, 0, 0, 1.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.08% {
    transform: matrix3d(1.613, 0, 0, 0, 0, 1.613, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.68% {
    transform: matrix3d(1.187, 0, 0, 0, 0, 1.187, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  11.21% {
    transform: matrix3d(1.081, 0, 0, 0, 0, 1.081, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  12.28% {
    transform: matrix3d(0.942, 0, 0, 0, 0, 0.942, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.61% {
    transform: matrix3d(0.873, 0, 0, 0, 0, 0.873, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.88% {
    transform: matrix3d(0.867, 0, 0, 0, 0, 0.867, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.48% {
    transform: matrix3d(0.852, 0, 0, 0, 0, 0.852, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  16.02% {
    transform: matrix3d(0.85, 0, 0, 0, 0, 0.85, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.08% {
    transform: matrix3d(0.845, 0, 0, 0, 0, 0.845, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.42% {
    transform: matrix3d(0.847, 0, 0, 0, 0, 0.847, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.62% {
    transform: matrix3d(0.848, 0, 0, 0, 0, 0.848, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  20.82% {
    transform: matrix3d(0.878, 0, 0, 0, 0, 0.878, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.82% {
    transform: matrix3d(0.898, 0, 0, 0, 0, 0.898, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  23.22% {
    transform: matrix3d(0.935, 0, 0, 0, 0, 0.935, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.96% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  25.63% {
    transform: matrix3d(1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  27.93% {
    transform: matrix3d(1.103, 0, 0, 0, 0, 1.103, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  31.3% {
    transform: matrix3d(1.087, 0, 0, 0, 0, 1.087, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  32.73% {
    transform: matrix3d(1.038, 0, 0, 0, 0, 1.038, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  37.44% {
    transform: matrix3d(0.931, 0, 0, 0, 0, 0.931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  37.7% {
    transform: matrix3d(0.931, 0, 0, 0, 0, 0.931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  44.04% {
    transform: matrix3d(1.02, 0, 0, 0, 0, 1.02, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  46.95% {
    transform: matrix3d(1.029, 0, 0, 0, 0, 1.029, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  50.38% {
    transform: matrix3d(1.01, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  56.56% {
    transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  56.72% {
    transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.13% {
    transform: matrix3d(1.003, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  66.07% {
    transform: matrix3d(1.006, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  66.67% {
    transform: matrix3d(1.006, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  75.58% {
    transform: matrix3d(0.997, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.09% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  94.69% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.banner-popup {
  top: 0;
  position: fixed;
  z-index: 1000;
  background: rgba(40, 40, 60, 0.7);
  width: 100vw;
  height: 100vh;
  padding: 50px 20px 20px;
  overflow: scroll;
}
.banner-popup--closed {
  display: none;
}
.banner-popup--open {
  display: block;
}
@media (min-width: 834px) {
  .banner-popup--open {
    display: flex;
    justify-content: center;
    padding: 100px 20px 20px;
  }
}
.banner-popup__box {
  max-width: 576px;
  animation: popout 250ms, fadeIn 250ms;
  overflow: hidden;
}
.banner-popup__container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 10px;
  background-color: #fff;
}
@media (min-width: 834px) {
  .banner-popup__container {
    flex-direction: row;
  }
}
.banner-popup__container--open {
  display: flex;
}
.banner-popup__desktop-close-button {
  top: 20px;
  right: 20px;
  z-index: 91;
  width: 50px;
  height: 50px;
  display: none;
  cursor: pointer;
  border-radius: 40px;
  position: absolute;
  background-color: #fff;
  animation: fatBounce 1000ms linear both;
}
@media (min-width: 834px) {
  .banner-popup__desktop-close-button {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .banner-popup__desktop-close-button {
    right: 45px;
  }
}
.banner-popup__desktop-close-button-icon g {
  stroke: #ff774d;
}
.banner-popup__description-body {
  padding: 20px;
}
@media (min-width: 576px) {
  .banner-popup__description-body {
    padding: 35px;
  }
}
@media (min-width: 834px) {
  .banner-popup__description-body {
    padding: 40px;
    width: 100%;
  }
}
.banner-popup__title-wrapper {
  flex-direction: column;
  display: flex;
  padding-bottom: 20px;
}
@media (min-width: 576px) {
  .banner-popup__title-wrapper {
    display: block;
  }
}
.banner-popup__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.03em;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 576px) {
  .banner-popup__title {
    padding-right: 5px;
  }
}
@media (min-width: 1024px) {
  .banner-popup__title {
    font-size: 28px;
  }
}
.banner-popup__title-colored {
  color: #4945e2;
}
.banner-popup__description {
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 144%;
  color: #28283c;
  letter-spacing: 0.01em;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 576px) {
  .banner-popup__description {
    font-size: 18px;
  }
}
.banner-popup__description-paragraph {
  margin: 0;
  padding-bottom: 10px;
  line-height: 144%;
}
.banner-popup__description-link {
  color: #28283c !important;
  text-decoration: none !important;
  border-bottom: 1px solid #00d2c8;
}
.banner-popup__description-link:hover,
.banner-popup__description-link:active,
.banner-popup__description-link:visited,
.banner-popup__description-link:focus {
  color: #28283c !important;
  text-decoration: none !important;
  border-bottom: 1px solid #00d2c8;
}
.banner-popup__button {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .banner-popup__button {
    justify-content: flex-start;
  }
}
.banner-popup__image-wrapper--mobile {
  display: block;
}
@media (min-width: 576px) {
  .banner-popup__image-wrapper--mobile {
    display: none;
  }
}
.banner-popup__image-wrapper--tablet {
  display: none;
}
@media (min-width: 576px) and (max-width: 834px) {
  .banner-popup__image-wrapper--tablet {
    display: block;
  }
}
.banner-popup__image-wrapper--desktop {
  width: 40%;
  display: none;
}
@media (min-width: 834px) {
  .banner-popup__image-wrapper--desktop {
    display: block;
  }
}
.banner-popup__image {
  width: 100%;
  display: block;
}
.banner-popup__mobile-close-button-wrap {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 15px;
}
@media (min-width: 834px) {
  .banner-popup__mobile-close-button-wrap {
    display: none;
  }
}
.banner-popup__mobile-close-button {
  z-index: 91;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 2px solid #fff;
  font-family: "Founders Grotesk", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
}
